/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect, useState } from 'react'
import {
  Image as NativeImage,
  ImageStyle,
  Platform,
  StyleSheet,
  View,
  ViewStyle
} from 'react-native'
import { useFirebase } from 'react-redux-firebase'

interface ImageProps {
  path?: string
  fallback?: string
  style?: ViewStyle | ImageStyle
  resizeMode?: 'contain' | 'cover'
  // disableZoom?: boolean
  // onOpen?: () => void
}
function areEqual(prevProps, nextProps) {
  return (
    prevProps?.path === nextProps?.path &&
    prevProps?.fallback === nextProps?.fallback
  )
}
/* const LightBoxHeader = ({ close }) => {
  return (
    // eslint-disable-next-line react-native/no-inline-styles
    <Appbar.Header style={{ backgroundColor: 'transparent' }}>
      <Appbar.Action icon="close" onPress={close} color="white" />
    </Appbar.Header>
  )
} */

const iconProduct = require('../../assets/icon-product.png')

const Image = memo((props: ImageProps) => {
  const {
    style,
    path,
    fallback,
    resizeMode = 'cover'
    // disableZoom,
    // onOpen = () => null
  } = props
  const firebase = useFirebase()
  const storage = firebase.storage()
  const [imagePath, setImagePath] = useState<string | undefined>(path)

  const Styles = {
    ImageContainer: {
      height: 80,
      width: 96,
      borderRadius: 16,
      borderColor: 'rgba(0, 0, 0, 0.1)',
      borderWidth: StyleSheet.hairlineWidth,
      overflow: 'hidden'
    },
    Image: {
      width: '100%',
      height: '100%'
    }
  }
  const onError = async () => {
    if (!imagePath || imagePath !== fallback) {
      const ref = await storage.refFromURL(fallback)
      const url = await ref.getDownloadURL()
      setImagePath(url)
    }
  }
  useEffect(() => {
    if (!path && fallback) onError()
    // This can happen if path is filled lazy
    if (path && path !== imagePath) setImagePath(path)
  }, [path, fallback])

  if (!path && !fallback && Platform.OS !== 'web')
    return (
      <View style={[Styles.ImageContainer as ViewStyle, style]}>
        {/* @ts-ignore */}
        <NativeImage
          resizeMode={resizeMode}
          style={[Styles.ImageContainer, style]}
          source={iconProduct}
        />
      </View>
    )

  if (Platform.OS === 'web')
    return (
      <View style={[Styles.ImageContainer as ViewStyle, style]}>
        <NativeImage
          resizeMode={resizeMode}
          style={Styles.Image}
          onError={onError}
          defaultSource={iconProduct}
          source={{ uri: imagePath, cache: 'force-cache' }}
        />
      </View>
    )

  return (
    <View style={[Styles.ImageContainer as ViewStyle, style]}>
      <NativeImage
        resizeMode={resizeMode}
        style={[Styles.Image, style as ImageStyle]}
        defaultSource={iconProduct}
        onError={onError}
        source={{ uri: imagePath, cache: 'force-cache' }}
      />
    </View>
  ) /* 
  return (
    <Lightbox
      style={[Styles.ImageContainer, style]}
      underlayColor="transparent"
      springConfig={{ tension: 80, friction: 20 }}
      onOpen={onOpen}
      renderHeader={close => <LightBoxHeader close={close} />}
      activeProps={{ resizeMode: 'contain' }}>
      <NativeImage
        resizeMode={resizeMode}
        style={Styles.Image}
        defaultSource={require('../../assets/icon-product.png')}
        onError={onError}
        source={{ uri: imagePath }}
      />
    </Lightbox>
  ) */
}, areEqual)

export { Image }
