import { Divider, Icon, Price } from '@components'
import {
  getPriceForOrderProduct,
  getProductType,
  getSingleUnit,
  makeTimestampToMoment,
  getCloudFunctionsBaseURL
} from '@helpers/helpers'
import { useNavigation } from '@react-navigation/native'
import { setNewOrder } from '@redux/reducer/order'
import { selectProductTypes, selectSingleUnits } from '@redux/selectors'
import { useScopedDispatch } from '@store'
import { CustomTheme } from '@themes/variables'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PixelRatio, StyleSheet, TouchableOpacity, View } from 'react-native'
import {
  ActivityIndicator,
  DataTable,
  Text,
  useTheme
} from 'react-native-paper'
import { useSelector } from 'react-redux'
import { useFirebase } from 'react-redux-firebase'
import { LocalOrder } from '@hierfoods/interfaces'
// enhance the original axios adapter with throttle and cache enhancer

interface OrderBubbleProps {
  orderId: string
  deleted: boolean
  revisionOrderId: string
}

const OrderBubble = (props: OrderBubbleProps) => {
  const theme: CustomTheme = useTheme()
  const { t } = useTranslation()
  const { orderId, deleted, revisionOrderId } = props
  const Styles = StyleSheet.create({
    Container: {
      width: '100%',
      backgroundColor: theme.colors.background,
      borderTopLeftRadius: theme.borderRadius,
      borderTopRightRadius: theme.borderRadius,
      borderTopWidth: StyleSheet.hairlineWidth,
      borderLeftWidth: StyleSheet.hairlineWidth,
      borderRightWidth: StyleSheet.hairlineWidth,
      borderColor: theme.colors.placeholder,
      padding: 16
    },
    DeletedContainer: {
      width: '100%',
      backgroundColor: theme.colors.background,
      borderTopLeftRadius: theme.borderRadius,
      borderTopRightRadius: theme.borderRadius,
      borderTopWidth: StyleSheet.hairlineWidth,
      borderLeftWidth: StyleSheet.hairlineWidth,
      borderRightWidth: StyleSheet.hairlineWidth,
      borderColor: theme.colors.placeholder,
      padding: 16,
      flexDirection: 'row'
    },
    Headline: {
      color: theme.colors.textTertiary,
      fontSize: 17,
      fontWeight: 'bold',
      paddingRight: 24
    },
    Head: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'baseline',
      paddingTop: 4,
      paddingBottom: 4
    },
    EtdContainer: {
      flexDirection: 'row',
      height: 18,
      justifyContent: 'center'
    },
    Etd: {
      color: theme.colors.green,
      fontSize: 12,
      fontWeight: '600'
    },
    DeliveryIcon: {
      color: theme.colors.green,
      fontSize: 15
    },
    DeletedIcon: {
      color: theme.colors.text,
      fontSize: 15
    },
    Table: { fontSize: 8, marginBottom: -16 * PixelRatio.getFontScale() },
    Row: {
      minHeight: 0,
      paddingVertical: 6 * PixelRatio.getFontScale(),
      paddingHorizontal: 0
    },
    RowLast: {
      borderBottomWidth: 0
    },
    Cell: {
      padding: 0,
      minHeight: 0
    },
    CellText: {
      fontSize: 10
    },
    Amount: {
      flex: 0.3
    },
    Multiplier: { flex: 0.2 },
    SingleUnits: { flex: 0.8 },
    Title: { flex: 3 },
    Loader: {
      paddingTop: 15
    },
    Separator: {
      fontSize: 3
    }
  })

  const navigation = useNavigation()
  const scopedDispatch = useScopedDispatch()
  const firebase = useFirebase()
  const functions = (firebase as any).functions()
  const [orderProducts, setOrderProducts] = useState([])
  const [order, setOrder] = useState<LocalOrder | null>(null)
  const [loading, setLoading] = useState(true)
  const productTypes = useSelector(selectProductTypes)
  const singleUnits = useSelector(selectSingleUnits)
  useEffect(() => {
    const getOrderProducts = async () => {
      try {
        const result = await global.cachedAxios.get(
          revisionOrderId
            ? `${getCloudFunctionsBaseURL()}/defaults-getOrderProducts?revisionOrderId=${revisionOrderId}`
            : `${getCloudFunctionsBaseURL()}/defaults-getOrderProducts?orderId=${orderId}`
        )

        if (result?.data?.orderProducts)
          setOrderProducts(result.data.orderProducts)
        if (result?.data?.order) setOrder(result.data.order)
        setLoading(false)
      } catch (e) {
        setLoading(false)
      }
    }
    if (!deleted) getOrderProducts()
  }, [functions, orderId, deleted, revisionOrderId])
  const goToOrder = () => {
    if (deleted) return
    scopedDispatch(
      setNewOrder({
        ...order,
        id: orderId,
        order_products: null,
        preferred_delivery_date: moment(order.preferred_delivery_date, 'x')
      })
    )
    navigation.navigate('ChatOrderDetails', { isExisting: true })
  }

  if (loading && !deleted)
    return (
      <View style={Styles.Loader}>
        <ActivityIndicator />
      </View>
    )
  if (deleted || !order || !orderProducts)
    return (
      <View style={Styles.DeletedContainer}>
        <Icon
          style={Styles.DeletedIcon}
          name="trash-can"
          iconSet="MaterialCommunityIcons"
        />
        <Text>{t('orderBubble.deleted')}</Text>
      </View>
    )

  const orderProductCount = orderProducts.length
  return (
    <TouchableOpacity onPress={() => goToOrder()} style={Styles.Container}>
      <View style={Styles.Head}>
        <Text style={Styles.Headline} selectable>
          <Icon name="cart" style={Styles.Headline} />
          {` ${t('chatDetails.order')} #${order?.order_number}`}
        </Text>
        <View style={Styles.EtdContainer}>
          <Text selectable style={Styles.Etd}>
            {makeTimestampToMoment(order?.preferred_delivery_date).fromNow()}
          </Text>

          <Icon
            style={Styles.DeliveryIcon}
            name="truck-fast"
            iconSet="MaterialCommunityIcons"
          />
        </View>
      </View>
      <Divider />
      <DataTable style={Styles.Table}>
        {orderProducts.map((item, i) => {
          const rowStyle =
            orderProductCount === i + 1
              ? [Styles.Row, Styles.RowLast]
              : Styles.Row
          return (
            <DataTable.Row style={rowStyle}>
              <DataTable.Cell style={[Styles.Cell, Styles.Amount]}>
                <Text style={Styles.CellText}>{item?.product.amount}</Text>
              </DataTable.Cell>
              <DataTable.Cell style={[Styles.Cell, Styles.Multiplier]}>
                <Text style={Styles.CellText}>x</Text>
              </DataTable.Cell>
              <DataTable.Cell style={[Styles.Cell, Styles.Amount]}>
                <Text style={Styles.CellText}>
                  {item?.productDetails?.bulk_amount}
                </Text>
              </DataTable.Cell>
              <DataTable.Cell style={[Styles.Cell, Styles.SingleUnits]}>
                <Text style={Styles.CellText}>
                  {getSingleUnit(item.productDetails, singleUnits)}
                </Text>
              </DataTable.Cell>
              <DataTable.Cell style={Styles.Cell}>
                <Text style={Styles.CellText}>
                  {getProductType(item.productDetails, productTypes)}
                </Text>
              </DataTable.Cell>
              <DataTable.Cell style={[Styles.Cell, Styles.Title]}>
                <Text style={Styles.CellText}>
                  {item?.productDetails?.title}
                </Text>
              </DataTable.Cell>
              <DataTable.Cell style={Styles.Cell} numeric>
                {item?.productDetails?.single_unit_id && (
                  <Text style={Styles.CellText}>{`€/${getSingleUnit(
                    item.productDetails,
                    singleUnits
                  )}`}</Text>
                )}
              </DataTable.Cell>
              <DataTable.Cell style={Styles.Cell} numeric>
                <Price
                  amount={
                    getPriceForOrderProduct(item)
                      ? getPriceForOrderProduct(item) /
                        (item?.productDetails?.bulk_amount || 1)
                      : 0
                  }
                  style={Styles.CellText}
                />
              </DataTable.Cell>
            </DataTable.Row>
          )
        })}
      </DataTable>
      <Text style={Styles.Separator}>
        {`                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        `}
      </Text>
    </TouchableOpacity>
  )
}
const MemodBubble = React.memo(OrderBubble)
export { MemodBubble as OrderBubble }
