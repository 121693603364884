import { Price } from '@components'
import { CustomTheme } from '@themes/variables'
import React, { memo } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { useTheme } from 'react-native-paper'
import { Icon } from './Icon'

interface OrderProductProps {
  price: number
  bulkAmount?: number
  old?: boolean
  up?: boolean
  down?: boolean
  prefix?: string
  suffix?: string
  noIcon?: boolean
}
/**
 * @param old - If this is old price -> line-trough
 * @param up - If price went up -> red
 * @param down - If price went down -> green
 */
const OrderProductPrice = memo(
  ({
    price,
    bulkAmount = 1,
    old,
    up,
    down,
    prefix,
    suffix,
    noIcon
  }: OrderProductProps) => {
    const theme: CustomTheme = useTheme()

    const Styles = StyleSheet.create({
      Container: {
        flexDirection: 'row',
        alignItems: 'center'
      },
      Property: {
        color: theme.colors.textTertiary,
        fontSize: 12
      },
      PropertyOld: {
        color: theme.colors.textTertiary,
        fontSize: 12,
        textDecorationLine: 'line-through'
      },
      PropertyUp: {
        color: theme.colors.red,
        fontSize: 12,
        fontWeight: 'bold'
      },
      PropertyDown: {
        color: theme.colors.green,
        fontSize: 12,
        fontWeight: 'bold'
      },
      Price: {},
      ArrowUp: {
        transform: [{ rotate: '-45deg' }],
        marginRight: 3
      },
      ArrowDown: {
        transform: [{ rotate: '45deg' }],
        marginRight: 3
      }
    })
    const getStyle = () => {
      if (old) return Styles.PropertyOld
      if (up) return Styles.PropertyUp
      if (down) return Styles.PropertyDown
      return Styles.Property
    }

    const PropertyStyle = getStyle()
    return (
      <View style={Styles.Container}>
        {!!up && !noIcon && (
          <Icon
            name="arrow-right-circle"
            iconSet="MaterialCommunityIcons"
            style={[PropertyStyle, Styles.ArrowUp]}
          />
        )}
        {!!down && !noIcon && (
          <Icon
            name="arrow-right-circle"
            iconSet="MaterialCommunityIcons"
            style={[PropertyStyle, Styles.ArrowDown]}
          />
        )}
        {!!price && (
          <>
            {prefix && typeof prefix === 'string' && (
              <Text style={[Styles.Price, PropertyStyle]}>{prefix}</Text>
            )}
            <Price
              amount={Math.ceil(price / bulkAmount)}
              style={[Styles.Price, PropertyStyle]}
            />
            {suffix && typeof suffix === 'string' && (
              <Text style={[Styles.Price, PropertyStyle]}>{suffix}</Text>
            )}
          </>
        )}
      </View>
    )
  }
)

export { OrderProductPrice }
