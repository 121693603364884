import * as Localization from 'expo-localization'
import { Scale } from '@helpers/helpers'
import { useTheme, Text } from 'react-native-paper'
import moment from 'moment'
import React from 'react'
import { View, StyleSheet, Dimensions } from 'react-native'
import { CustomTheme } from '@themes/variables'
import { useTranslation } from 'react-i18next'

const styles = StyleSheet.create({
  Container: {
    width: '100%',
    flex: 1,
    padding: Scale(16)
  },
  WideContainer: {
    maxWidth: '80%',
    marginRight: 'auto',
    marginLeft: 72
  },
  Title: {
    fontSize: 18,
    fontWeight: 'bold'
  },
  Subtitle: {
    fontSize: 14,
    marginBottom: 6
  },
  Week: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 4
  },
  WeekNumber: {
    justifyContent: 'center',
    width: '12.5%',
    minWidth: 48,
    textAlign: 'left'
  },
  Days: {
    flexDirection: 'row',
    flex: 1,
    marginLeft: 10
  },
  Day: {
    flex: 1 / 7,
    margin: 1,
    textAlign: 'center',
    paddingVertical: 4
  },
  DayCell: {
    borderRadius: 5,
    borderColor: '#eee',
    borderWidth: 1,
    width: '80%',
    height: 20,
    alignSelf: 'center',
    justifyContent: 'center'
  },
  Today: {
    borderWidth: 2
  },
  HasPurchases: {
    borderWidth: 0
  },
  DayText: {
    textAlign: 'center'
  }
})

interface ProductHistoryCalendarProps {
  history: Record<string, number>
}

export const ProductHistoryCalendar = ({
  history
}: ProductHistoryCalendarProps) => {
  const isEnglish = Localization.locale.startsWith('en')
  moment.locale(isEnglish ? 'en' : 'de', {
    // set Monday as first day for both en and de
    week: {
      dow: 1
    }
  })

  const { width } = Dimensions.get('screen')

  const theme: CustomTheme = useTheme()
  const { t } = useTranslation()

  const now = moment()
  const mondayOfCurrentWeek = moment().startOf('isoWeek')
  const mondayFourWeeksAgo = mondayOfCurrentWeek.clone().subtract(3, 'week')
  // "Mo.","Di.","Mi.",etc. or `"Mon", "Tue", "Wed" etc.
  const weekdays = moment.weekdaysShort(true)

  const calendar = {}

  // build a list of last 27 days
  const dates = Object.keys(history)
    .map(ts => parseInt(ts, 10))
    .sort((a, b) => b - a)

  // store in a map by `DDMMYY` to efficiently get the volumes for a day
  const daysToVolumes = {}
  dates.forEach(ts => {
    // keyed as `250321`
    daysToVolumes[moment(ts).format('DDMMYY')] =
      // sum-up amounts purhcased on the same day
      (daysToVolumes[moment(ts).format('DDMMYY')] || 0) + history[ts]
  })

  // iterate over four weeks including the current one
  for (
    const current = mondayFourWeeksAgo.clone();
    current.diff(mondayOfCurrentWeek) <= 0;
    current.add(1, 'week')
  ) {
    const weekNumber = current.format('WW')
    calendar[weekNumber] = {}
    // iterate over weekdays
    const day = current.clone()
    weekdays.forEach(weekday => {
      const value =
        // days with purchases render the volume: '15'
        daysToVolumes[day.format('DDMMYY')] ||
        // empty days in the past render dashes: '-'
        // empty days in the future (and today) render empty spaces: ' '
        (day.isSame(now, 'day') || day.isAfter(now) ? ' ' : '-')
      calendar[weekNumber][weekday] = value

      day.add(1, 'day')
    })
  }

  // we need to "loop" the sorting from 01 to 53
  // so: … 50, 51, 52, 53, 01, 02, 03...
  const sortedWeekNumbers = Object.keys(calendar).sort((weekA, weekB) => {
    let sortByA = parseInt(weekA, 10)
    if (weekA.startsWith('0')) {
      sortByA += 52
    }
    return sortByA - parseInt(weekB, 10)
  })

  // formatting here must match `weekNumber` and `weekdays` formatting from
  // above, since it is used for comparison later
  const today = now.format('WWddd')

  const startMonth = mondayFourWeeksAgo.format('MMMM')
  const endMonth = now.format('MMMM')
  const monthRange =
    startMonth === endMonth ? endMonth : `${startMonth} - ${endMonth}`

  return (
    <View
      style={[
        styles.Container,
        // 600 is maxwidth, 72 is the gap on the left (image width on product
        // card), 32 is two paddings
        width >= 600 + 72 + 32 ? styles.WideContainer : {}
      ]}>
      <Text style={[styles.Title, { color: theme.colors.primary }]}>
        {monthRange}
      </Text>
      <Text style={[styles.Subtitle]}>{t('orderCreate.orderOverview')}</Text>
      {sortedWeekNumbers.map((weekNumber, index) => (
        <>
          {/* Legend */}
          {index === 0 && (
            <View style={styles.Week} key={`${weekNumber}-legend`}>
              <View style={styles.WeekNumber} />
              <View style={styles.Days}>
                {Object.keys(calendar[weekNumber]).map(weekday => (
                  <View style={styles.Day}>
                    <Text style={styles.DayText}>{weekday}</Text>
                  </View>
                ))}
              </View>
            </View>
          )}
          {/* Calendar Body */}
          <View style={styles.Week} key={weekNumber}>
            <View style={styles.WeekNumber}>
              <Text numberOfLines={1}>KW{weekNumber}</Text>
            </View>
            <View style={styles.Days}>
              {Object.keys(calendar[weekNumber]).map(weekday => (
                <View style={[styles.Day]} key={`${weekNumber}-${weekday}`}>
                  <View
                    style={[
                      styles.DayCell,
                      { borderColor: theme.colors.field },
                      today === `${weekNumber}${weekday}` && [
                        styles.Today,
                        { borderColor: theme.colors.primary }
                      ],
                      typeof calendar[weekNumber][weekday] === 'number' && [
                        styles.HasPurchases,
                        {
                          backgroundColor: theme.colors.primary
                        }
                      ]
                    ]}>
                    <Text
                      style={[
                        styles.DayText,
                        typeof calendar[weekNumber][weekday] === 'number' && {
                          color: theme.colors.background
                        }
                      ]}>
                      {calendar[weekNumber][weekday]}
                    </Text>
                  </View>
                </View>
              ))}
            </View>
          </View>
        </>
      ))}
    </View>
  )
}
