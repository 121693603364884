import React, { createRef, useRef } from 'react'
import { Provider } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { NavigationContainer } from '@react-navigation/native'
import { createStackNavigator } from '@react-navigation/stack'

import EnterCode from '@screens/enter-code'
import ChatDetailsForSuppier from '@screens/chat-details-for-supplier'
import OrderDetailsForSupplier from '@screens/order-details-for-supplier'
import DispatchAdvise from '@screens/dispatch-advise'

import { CustomTheme } from '@themes/variables'

import { orderOverviewStore, ScopedReduxContext } from '../../store'

const NormalStack = createStackNavigator()

const navigationRef: any = createRef()

const Navigation = ({ theme }: { theme: CustomTheme }) => {
  const navigationRef = useRef()
  const { t } = useTranslation()

  const linking = {
    prefixes: ['localhost'],
    config: {
      screens: {
        chat: { path: 'm/:code' },
        dispatchOrder: { path: 'd/:code' },
        order: { path: 'c/:code' },
        enterChatCode: { path: 'chatten' },
        enterDispatchOrderCode: { path: 'senden' },
        enterOrderCode: { path: 'liefern' }
      }
    }
  }

  return (
    <NavigationContainer theme={theme} ref={navigationRef} linking={linking}>
      <Provider store={orderOverviewStore} context={ScopedReduxContext}>
        <NormalStack.Navigator initialRouteName="enterOrderCode">
          <NormalStack.Screen
            name="enterChatCode"
            options={{ title: t('enterChatCode.title') }}>
            {props => <EnterCode {...props} overviewPath="chat" />}
          </NormalStack.Screen>
          <NormalStack.Screen
            name="enterDispatchOrderCode"
            options={{ title: t('enterDispatchOrderCode.title') }}>
            {props => <EnterCode {...props} overviewPath="dispatchOrder" />}
          </NormalStack.Screen>
          <NormalStack.Screen
            name="enterOrderCode"
            options={{ title: t('enterOrderCode.title') }}>
            {props => <EnterCode {...props} overviewPath="order" />}
          </NormalStack.Screen>
          <NormalStack.Screen name="chat" component={ChatDetailsForSuppier} />
          <NormalStack.Screen name="dispatchOrder" component={DispatchAdvise} />
          <NormalStack.Screen
            name="order"
            component={OrderDetailsForSupplier}
          />
        </NormalStack.Navigator>
      </Provider>
    </NavigationContainer>
  )
}
export { navigationRef }
export default Navigation
