/* eslint-disable no-param-reassign */
import {
  getBulkUnit,
  getProductType,
  getSingleUnit,
  orderByWithUmlaut,
  momentToUnixInt,
  makeTimestampToMoment
} from '@helpers/helpers'
import {
  Category,
  OrderProductDetails,
  OrderProducts,
  OrderStatus,
  Supplier,
  DatabaseMap,
  OrderOverviewSection
} from '@hierfoods/interfaces'
import Fuse from 'fuse.js'
import clone from 'lodash/clone'
import isEqual from 'lodash/isEqual'
import moment from 'moment'
import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect'

const emptyObject = {}
const emptyArray = []
const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual)

export * from './scopedSelectors'

/**
 * Global Selectors
 */

export const selectUID = (s): string => s?.firebase?.auth?.uid
export const selectProfile = s => s?.firebase?.profile as Supplier
export const selectSettings = createSelector(
  selectProfile,
  profile => profile?.settings
)

export const selectHideOrderUser = createSelector(
  selectSettings,
  settings => !!settings?.hideOrderUser
)

export const selectForceShowProductID = createSelector(
  selectSettings,
  settings => !!settings?.forceShowProductID
)

export const selectFirebaseData = s => s?.firebase?.data as DatabaseMap
export const selectBulkUnits = createDeepEqualSelector(
  selectFirebaseData,
  firebaseData => firebaseData?.bulk_units || emptyObject
)
export const selectSingleUnits = createDeepEqualSelector(
  selectFirebaseData,
  firebaseData => firebaseData?.single_units || emptyObject
)
export const selectVATs = createDeepEqualSelector(
  selectFirebaseData,
  firebaseData => firebaseData?.vat || emptyObject
)
export const selectProductTypes = createDeepEqualSelector(
  selectFirebaseData,
  firebaseData => firebaseData?.product_types || emptyObject
)

export const selectOrderProductsWithProductType = (
  orderProducts: OrderProducts[]
) =>
  createSelector(selectProductTypes, productTypes =>
    orderProducts && orderProducts.length
      ? orderProducts.map(orderProduct => ({
          ...orderProduct,
          productDetails: {
            ...orderProduct.productDetails,
            product_type: getProductType(
              orderProduct.productDetails,
              productTypes
            )
          }
        }))
      : []
  )

export const selectAllOrders = (key: string = 'orders') =>
  createSelector(selectFirebaseData, firebaseData =>
    firebaseData?.[key]
      ? Object.keys(firebaseData[key]).map(k => ({
          ...firebaseData[key][k],
          id: k
        }))
      : emptyArray
  )
export const selectOrdersAreLoading = s =>
  !(s?.firebase?.requesting?.orders === false)

export const selectArchivedOrdersAreLoading = s =>
  !(s?.firebase?.requesting?.archived_orders === false)

export const selectOrdersByStatuses = (
  statuses: OrderStatus[],
  customSortFunction?: (orders: any) => any,
  customFilterFunction?: (orders: any) => any,
  key?: string
) =>
  createDeepEqualSelector(selectAllOrders(key), orders => {
    let out
    if (customSortFunction)
      out = customSortFunction(orders.filter(o => statuses.includes(o.status)))
    else
      out =
        orderByWithUmlaut(
          orders.filter(o => statuses.includes(o.status)),
          ['preferred_delivery_date', 'order_number'],
          ['desc', 'desc']
        ) || emptyArray
    if (customFilterFunction) return customFilterFunction(out)
    return out
  })
export const selectSuppliersWhereWeOrderedToday = createDeepEqualSelector(
  selectAllOrders(),
  allOrders => {
    const SupplierIDs = new Set()
    allOrders.forEach(o => {
      if (
        // If order == sent and was updated_at today, it was sent out today
        ['sent', 'confirmed'].includes(o.status) &&
        makeTimestampToMoment(o.updated_at).isSame(moment(), 'day')
      )
        SupplierIDs.add(o.supplier_id)
    })
    return Array.from(SupplierIDs)
  }
)

const filterByDay = (
  data,
  daysToSubtract: number = 0,
  additionalTimespanDays: number = 0,
  openEnd: boolean = false
) =>
  data.filter(
    o =>
      o.updated_at <
        momentToUnixInt(
          moment().subtract(daysToSubtract, 'days').endOf('day')
        ) &&
      (openEnd ||
        o.updated_at >
          momentToUnixInt(
            moment()
              .subtract(daysToSubtract + additionalTimespanDays, 'days')
              .startOf('day')
          ))
  )

export const ordersCombined = (key?: string) =>
  createDeepEqualSelector(
    selectOrdersByStatuses(
      ['delivered', 'sent', 'confirmed'],
      orders =>
        clone(orders).sort((a, b) => {
          const timeStampA = Math.max(
            parseInt(a.updated_at, 10) || 0,
            parseInt(a.created_at, 10) || 0
          )
          const timeStampB = Math.max(
            parseInt(b.updated_at, 10) || 0,
            parseInt(b.created_at, 10) || 0
          )
          if (timeStampA > timeStampB) return -1
          if (timeStampB > timeStampA) return 1
          return 0
        }),
      undefined,
      key
    ),
    selectOrdersByStatuses(['rejected'], undefined, undefined, key),
    (sentOrConfirmed, rejected) => {
      const out: OrderOverviewSection[] = []
      const todaysOrders = filterByDay(sentOrConfirmed, 0)
      if (todaysOrders?.length > 0)
        out.push({
          translationKey: 'orderStatus.today',
          data: todaysOrders
        })
      const yesterdaysOrders = filterByDay(sentOrConfirmed, 1)
      if (yesterdaysOrders?.length > 0)
        out.push({
          translationKey: 'orderStatus.yesterday',
          data: yesterdaysOrders
        })
      const lastWeekOrders = filterByDay(sentOrConfirmed, 2, 7)
      if (lastWeekOrders?.length > 0)
        out.push({
          translationKey: 'orderStatus.lastWeek',
          data: lastWeekOrders
        })
      const earlier = filterByDay(sentOrConfirmed, 10, 0, true)
      if (earlier?.length > 0)
        out.push({
          translationKey: 'orderStatus.earlier',
          data: earlier
        })
      if (rejected?.length > 0)
        out.push({
          translationKey: 'orderStatus.rejected',
          data: rejected,
          type: 'rejected'
        })
      return out
    }
  )

export const selectAllMerchantSupplier = createDeepEqualSelector(
  selectFirebaseData,
  firebaseData => firebaseData?.merchant_suppliers
)

export const selectAllSuppliersForMerchant = createSelector(
  selectUID,
  selectAllMerchantSupplier,
  (merchantId, merchantSupplierRelations) =>
    merchantSupplierRelations?.[merchantId]
      ? merchantSupplierRelations?.[merchantId]
      : emptyArray
)

export const selectSuppliersForMerchantIds = createSelector(
  selectAllSuppliersForMerchant,
  suppliers =>
    Object.entries(suppliers)
      // React-Redux Firebase can Produce Object-keys with null as value for deleted objects, which we filter out here
      .filter(([, value]) => !!value)
      .map(([key]) => key)
)

export const selectAllSupplierDetails = createSelector(
  selectFirebaseData,
  firebaseData => firebaseData?.suppliers
)

export const selectIsSupplierConnected = (supplierId: string) =>
  createSelector(selectSuppliersForMerchantIds, allSuppliers =>
    allSuppliers.includes(supplierId)
  )

export const selectCurrentSupplier = (supplierId: string) =>
  createDeepEqualSelector(selectAllSupplierDetails, suppliers => ({
    ...suppliers?.[supplierId],
    id: supplierId
  }))

export const selectAllSuppliersForMerchantWithDetails = createDeepEqualSelector(
  selectSuppliersForMerchantIds,
  selectAllSupplierDetails,
  (suppliers, supplierDetails) => {
    if (!suppliers || !supplierDetails) return emptyArray
    const mapped = suppliers.map(supl => ({
      id: supl,
      data: supplierDetails[supl]
    }))
    return orderByWithUmlaut(mapped, ['data.name'], ['asc'])
  }
)
export const selectLockedSuppliers = createDeepEqualSelector(
  selectAllSuppliersForMerchantWithDetails,
  supplier => supplier.filter(s => s.data?.locked).map(s => s.id)
)
export const selectCurrentSupplierLocked = (supplierId: string) =>
  createSelector(selectLockedSuppliers, locked => locked.includes(supplierId))
const selectDeltaFromFirebase = createSelector(
  selectFirebaseData,

  firebaseData => firebaseData.products_delta
)
export const selectDelta = (supplierId: string) =>
  createSelector(
    selectUID,
    selectDeltaFromFirebase,
    (uid, delta) => delta?.[uid]?.[supplierId] || emptyArray
  )
export const selectCustomPrices = (supplierId: string) =>
  createDeepEqualSelector(
    selectUID,
    selectFirebaseData,
    (merchantId, firebaseData) =>
      firebaseData?.prices?.[supplierId]?.merchants?.[merchantId] || emptyObject
  )

export const selectAllProductsForCurrentSupplierObject = (supplierId: string) =>
  createDeepEqualSelector(
    selectFirebaseData,
    selectDelta(supplierId),
    selectCustomPrices(supplierId),
    selectProductTypes,
    selectSingleUnits,
    selectBulkUnits,
    (
      firebaseData,
      delta,
      customPrices,
      productTypes,
      singleUnits,
      bulkUnits
    ) => {
      const getDeltaForID = (id, softDeleted) => {
        if (!delta || delta.length === 0) return null
        const deltaForID = delta.find(d => d.key === id)
        if (!deltaForID) return 'new'
        if (deltaForID.soft_deleted && !softDeleted) return 'back'
        if (deltaForID.price === 0) return null
        return parseFloat(deltaForID.price)
      }
      if (!firebaseData?.products?.[supplierId]) return emptyObject
      const outMap = {}
      Object.entries(firebaseData?.products?.[supplierId])
        .filter(
          ([, value]: [string, OrderProductDetails]) => !value.soft_deleted
        )
        .forEach(([key, value]: [string, OrderProductDetails]) => {
          outMap[key] = {
            ...value,
            id: `${key}`,
            delta: getDeltaForID(key, !!value?.soft_deleted),
            product_type: getProductType(value, productTypes),
            bulk_unit: getBulkUnit(value, bulkUnits),
            single_unit: getSingleUnit(value, singleUnits),
            // Use custom-prices if available
            bulk_price: customPrices?.[key]?.bulk_price || value?.bulk_price,
            selling_price:
              customPrices?.[key]?.selling_price || value?.selling_price
          }
        })
      return outMap
    }
  )

export const selectAllProductsForCurrentSupplier = (supplierId: string) =>
  createDeepEqualSelector(
    selectFirebaseData,
    selectDelta(supplierId),
    selectCustomPrices(supplierId),
    selectProductTypes,
    selectSingleUnits,
    selectBulkUnits,
    (
      firebaseData,
      delta,
      customPrices,
      productTypes,
      bulkUnits,
      singleUnits
    ) => {
      const getDeltaForID = (id, softDeleted) => {
        if (!delta || delta.length === 0) return null
        const deltaForID = delta.find(d => d.key === id)
        if (!deltaForID) return 'new'
        if (deltaForID.soft_deleted && !softDeleted) return 'back'
        if (deltaForID.price === 0) return null
        return parseFloat(deltaForID.price)
      }
      return firebaseData?.products?.[supplierId]
        ? Object.entries(firebaseData?.products?.[supplierId])
            .filter(([, p]: [string, OrderProductDetails]) => !p.soft_deleted)
            .map(([key, value]: [string, OrderProductDetails]) => ({
              ...value,
              id: `${key}`,
              delta: getDeltaForID(key, !!value?.soft_deleted),
              product_type: getProductType(value, productTypes),
              bulk_unit: getBulkUnit(value, bulkUnits),
              single_unit: getSingleUnit(value, singleUnits),
              // Use custom-prices if available
              bulk_price: customPrices?.[key]?.bulk_price || value?.bulk_price,
              selling_price:
                customPrices?.[key]?.selling_price || value?.selling_price
            }))
        : emptyArray
    }
  )

export const selectOrderProductsWithTypesSorted = (
  orderProducts: OrderProducts[]
) =>
  createSelector(
    selectOrderProductsWithProductType(orderProducts),
    orderProductsWithType =>
      orderProductsWithType && orderProductsWithType.length
        ? orderByWithUmlaut(
            orderProductsWithType,
            ['productDetails.product_type', 'productDetails.title'],
            ['asc', 'asc']
          )
        : []
  )

export const selectFusedProducts = (supplierId: string) =>
  createDeepEqualSelector(
    selectAllProductsForCurrentSupplier(supplierId),
    allProducts => {
      const mappedProducts = allProducts?.map(p => ({
        productDetails: {
          ...p
        },
        product: {
          amount: 0,
          product_id: `${p.id}`
        }
      }))
      if (!mappedProducts) return undefined
      const fuse = new Fuse(mappedProducts, {
        keys: [
          { name: 'productDetails.product_type', weight: 3 },
          { name: 'productDetails.title', weight: 1 },
          { name: 'productDetails.supplier_product_id', weight: 7 },
          { name: 'productDetails.ean', weight: 10 },
          { name: 'productDetails.single_unit', weight: 0.5 },
          { name: 'productDetails.bulk_unit', weight: 0.5 }
        ],
        minMatchCharLength: 2,
        threshold: 0.2,
        ignoreLocation: true,
        shouldSort: true
      })
      return fuse
    }
  )

export const selectSupplierById =
  supplierId =>
  ({ firebase }) =>
    (supplierId && firebase?.data?.suppliers?.[supplierId]) ?? null

export const selectMessagesForChat = (uid, supplierId) => s =>
  s.firebase?.ordered?.messages?.[uid]?.[supplierId]

export const selectMessagesForChatConverted = (uid, supplierId) =>
  createSelector(selectMessagesForChat(uid, supplierId), messages =>
    messages
      ?.map(message => ({
        _id: message.key,
        text: message.value.message,
        createdAt: moment(message.value.created_at),
        orderId: message.value?.order_id,
        revisionOrderId: message.value?.revision_order_id,
        order_deleted: message.value?.order_deleted,
        user:
          message.value.from === 'supplier'
            ? {
                _id: supplierId
              }
            : {
                _id: uid
              }
      }))
      .reverse()
  )

const selectChats = s => s.firebase?.ordered?.chats
export const selectChatOverview = createDeepEqualSelector(
  selectUID,
  selectChats,
  (uid, chats) =>
    chats?.[uid]?.sort((a, b) => b.value.created_at - a.value.created_at) ??
    emptyArray
)

export const selectCategories = createSelector(
  selectFirebaseData,
  firebaseData =>
    firebaseData?.categories
      ? Object.keys(firebaseData.categories).map(ok => ({
          ...firebaseData.categories[ok],
          id: ok,
          isCategory: true
        }))
      : emptyArray
)

export const selectCategoriesForSupplier = (supplierId: string) =>
  createDeepEqualSelector(
    selectAllProductsForCurrentSupplier(supplierId),
    selectCategories,
    (products, categories) => {
      let someHasNoCategory = false
      const categoriesSet = new Set()
      products.forEach(p => {
        if (p.category_id) categoriesSet.add(p.category_id)
        else someHasNoCategory = true
      })
      const filteredCategories = categories?.filter(category =>
        categoriesSet.has(category.id)
      )

      const orderedCategories = orderByWithUmlaut(filteredCategories, ['title'])

      if (someHasNoCategory)
        orderedCategories.push({
          id: 'noCategory',
          title: 'Ohne Kategorie',
          isCategory: true
        })
      return orderedCategories
    }
  )

export const selectNewProducts = (supplierId: string) =>
  createDeepEqualSelector(
    selectAllProductsForCurrentSupplier(supplierId),
    selectProductTypes,
    (allProducts, productTypes) =>
      orderByWithUmlaut(
        allProducts
          ?.filter(p => p?.delta === 'new' || p?.delta === 'back')
          .map(p => ({
            productDetails: {
              ...p,
              product_type: getProductType(p, productTypes)
            },
            product: {
              amount: 0,
              product_id: `${p.id}`
            }
          })),
        /* .map(mappedProds =>
            Object.assign(
              mappedProds,
              orderProductsWithTypesSorted.find(
                op => op.product.product_id === mappedProds.product.product_id
              )
            )
          ) */ ['productDetails.product_type', 'productDetails.title'],
        ['asc', 'asc']
      )
  )
export const selectProductsForCategory = (
  category: Category,
  supplierId: string
) =>
  createDeepEqualSelector(
    selectAllProductsForCurrentSupplier(supplierId),
    selectNewProducts(supplierId),
    (allProducts, newProducts) => {
      if (!category) return []
      if (category?.isNewProducts) return newProducts
      let filterFunc = p => `${p.supplier_category_id}` === `${category?.id}`
      if (category?.id === 'noCategory') {
        filterFunc = p => !p.supplier_category_id
      }

      return orderByWithUmlaut(
        allProducts?.filter(filterFunc).map(p => ({
          productDetails: p,
          product: {
            amount: 0,
            product_id: `${p.id}`
          }
        })),
        /* .map(mappedProds =>
            Object.assign(
              mappedProds,
              orderProductsWithTypesSorted.find(
                op => op.product.product_id === mappedProds.product.product_id
              )
            )
          ) */ ['productDetails.product_type', 'productDetails.title'],
        ['asc', 'asc']
      )
    }
  )

export const selectUnreadCount = createSelector(selectChatOverview, chats =>
  chats.reduce((previous, { value }) => previous + value?.unread || 0, 0)
)
const selectFavoritesFromFirebase = createDeepEqualSelector(
  selectFirebaseData,
  firebaseData => firebaseData.favorites
)

const selectProductHistoryFromFirebase = createDeepEqualSelector(
  selectFirebaseData,
  firebaseData => firebaseData.product_history
)

export const selectSupplierProductHistory = (supplierId: string) =>
  createDeepEqualSelector(
    selectUID,
    selectProductHistoryFromFirebase,
    (uid, history) => history?.[uid]?.[supplierId]
  )

export const selectFavoriteProducts = (supplierId: string) =>
  createDeepEqualSelector(
    selectUID,
    selectFavoritesFromFirebase,
    (uid, favorites) => favorites?.[uid]?.[supplierId] || emptyArray
  )

export const selectIsFavoriteProduct = (
  supplierId: string,
  productId: string
) =>
  createSelector(selectFavoriteProducts(supplierId), favoriteProducts =>
    favoriteProducts?.includes(productId)
  )

export const selectProductFavoritesFiltered = (supplierId: string) =>
  createDeepEqualSelector(
    selectAllProductsForCurrentSupplier(supplierId),
    selectProductTypes,
    selectFavoriteProducts(supplierId),
    (allProducts, productTypes, favoriteProducts) =>
      orderByWithUmlaut(
        allProducts
          ?.map(p => ({
            productDetails: {
              ...p,
              product_type: getProductType(p, productTypes)
            },
            product: {
              amount: 0,
              product_id: `${p.id}`
            }
          }))
          .filter(p => favoriteProducts.includes(p.product.product_id)),
        ['productDetails.product_type', 'productDetails.title'],
        ['asc', 'asc']
      ) ?? emptyArray
  )

export const selectSupplierCategories = (supplierId: string) =>
  createSelector(
    selectFirebaseData,
    firebaseData => firebaseData?.supplier_categories?.[supplierId] || {}
  )

export const selectSupplierCategoriesSorted = (supplierId: string) =>
  createDeepEqualSelector(
    selectAllProductsForCurrentSupplier(supplierId),
    selectSupplierCategories(supplierId),
    (allProducts, supplierCategories) => {
      const productsBySupplierCategory = allProducts.reduce(
        (result, product) => {
          const categoryId = product.supplier_category_id || 'noCategory'
          if (result[categoryId]) {
            result[categoryId].push(product)
          } else {
            result[categoryId] = [product]
          }
          return result
        },
        {}
      )
      const someHaveNoCategory = Object.prototype.hasOwnProperty.call(
        productsBySupplierCategory,
        'noCategory'
      )
      const sortedSupplierCategories = Object.keys(supplierCategories)
        // don’t show categories without products
        .filter(id => productsBySupplierCategory[id])
        .sort(
          (idA, idB) =>
            supplierCategories[idA].order - supplierCategories[idB].order
        )
        .map(id => ({
          ...supplierCategories[id],
          id,
          isCategory: true
        }))

      if (someHaveNoCategory) {
        sortedSupplierCategories.push({
          id: 'noCategory',
          title: 'Sonstiges',
          order: 9999,
          isCategory: true
        })
      }
      return sortedSupplierCategories
    }
  )

export const selectFavoritesOrderedByCategories = (supplierId: string) =>
  createDeepEqualSelector(
    selectProductFavoritesFiltered(supplierId),
    selectSupplierCategories(supplierId),
    selectSupplierCategoriesSorted(supplierId),
    (favoriteProducts, supplierCategoriesMap) => {
      const favoritesByCategory = favoriteProducts.reduce(
        (result, favoriteProduct) => {
          const product = favoriteProduct.productDetails
          result[product.supplier_category_id] =
            result[product.supplier_category_id] || []
          result[product.supplier_category_id].push(favoriteProduct)
          return result
        },
        {}
      )
      return Object.keys(favoritesByCategory)
        .sort((idA, idB) => {
          const orderA = supplierCategoriesMap[idA]
            ? supplierCategoriesMap[idA].order
            : 9999
          const orderB = supplierCategoriesMap[idB]
            ? supplierCategoriesMap[idB].order
            : 9999
          return orderA - orderB
        })
        .map(id => ({
          title: supplierCategoriesMap[id]
            ? supplierCategoriesMap[id].title
            : 'Sonstiges',
          type: 'favorite',
          data: favoritesByCategory[id]
        }))
    }
  )

export const selectIsSupplierUsingCrawler = (supplierId: string) =>
  createSelector(selectCurrentSupplier(supplierId), (supplier: Supplier) => {
    if (supplier.preferred_types) {
      return supplier.preferred_types
        .map(pref => {
          const [key] = Object.keys(pref)
          return key
        })
        .includes('crawler')
    }
    return supplier.preferred_type === 'crawler'
  })

export const selectMerchantSupplierDetails = (supplierId: string) =>
  createSelector(
    selectAllMerchantSupplier,
    selectUID,
    (merSupData, UID) => merSupData?.[UID]?.[supplierId]
  )

export const selectDeviceDetails = (deviceID: string) =>
  createSelector(
    selectFirebaseData,
    selectUID,
    (data, uid) => data.devices?.[uid]?.[deviceID]
  )

export const selectAllOrderUsers = createSelector(
  selectFirebaseData,
  selectUID,
  (data, UID) => data?.order_users?.[UID]
)

export const selectProductsRequesting = (supplierId: string) => s =>
  supplierId ? s.firebase?.requesting?.[`products/${supplierId}`] : false

export const selectDrafts = createDeepEqualSelector(
  selectAllOrders(),
  selectSuppliersForMerchantIds,
  (allOrders, merchantSuppliers) =>
    orderByWithUmlaut(
      allOrders.filter(
        o => o.status === 'draft' && merchantSuppliers.includes(o.supplier_id)
      ),
      ['updated_at'],
      ['desc']
    ) || emptyArray
)
