import * as React from 'react'
import { useTranslation } from 'react-i18next'
import IconComponent from './IconComponent'

const BarcodeHeaderButton = React.memo(
  ({ onPress, style }: { onPress?: () => void; style?: any }) => {
    const { t } = useTranslation()

    return (
      <IconComponent
        name="scan"
        text={t('header.barcode')}
        onPress={onPress}
        style={style}
      />
    )
  }
)
export { BarcodeHeaderButton }
