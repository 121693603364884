import { Moment } from 'moment'
import { action, on, payload, reducer } from 'ts-action'
import { OrderStatus, LocalOrder } from '@hierfoods/interfaces'

function updateOrderState(
  oldObject: LocalOrder,
  newValues: Partial<LocalOrder>
): LocalOrder {
  // Encapsulate the idea of passing a new object as the first parameter
  // to Object.assign to ensure we correctly copy data instead of mutating
  // eslint-disable-next-line prefer-object-spread
  return Object.assign({}, oldObject, newValues)
}

export const setNewOrder = action('[ORDER] setNewOrder', payload<LocalOrder>())
export const setOrderEdited = action(
  '[ORDER] setOrderEdited',
  payload<boolean>()
)
export const manualAutoDraftOrder = action('[ORDER] manualAutoDraftOrder')
export const setOrderID = action('[ORDER] setOrderID', payload<string>())

export const setPreferredDeliveryDate = action(
  '[ORDER] setPreferredDeliveryDate',
  payload<Moment>()
)
export const setOrderUser = action('[ORDER] setOrderUser', payload<string>())
export const setSearchString = action(
  '[ORDER] setSearchString',
  payload<string>()
)
export const setSearchActive = action(
  '[ORDER] setSearchActive',
  payload<boolean>()
)
export const setCartStep = action('[ORDER] setCartStep', payload<number>())

export const setCartOpen = action('[ORDER] setCartOpen', payload<boolean>())

export const resetOrderState = action('[ORDER] resetOrderState')
export const resetOrder = action('[ORDER] resetOrder')
export const sendOrder = action(
  '[ORDER] sendOrder',
  payload<OrderStatus | undefined>()
)
export const setOrderSending = action(
  '[ORDER] setOrderSending',
  payload<boolean>()
)
export const acceptOrder = action('[ORDER] acceptOrder')
export const startAcceptingOrder = action('[ORDER] startAcceptingOrder')

export const deleteOrder = action('[ORDER] delteOrder')

export const setTestOrder = action('[ORDER] setTestOrder', payload<boolean>())

const initialState: LocalOrder | null = null

export const orderReducer = reducer<LocalOrder>(
  initialState,

  on(setNewOrder, (state, { payload }) => ({
    ...payload,
    order_edited: null
  })),
  on(setOrderID, (state, { payload }) =>
    updateOrderState(state, {
      id: payload
    })
  ),
  on(setOrderEdited, (state, { payload }) =>
    updateOrderState(state, {
      order_edited: payload
    })
  ),
  on(setOrderSending, (state, { payload }) =>
    updateOrderState(state, {
      sending: payload
    })
  ),
  on(sendOrder, state =>
    updateOrderState(state, {
      sending: true
    })
  ),
  on(setSearchString, (state, { payload }) =>
    updateOrderState(state, {
      search: payload
    })
  ),
  on(setCartStep, (state, { payload }) =>
    updateOrderState(state, {
      cartStep: payload
    })
  ),
  on(setCartOpen, (state, { payload }) =>
    updateOrderState(state, {
      cart_open: payload
    })
  ),
  on(setSearchActive, (state, { payload }) =>
    updateOrderState(state, {
      searchActive: payload
    })
  ),
  on(setPreferredDeliveryDate, (state, { payload }) =>
    updateOrderState(state, {
      preferred_delivery_date: payload
    })
  ),
  on(setOrderUser, (state, { payload }) =>
    updateOrderState(state, {
      order_user: payload
    })
  ),
  on(setTestOrder, (state, { payload }) =>
    updateOrderState(state, {
      is_test_order: payload
    })
  ),
  /* if there is an initialAmount for the Product, reset it to its initAmount */
  /* on(resetOrder, state =>
    updateOrderState(state, {
      order_products: state.order_products.map(orderProduct => ({
        ...orderProduct,
        product: {
          ...orderProduct.product,
          amount: orderProduct.initialAmount || orderProduct.product.amount
        }
      }))
    })
  ), */
  on(resetOrderState, () => initialState)
)
