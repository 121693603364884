import { action, on, payload, reducer } from 'ts-action'

export const setScreenName = action(
  '[NAVIGATION] setScreenName',
  payload<string>()
)
export const switchScreen = action(
  '[NAVIGATION] switchScreen',
  payload<string>()
)

const initialState: string = null

export const screenNameReducer = reducer(
  initialState,
  on(setScreenName, (state, { payload }) => payload)
)
