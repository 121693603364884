import { action, payload, reducer } from 'ts-action'
/**
 * This is just used as an AppWide Action-Dispatcher
 * The Handling is done in the FavoritesMiddleware
 */
export const toggleFavorites = action(
  '[FAVORITES] toggleFavorites',
  payload<string>()
)

const initialState = {}

export const favoritesReducer = reducer(initialState)
