import { action, on, payload, reducer } from 'ts-action'
import clone from 'lodash/clone'
import { OrderProduct, OrderStatus } from '@hierfoods/interfaces'

export interface ReduxOrderProductsState {
  [id: string]: OrderProduct
}

export const resetOrderProductsState = action(
  '[ORDER_PRODUCTS] resetOrderProductsState'
)
export const setAllOrderProducts = action(
  '[ORDER_PRODUCTS] setAllOrderProducts',
  payload<OrderProduct[]>()
)

export const addOrderProduct = action(
  '[ORDER_PRODUCTS] addOrderProduct',
  payload<{
    orderProduct: OrderProduct
    id: string
  }>()
)

export const removeOrderProduct = action(
  '[ORDER_PRODUCTS] removeOrderProduct',
  payload<string>()
)

export const setOrderProductAmount = action(
  '[ORDER_PRODUCTS] setOrderProductAmount',
  payload<{
    amount: number
    id: string
  }>()
)

export const updateOrderProduct = action(
  '[ORDER_PRODUCTS] updateOrderProduct',
  payload<{
    amount: number
    id: string
  }>()
)
export const orderProductPlusPressed = action(
  '[ORDER_PRODUCTS] orderProductPlusPressed',
  payload<string>()
)
export const orderProductMinusPressed = action(
  '[ORDER_PRODUCTS] orderProductMinusPressed',
  payload<string>()
)
export const setOrderProductRemoteID = action(
  '[ORDER_PRODUCTS] setOrderProductRemoteID',
  payload<{
    id: string
    remoteID: string
  }>()
)
export const setOrderIDForOrderProducts = action(
  '[ORDER_PRODUCTS] setOrderIDForOrderProducts',
  payload<string>()
)
export const setOrderStatusForOrderProducts = action(
  '[ORDER_PRODUCTS] setOrderStatusForOrderProducts',
  payload<{
    id: string
    status: OrderStatus
  }>()
)
const initialState: {
  [id: string]: OrderProduct
} | null = {}

export const orderProductReducer = reducer(
  initialState,
  on(resetOrderProductsState, () => ({
    ...initialState
  })),
  on(addOrderProduct, (state, { payload }) => ({
    ...state,
    [payload.id]: payload.orderProduct
  })),
  on(setAllOrderProducts, (state, { payload }) => {
    const newState = {}
    payload.forEach(op => {
      newState[op.product_id] = op
    })
    return newState
  }),
  on(removeOrderProduct, (state, { payload }) => {
    const cloned = clone(state)
    delete cloned[payload]
    return cloned
  }),
  on(setOrderProductAmount, (state, { payload }) => ({
    ...state,
    [payload.id]: { ...state[payload.id], amount: payload.amount }
  })),
  on(setOrderProductRemoteID, (state, { payload }) => {
    if (!state[payload.id]) {
      // because `setOrderProductRemoteId` is called from a debounced!
      // autoDraftOrder function, it’s possible that by the time this
      // action handler is executed, the orderProduct was deleted from redux
      // state
      return state
    }
    return {
      ...state,
      [payload.id]: { ...state[payload.id], id: payload.remoteID }
    }
  }),
  on(setOrderIDForOrderProducts, (state, { payload }) => {
    const newState = { ...state }
    Object.keys(newState).forEach(key => {
      newState[key] = { ...newState[key], order_id: payload }
    })
    return newState
  }),
  on(setOrderStatusForOrderProducts, (state, { payload }) => {
    const newState = { ...state }
    Object.keys(newState).forEach(key => {
      newState[key] = { ...newState[key], status: payload }
    })
    return newState
  })
)
