import React, { useState } from 'react'
import { View } from 'react-native'
import { useTranslation } from 'react-i18next'
import { Button } from 'react-native-paper'
import { StackActions, useNavigation } from '@react-navigation/native'

import { TextInput } from '@components'

import Styles from './styles'

interface EnterCodeProps {
  overviewPath: string
  route: {
    name: string
  }
}

const EnterCode = ({ route, overviewPath }: EnterCodeProps) => {
  const { name } = route
  const navigation = useNavigation()
  const { t } = useTranslation()
  const [inputText, setInputText] = useState('')
  const goToOverview = () => {
    if (inputText)
      navigation.dispatch(
        StackActions.replace(overviewPath, {
          code: inputText
        })
      )
  }

  return (
    <View style={Styles.Container}>
      <TextInput onChangeText={setInputText} label={t(`${name}.placeholder`)} />
      <Button disabled={!inputText} onPress={goToOverview}>
        {t(`${name}.button`)}
      </Button>
    </View>
  )
}

export default EnterCode
