import * as React from 'react'
import { Text } from 'react-native-paper'

interface PriceProps {
  amount: number
}

const Price = ({ amount, ...props }: PriceProps) => {
  /* const priceFormatted = Number(amount / 100).toLocaleString(
    (i18next as any).default.language,
    {
      style: 'currency',
      currency: 'EUR'
    }
  ) */
  const formatted = `€ ${Number(amount / 100)
    .toFixed(2)
    .toString()
    .replace('.', ',')}`
  return (
    <Text {...props}>
      {
        formatted // Platform.OS === 'android' ? androidFormattedWorkaround : priceFormatted}
      }
    </Text>
  )
}
export { Price }
