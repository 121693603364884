import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  Container: {
    flex: 1,
    maxWidth: 800,
    width: '100%',
    overflow: 'hidden',
    alignSelf: 'center'
  },
  ButtonGroup: {
    flexDirection: 'row',
    padding: 16,
    justifyContent: 'space-between'
  },
  ConfirmButton: {
    marginLeft: 20
  }
})
