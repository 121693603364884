import React from 'react'
import { CustomTheme } from '@themes/variables'
import { Platform, StyleSheet, View, ViewProperties } from 'react-native'
import { useTheme } from 'react-native-paper'

interface DividerProperties extends ViewProperties {
  style?: object
  inset?: boolean | number
  platform?: 'auto' | 'both' | 'ios' | 'android'
}

const Styles = StyleSheet.create({
  Divider: {
    height: StyleSheet.hairlineWidth,
    marginLeft: 0
  }
})

const Divider = ({
  style = {},
  inset = false,
  platform = 'auto',
  ...otherProps
}: DividerProperties) => {
  const theme: CustomTheme = useTheme()
  const { colors } = theme
  const insetWidth = typeof inset === 'number' ? inset : 16

  let show = true
  if (Platform.OS === 'ios' && platform === 'android') show = false
  if (Platform.OS === 'android' && (platform === 'auto' || platform === 'ios'))
    show = false
  return show ? (
    <View
      {...otherProps}
      style={[
        Styles.Divider,
        {
          backgroundColor: colors.placeholder,
          marginLeft: inset && insetWidth
        },
        style
      ]}
    />
  ) : null
}
export { Divider }
