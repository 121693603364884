import firebase from 'firebase'
import { toggleFavorites } from '@redux/reducer/favorites'
import clone from 'lodash/cloneDeep'
import { globalStore } from '@store'
import Sentry from '@errorhandler'
import { showError } from '../reducer/feedback'

const toggleFavoritesForProductID = (store, productID: string) => {
  try {
    const {
      supplier: { supplier: supplierID }
    } = store.getState()
    const {
      firebase: {
        auth: { uid },
        data: firebaseData
      }
    }: any = globalStore.getState()
    const ref = firebase.database().ref(`favorites/${uid}/${supplierID}/`)
    const currentFavorites = clone(
      firebaseData?.favorites?.[uid]?.[supplierID] || []
    )

    const existingIndex = currentFavorites.indexOf(productID)
    if (existingIndex > -1) {
      currentFavorites.splice(existingIndex, 1)
    } else currentFavorites.push(productID)
    ref.set(currentFavorites)
  } catch (err) {
    Sentry.Native.captureException(err)
    store.dispatch(showError({ message: 'errors.products.favorites' }))
  }
}

const favoritesMiddleware = store => next => action => {
  // Do something in here, when each action is dispatched
  next(action) // Continue the Action
  switch (action.type) {
    case toggleFavorites.type:
      toggleFavoritesForProductID(store, action.payload)
      break

    default:
      break
  }
}

export { favoritesMiddleware }
