/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from '@components'
import { Scale } from '@helpers/helpers'
import useBackHandler from '@helpers/useBackHandler'
import { useKeyboard } from '@helpers/useKeyboard'
import { useNavigation } from '@react-navigation/native'
import { Category } from '@hierfoods/interfaces'
import { setAccepting } from '@redux/reducer/accepting'
import {
  deleteOrder,
  manualAutoDraftOrder,
  setCartStep,
  setSearchString
} from '@redux/reducer/order'
import {
  selectAccepting,
  selectCartStep,
  selectOrderEdited,
  selectOrderProducts,
  selectOrderStatus,
  selectSearch,
  selectSupplierID,
  selectUID
} from '@redux/selectors'
import logService, { useFunnel } from '@services/log-service'
import { useScopedDispatch, useScopedSelector } from '@store'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Keyboard, Platform, StyleSheet, useColorScheme } from 'react-native'
import {
  Dialog,
  Portal,
  Provider as PaperProvider,
  useTheme
} from 'react-native-paper'

import { useSelector } from 'react-redux'

const Styles = StyleSheet.create({
  BackDialogTitle: Platform.select({
    ios: {
      marginBottom: 16
    }
  }),
  BackDialogButtons: {
    width: '100%',
    backgroundColor: 'transparent',
    borderWidth: 0,
    borderRadius: 0,
    height: 50,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    elevation: 0
  },
  BackDialogContainer: {
    padding: 0,
    flexDirection: 'row',
    borderTopWidth: StyleSheet.hairlineWidth
  },
  Dialog: Platform.select({
    ios: {
      borderRadius: Scale(14)
    }
  }),
  DialogTitle: Platform.select({
    ios: {
      textAlign: 'center',
      fontSize: Scale(17),
      fontWeight: '600',
      marginBottom: Scale(2)
    }
  }),
  DialogContent: Platform.select({
    ios: {
      textAlign: 'center',
      fontSize: Scale(13)
    }
  }),

  DialogButtonRight: {
    borderLeftWidth: StyleSheet.hairlineWidth
  }
})

interface BackHandlerInterface {
  onBackPress?: () => boolean
  activeCategory?: Category
}
const BackHandler = ({ onBackPress, activeCategory }: BackHandlerInterface) => {
  const theme = useTheme()
  const colorScheme = useColorScheme()
  const navigation = useNavigation()
  const scopedDispatch = useScopedDispatch()
  const { t } = useTranslation()
  const keyboard = useKeyboard()

  const [backAlertVisible, setBackAlertVisible] = useState(false)

  const barcodeActive = useScopedSelector(selectSearch)
  const cartStep = useScopedSelector(selectCartStep)
  const currentStatus = useScopedSelector(selectOrderStatus)
  const accepting = useScopedSelector(selectAccepting)
  const orderEdited = useScopedSelector(selectOrderEdited)
  const orderProducts = useScopedSelector(selectOrderProducts)
  const supplierId = useScopedSelector(selectSupplierID)
  const uid = useSelector(selectUID)

  const funnel = useFunnel()

  const closeSearch = useCallback(() => {
    scopedDispatch(setSearchString(''))
    Keyboard.dismiss()
    return true
  }, [])

  const saveDraft = () => {
    scopedDispatch(manualAutoDraftOrder())
    navigation.popToTop()
    funnel.log({
      componentName: 'Order',
      entity: 'CreateDraft',
      action: 'Success',
      attributes: {
        supplier_id: supplierId,
        merchant_id: uid
      }
    })
  }

  const deleteDraft = async () => {
    scopedDispatch(deleteOrder())
    navigation.popToTop()
    funnel.log({
      componentName: 'Order',
      entity: 'DiscardDraft',
      action: 'Success',
      attributes: {
        supplier_id: supplierId,
        merchant_id: uid
      }
    })
  }

  const discardOrder = () => {
    logService('merchant_dismiss_changed_order', undefined, {
      choice: 'discard'
    })
    navigation.popToTop()
  }

  const backWasPressed = (): boolean => {
    if (keyboard.keyboardShown) {
      Keyboard.dismiss()
      return true
    }

    if (barcodeActive?.length > 0) return closeSearch()

    if (cartStep === 2) {
      scopedDispatch(setCartStep(1))
      return true
    }

    if (accepting) scopedDispatch(setAccepting(false))

    if (onBackPress) onBackPress()

    // we will ask user about saving the draft only if it has order products
    if (orderEdited && !activeCategory && Object.keys(orderProducts).length) {
      setBackAlertVisible(true)

      return true
    }

    navigation.goBack()

    return true
  }

  useBackHandler(true, backWasPressed, [
    cartStep,
    accepting,
    orderEdited,
    barcodeActive?.length,
    keyboard.keyboardShown,
    orderProducts
  ])
  return (
    <Portal>
      <PaperProvider
        theme={{
          ...theme,
          colors: {
            ...theme.colors,
            backdrop: colorScheme === 'dark' ? '#000000bb' : '#00000066'
          }
        }}>
        <Dialog
          visible={backAlertVisible}
          dismissable={false}
          style={Styles.Dialog}>
          <Dialog.Title style={[Styles.DialogTitle, Styles.BackDialogTitle]}>
            {currentStatus === 'draft'
              ? t('orderCreate.onDismiss.draftTitle')
              : t('orderCreate.onDismiss.orderTitle')}
          </Dialog.Title>
          <Dialog.Actions
            style={[
              Styles.BackDialogContainer,
              { borderColor: theme.colors.placeholder }
            ]}>
            {currentStatus === 'draft' ? (
              <>
                <Button
                  mode="text"
                  type="primary"
                  style={[Styles.BackDialogButtons]}
                  onPress={deleteDraft}>
                  {t('orderCreate.onDismiss.deleteDraft')}
                </Button>
                <Button
                  mode="text"
                  type="secondary"
                  style={[
                    Styles.BackDialogButtons,
                    Styles.DialogButtonRight,
                    { borderColor: theme.colors.placeholder }
                  ]}
                  onPress={saveDraft}>
                  {t('orderCreate.onDismiss.saveDraft')}
                </Button>
              </>
            ) : (
              <>
                <Button
                  mode="text"
                  type="primary"
                  style={[Styles.BackDialogButtons]}
                  onPress={() => {
                    setBackAlertVisible(false)
                  }}>
                  {t('orderCreate.onDismiss.cancelDialog')}
                </Button>
                <Button
                  mode="text"
                  type="secondary"
                  style={[
                    Styles.BackDialogButtons,
                    Styles.DialogButtonRight,
                    { borderColor: theme.colors.placeholder }
                  ]}
                  onPress={discardOrder}>
                  {t('orderCreate.onDismiss.dismissOrder')}
                </Button>
              </>
            )}
          </Dialog.Actions>
        </Dialog>
      </PaperProvider>
    </Portal>
  )
}

export { BackHandler }
