import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { View } from 'react-native'
import { useTranslation } from 'react-i18next'
import { showMessage } from 'react-native-flash-message'
import { StackActions, useNavigation, useRoute } from '@react-navigation/native'
import axios from 'axios'

import {
  Button,
  Divider,
  OrderDetails,
  OrderDetailsHeader,
  OrderDetailsTotal,
  OrderDeliverySelector,
  Loader
} from '@components'

import { OrderStatus } from '@hierfoods/interfaces'
import { FunnelProvider } from '@services/log-service'
import { useFirebaseData } from '@helpers/useFirebaseData'
import { useCompleteProducts } from '@helpers/useCompleteProducts'
import {
  makeTimestampToMoment,
  getProductsTotal,
  momentToUnixInt,
  getCloudFunctionsBaseURL,
  mapToArrayWithID
} from '../../helpers/helpers'

import Styles from './styles'

const axiosClient = axios.create({
  baseURL: getCloudFunctionsBaseURL(),
  headers: { 'Cache-Control': 'no-cache' }
})

const DispatchAdvise = () => {
  const navigation = useNavigation()
  const route = useRoute()
  const { t } = useTranslation()

  const orderShortCode = route.params?.code

  const goBackToEnterScreen = () => {
    navigation.dispatch(StackActions.replace('enterDispatchOrderCode'))
  }

  if (!orderShortCode) goBackToEnterScreen()

  const [orderStatus, setOrderStatus] = useState<OrderStatus>()
  const [preferredDeliveryDate, setPreferredDeliveryDate] = useState(null)
  const [total, setTotal] = useState(0)
  const [title, setTitle] = useState('Lädt...')
  const [isEditMode, setEditMode] = useState(false)

  const transformOrder = useCallback(
    orders => mapToArrayWithID(orders)?.[0],
    []
  )

  const order = useFirebaseData(
    'orders',
    {
      orderByChild: 'short_code',
      equalTo: orderShortCode,
      waitFor: orderShortCode
    },
    transformOrder
  )

  if (order === undefined) goBackToEnterScreen()

  const orderNumber = order?.order_number

  // "complete" means that we merged the complete product data from
  // order_products which contains a snapshot of some data at the moment of
  // ordering
  // /products/:supplierId which contains current updated product data
  // and from linked properties like product_types, bulk_units etc. that contain
  // meta information stored as lists of titles with ids
  const [completeProducts, updateProductAmount, updateProductPrice] =
    useCompleteProducts(order)

  const merchantId = order?.merchant_id
  const merchantName = useFirebaseData(`merchants/${merchantId}/name`, {
    waitFor: order
  })

  const supplierId = order?.supplier_id
  const supplierName = useFirebaseData(`suppliers/${supplierId}/name`, {
    waitFor: order
  })

  const orderUser = useFirebaseData(
    `order_users/${merchantId}/${order?.order_user}/title`,
    { waitFor: order && order.order_user }
  )

  const merchantSupplier = useFirebaseData(
    `merchant_suppliers/${merchantId}/${supplierId}`,
    { waitFor: order }
  )
  const customerId = merchantSupplier?.customerID

  useEffect(() => {
    if (order?.status) {
      setOrderStatus(order.status === 'sent' ? 'new' : order.status)
    }

    if (order?.preferred_delivery_date) {
      setPreferredDeliveryDate(
        makeTimestampToMoment(order?.preferred_delivery_date)
      )
    }
  }, [order])

  useMemo(() => {
    if (completeProducts) {
      const total = getProductsTotal(completeProducts)
      setTotal(total)
    }
  }, [completeProducts])

  useEffect(() => {
    let title = ''
    if (orderNumber && merchantName) {
      title = `#${orderNumber} - ${merchantName}`
    }
    if (customerId) {
      title += ` `
    }
    if (orderUser) {
      title += ` (${orderUser})`
    }
    setTitle(title)
  }, [orderNumber, merchantName, orderUser, customerId])

  useEffect(() => {
    navigation.setOptions({
      title,
      headerStyle: {
        borderBottomWidth: 0
      }
    })
  }, [title, navigation])

  const orderData = order
    ? [
        {
          title: t(`orderStatus.${orderStatus}`),
          type: orderStatus,
          data: completeProducts ?? []
        }
      ]
    : []

  const dispatchAdvise = async () => {
    const dispatchedProducts = completeProducts.reduce(
      (products, { product }) => ({
        ...products,
        [product.id]: {
          supplier_product_id: product?.product_id,
          amount: product?.amount,
          price: product?.originalData?.bulkPrice
        }
      }),
      {}
    )
    const dispatchAdvise = {
      date: momentToUnixInt(preferredDeliveryDate),
      order_id: order.id,
      products: dispatchedProducts
    }
    await axiosClient
      .post('defaults-createDelivery', dispatchAdvise, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then(() => {
        // TODO: where are these logs going? what do we want to log?
        // logService('supplier_order_confirm')
        showMessage({
          message: 'Despatch advise is saved',
          type: 'success',
          icon: 'auto'
        })
      })
      .catch(e => {
        console.log(e)
        showMessage({
          message: e?.message,
          type: 'danger',
          icon: 'auto'
        })
        return false
      })
  }

  let orderHeaderAdditionalText = ''
  if (merchantName) {
    orderHeaderAdditionalText += merchantName
  }
  if (customerId) {
    orderHeaderAdditionalText += ` (${customerId})`
  }

  const isLoading = !order || !completeProducts

  return (
    <FunnelProvider
      sessionAttributes={{
        flow_name: 'SupplierOrderDetails'
      }}>
      <View style={Styles.Container}>
        {isLoading && <Loader />}
        <OrderDetailsHeader date={preferredDeliveryDate} />
        <OrderDetails
          data={orderData}
          isLocked={!isEditMode || orderStatus === 'confirmed'}
          orderHeaderAdditionalText={orderHeaderAdditionalText}
          updateProductAmount={updateProductAmount}
          updateProductPrice={updateProductPrice}
        />
        <OrderDetailsTotal total={total} />
        <Divider inset={0} platform="both" />
        {orderStatus === 'new' && (
          <View style={Styles.ButtonGroup}>
            {isEditMode && preferredDeliveryDate && (
              <OrderDeliverySelector
                deliveryDate={preferredDeliveryDate}
                supplierName={supplierName}
                merchantSupplier={merchantSupplier}
                selectDeliveryDate={setPreferredDeliveryDate}
              />
            )}
            {isEditMode && (
              <Button
                style={Styles.ConfirmButton}
                onPress={dispatchAdvise}
                size="L">
                {t('dispatchAdvise.confirmButton')}
              </Button>
            )}
            {!isEditMode && (
              <Button
                style={Styles.ConfirmButton}
                onPress={() => {
                  setEditMode(true)
                }}
                size="L">
                {t('dispatchAdvise.editButton')}
              </Button>
            )}
          </View>
        )}
      </View>
    </FunnelProvider>
  )
}

export default DispatchAdvise
