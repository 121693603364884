import { getPriceForOrderProduct, orderByWithUmlaut } from '@helpers/helpers'
import { createSelector } from 'reselect'
import {
  LocalOrder,
  OrderProduct,
  OrderProductDetails
} from '@hierfoods/interfaces'
/**
 * Scoped Selectors
 */
export const selectSupplierID = s => s?.supplier?.supplier as string | undefined
export const selectOrder = s => s?.order as LocalOrder | undefined
export const selectAccepting = s => s.accepting as boolean

export const selectOrderStatus = createSelector(
  selectOrder,
  order => order?.status
)
export const selectOrderUser = createSelector(
  selectOrder,
  order => order?.order_user
)
export const selectOrderArchived = createSelector(
  selectOrder,
  order => order?.archived
)
export const selectOrderNumber = createSelector(
  selectOrder,
  order => order?.order_number
)
export const selectOrderUpdatedAt = createSelector(
  selectOrder,
  order => order?.updated_at
)
export const selectSearchActive = createSelector(
  selectOrder,
  order => order?.searchActive
)
export const selectCartStep = createSelector(
  selectOrder,
  order => order?.cartStep || 1
)
export const selectCartOpen = createSelector(
  selectOrder,
  order => !!order?.cart_open
)
export const selectIsOrderSending = createSelector(
  selectOrder,
  order => !!order?.sending
)
export const selectPreferredDeliveryDate = s =>
  s?.order?.preferred_delivery_date

export const selectSearch = createSelector(selectOrder, order => order?.search)
export const selectOrderID = createSelector(selectOrder, order => order?.id)
export const selectOrderEdited = createSelector(
  selectOrder,
  order => order?.order_edited
)
export const selectOrderProducts = s => s.orderProducts as OrderProduct[] | []
export const selectOrderProductsAsArray = createSelector(
  selectOrderProducts,
  orderProducts => Object.values(orderProducts)
)
export const selectOrderProductIDs = createSelector(
  selectOrderProducts,
  orderProducts =>
    orderByWithUmlaut(
      Object.entries(orderProducts),
      ['1.originalData.productType', '1.originalData.name', '0'],
      ['asc', 'asc', 'asc']
    ).map(([key]) => key)
)
export const selectLocalProductsLength = createSelector(
  selectOrderProductIDs,
  ids => ids.length
)
export const selectSum = (allProducts: { [id: string]: OrderProductDetails }) =>
  createSelector(
    selectOrderProductsAsArray,
    orderProducts =>
      orderProducts?.reduce<number>(
        (previous, orderProduct) =>
          previous +
          getPriceForOrderProduct({
            product: orderProduct,
            productDetails: allProducts?.[orderProduct?.product_id]
          }) *
            orderProduct.amount,
        0
      ) || 0
  )

export const selectAmountForOrderProduct = (productId: string) =>
  createSelector(
    selectOrderProducts,
    orderProducts => orderProducts?.[productId]?.amount || 0
  )

export const selectAcceptedForOrderProduct = (productId: string) =>
  createSelector(
    selectOrderProducts,
    orderProducts => orderProducts?.[productId]?.accepted || 0
  )

export const selectOrderProductById = (productId: string) =>
  createSelector(
    selectOrderProducts,
    orderProducts => orderProducts[productId] || { amount: 0 }
  )

export const selectInitialAmountForOrderProduct = productId =>
  createSelector(
    selectOrderProductById(productId),
    (
      orderProduct // ### TODO
    ) => orderProduct?.initialAmount || orderProduct?.product?.amount || 0
  )

export const selectAcceptingIconNameForProduct = productId =>
  createSelector(
    selectAmountForOrderProduct(productId),
    selectInitialAmountForOrderProduct(productId),
    selectAcceptedForOrderProduct(productId),
    (amount, initialAmount, accepted) => {
      // ### TODO
      if (amount !== initialAmount) return 'alert-circle'
      if (accepted) return 'checkbox-marked-circle'
      return 'circle-outline'
    }
  )
