import { useEffect, useState } from 'react'
import { AppState } from 'react-native'

export default function useForeground() {
  const [isForeground, setIsForeground] = useState(
    AppState.currentState === 'active'
  )
  const handleAppStateChange = state => {
    if (state === 'active') setIsForeground(true)
    else setIsForeground(false)
  }
  useEffect(() => {
    AppState.addEventListener('change', handleAppStateChange)
    return () => {
      AppState.removeEventListener('change', handleAppStateChange)
    }
  }, [])

  return isForeground
}
