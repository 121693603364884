import Constants from 'expo-constants'

const firebaseConfig = {
  apiKey: 'AIzaSyCIfP1nS1OpJaxFVg3zBSVOz6K0WIn-JYw',
  authDomain: 'hierfoods.firebaseapp.com',
  databaseURL: 'https://hierfoods.firebaseio.com',
  projectId: 'hierfoods',
  storageBucket: 'hierfoods.appspot.com',
  messagingSenderId: '153211070398',
  appId: '1:153211070398:web:22e72e04c19b1197103bef',
  measurementId: 'G-BVHB1DPHHL'
}
const firebaseConfigStaging = {
  apiKey: 'AIzaSyCDEq-9-Kyw5drQ-HLkXJ5NWiXVj8Z3IqM',
  authDomain: 'hierfoods-staging.firebaseapp.com',
  databaseURL: 'https://hierfoods-staging.firebaseio.com',
  projectId: 'hierfoods-staging',
  storageBucket: 'hierfoods-staging.appspot.com',
  messagingSenderId: '930174701904',
  appId: '1:930174701904:web:9d65b24be2c4d02176dc55',
  measurementId: 'G-BMSVR1N8GV'
}

const firebaseConfigDevelopment = {
  apiKey: 'AIzaSyBFFn0x2j93-vTly0pyF6wpIi00oR2pQ2w',
  authDomain: 'hierfoods-develop.firebaseapp.com',
  databaseURL:
    'https://hierfoods-develop-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'hierfoods-develop',
  storageBucket: 'hierfoods-develop.appspot.com',
  messagingSenderId: '446871847356',
  appId: '1:446871847356:web:5d5525e8dcce5c63ccebb9'
}

const registerFirebase = firebase => {
  if (Constants.manifest.extra?.staging) {
    console.log('Using Staging Firebase Config')
    return firebase.initializeApp(firebaseConfigStaging)
  }
  if (Constants.manifest.extra?.development) {
    console.log('Using Development Firebase Config')
    return firebase.initializeApp(firebaseConfigDevelopment)
  }
  if (Constants.manifest.extra?.local) {
    console.log('Using Local Firebase Config')
    // Local uses the same setting as development but via emulator
    return firebase.initializeApp(firebaseConfigDevelopment)
  }
  console.log('Using Production Firebase Config')
  return firebase.initializeApp(firebaseConfig)
}

export default registerFirebase
