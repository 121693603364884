import { Icon } from '@components'
import React, { memo } from 'react'
import { StyleSheet, TouchableOpacity } from 'react-native'
import { Text, useTheme } from 'react-native-paper'
import { Scale } from '@helpers/helpers'

interface CategoryItemProps {
  category: {}
  onPress: () => void
}

const CategoryItem = ({ category, onPress }: CategoryItemProps) => {
  const theme = useTheme()
  const Styles = StyleSheet.create({
    Container: {
      paddingHorizontal: Scale(16),
      paddingVertical: 16,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    Title: { fontSize: 16 }
  })

  return category ? (
    <TouchableOpacity style={Styles.Container} onPress={onPress}>
      <Text style={Styles.Title}>{category.title}</Text>
      <Icon name="arrow-forward" color={theme.colors.text} size={Scale(16)} />
    </TouchableOpacity>
  ) : null
}
function areEqual(prevProps, nextProps) {
  return prevProps?.category?.title === nextProps?.category?.title
}

const MemoCategoryItem = memo(CategoryItem, areEqual)
export { MemoCategoryItem as CategoryItem }
