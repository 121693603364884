import { selectSearchActive } from '@redux/selectors'
import { BarCodeScannedCallback, BarCodeScanner } from 'expo-barcode-scanner'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, Dimensions, StyleSheet, View } from 'react-native'
import Modal from 'react-native-modal'
import { Appbar, Text } from 'react-native-paper'
import { useScopedSelector } from '@store'

const { width, height } = Dimensions.get('window')
const scannerHeight = (width / 3) * 4

const BarcodeModal = React.memo(
  ({
    handleBarCodeScanned,
    handleBarCodeClose,
    isFocused = true
  }: {
    handleBarCodeScanned: BarCodeScannedCallback
    handleBarCodeClose: () => void
    isFocused?: boolean
  }) => {
    const Styles = React.useMemo(
      () =>
        StyleSheet.create({
          BarcodeScanner: {
            width: '100%',
            position: 'absolute',
            bottom: (height - scannerHeight) / 2,
            height: scannerHeight,
            zIndex: -1
          },
          BarcodeContainer: {
            backgroundColor: 'black',
            padding: 0,
            margin: 0,
            justifyContent: 'flex-start'
          },
          BarcodeHeader: {
            backgroundColor: 'transparent',
            zIndex: 10,
            width: '100%'
          },
          BarcodeDescription: {
            position: 'absolute',
            bottom: '20%',
            textAlign: 'center',
            color: 'white',
            fontSize: 20,
            width: '100%',
            zIndex: 2
          },
          BardcodeScanArea: {
            width: '100%',
            bottom: (height - scannerHeight) / 2,
            height: scannerHeight,
            borderColor: 'rgba(0,0,0,0.5)',
            alignSelf: 'center',
            borderTopWidth: scannerHeight * 0.45,
            borderBottomWidth: scannerHeight * 0.45,
            borderLeftWidth: width * 0.25,
            borderRightWidth: width * 0.25,
            position: 'absolute',
            zIndex: 1
          },
          BarcodeScanAreaContainer: {
            backgroundColor: 'black',
            flex: 1,
            justifyContent: 'center'
          }
        }),
      []
    )
    const { t } = useTranslation()
    const barcodeActive = useScopedSelector(selectSearchActive)
    const [scannerVisible, setScannerVisible] = useState(false)
    const [hasPermission, setHasPermission] = useState(null)

    useEffect(() => {
      if (barcodeActive) {
        ;(async () => {
          const { status } = await BarCodeScanner.requestPermissionsAsync()
          setHasPermission(status === 'granted')
        })()
      }
    }, [barcodeActive])

    useEffect(() => {
      if (hasPermission === false) Alert.alert(t('camera.youNeedToAllowCamera'))
    }, [hasPermission, t])

    const onModalShow = () => setScannerVisible(true)
    const onModalHide = () => setScannerVisible(false)
    if (!hasPermission) return null
    return (
      <Modal
        style={Styles.BarcodeContainer}
        isVisible={barcodeActive && isFocused}
        useNativeDriver
        onModalShow={onModalShow}
        onModalHide={onModalHide}
        onBackdropPress={handleBarCodeClose}
        onBackButtonPress={handleBarCodeClose}>
        <Appbar.Header style={Styles.BarcodeHeader}>
          <Appbar.Action
            icon="close"
            onPress={handleBarCodeClose}
            color="white"
          />
        </Appbar.Header>
        <View style={Styles.BarcodeScanAreaContainer}>
          {scannerVisible && (
            <BarCodeScanner
              onBarCodeScanned={handleBarCodeScanned}
              style={Styles.BarcodeScanner}
              barCodeTypes={[
                BarCodeScanner.Constants.BarCodeType.ean8,
                BarCodeScanner.Constants.BarCodeType.ean13,
                BarCodeScanner.Constants.BarCodeType.upc_a,
                BarCodeScanner.Constants.BarCodeType.upc_e,
                BarCodeScanner.Constants.BarCodeType.upc_ean
              ]}
            />
          )}
          <View style={Styles.BardcodeScanArea} />
        </View>
        <Text style={Styles.BarcodeDescription}>{t('search.waiting')}</Text>
      </Modal>
    )
  }
)
export { BarcodeModal }
