import * as React from 'react'
import { Text, View, StyleSheet } from 'react-native'
import { useTheme } from 'react-native-paper'
import { useTranslation } from 'react-i18next'

import { Divider, Price } from '@components'

import { CustomTheme } from '@themes/variables'

const Styles = StyleSheet.create({
  Price: {
    fontSize: 20,
    fontWeight: 'bold'
  },
  PriceTitle: {
    fontSize: 20
  },
  PriceContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 16,
    paddingVertical: 8
  }
})

interface OrderDetailsTotalProps {
  total: number
}

const OrderDetailsTotal = ({ total }: OrderDetailsTotalProps) => {
  const { t } = useTranslation()
  const theme: CustomTheme = useTheme()

  if (!total) return null

  return (
    <>
      <Divider inset={0} platform="both" />
      <View style={Styles.PriceContainer}>
        <Text style={Styles.PriceTitle}>{t('orderDetails.priceTitle')}</Text>
        <Price
          amount={total}
          style={[Styles.Price, { color: theme.colors.primary }]}
        />
      </View>
    </>
  )
}
export { OrderDetailsTotal }
