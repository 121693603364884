import { useEffect, useState } from 'react'
import * as Notifications from 'expo-notifications'

export default function useDeviceToken() {
  const [deviceToken, setDeviceToken] = useState('')

  const getDeviceToken = async () => {
    // Dev workaround because simulator has no push-notifications
    if (__DEV__) {
      setDeviceToken('none')
    } else {
      const token = await (await Notifications.getExpoPushTokenAsync()).data
      setDeviceToken(token.replace('ExponentPushToken[', '').replace(']', ''))
    }
  }
  useEffect(() => {
    getDeviceToken()
  }, [])

  return deviceToken
}
