/* eslint-disable react-hooks/exhaustive-deps */
import firebase from 'firebase'
import { Maintenance } from '@hierfoods/interfaces'
import { useEffect, useRef, useState } from 'react'
import semver from 'semver'
import Constants from 'expo-constants'
import * as Updates from 'expo-updates'

export default function useMaintenanceMode(): [
  boolean,
  Maintenance | undefined
] {
  const reference = useRef(firebase.database().ref('/maintenance'))
  const [showMaintenance, setShowMaintenance] = useState(false)
  const [maintenanceConfig, setMaintenanceConfig] = useState<
    Maintenance | undefined
  >()

  const checkIfSmallerOrEqualVersion = (
    onlineVersion: string,
    localVersion: string
  ): boolean => {
    // prevent runtime error if a badly formatted verison is set in Firebase
    const coercedOnlineVersion = semver.coerce(onlineVersion.toString())
    const onlineVersionValid = semver.valid(coercedOnlineVersion)
    return semver.gte(
      onlineVersionValid ? coercedOnlineVersion : '1000.0.0',
      localVersion
    )
  }

  const shouldShowMaintenance = (config: Maintenance | undefined) => {
    if (!config || !config.active) return false

    // Check if the revision-ID mismatches
    if (
      config.forceAppRevision &&
      // @ts-ignore
      Updates.manifest?.revisionId &&
      config.forceAppRevision === (Updates.manifest as any).revisionId
    )
      return false

    // Check if our current Version is smaller than the required one
    if (
      config.maximumAppVersion &&
      !checkIfSmallerOrEqualVersion(
        config.maximumAppVersion,
        Constants.nativeAppVersion
      )
    )
      return false

    return true
  }

  useEffect(() => {
    reference.current.on('value', snapshot => {
      const value = snapshot.val() as Maintenance | undefined
      setShowMaintenance(shouldShowMaintenance(value))
      setMaintenanceConfig(value)
    })
    return () => reference.current.off()
  }, [reference])
  return [showMaintenance, maintenanceConfig]
}
