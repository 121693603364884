import logService from '@services/log-service'
import {
  acceptOrder,
  deleteOrder,
  sendOrder,
  setNewOrder,
  setPreferredDeliveryDate,
  startAcceptingOrder,
  manualAutoDraftOrder
} from '../reducer/order'
import { resetOrderProductsState } from '../reducer/orderProducts'
import {
  acceptCurrentOrder,
  deleteCurrentOrder,
  sendOutOrder,
  startAccepting,
  debouncedAutoDraftOrder,
  markTestOrders
} from './orderFunctions'

const orderMiddleware = store => next => action => {
  // Do something in here, when each action is dispatched

  next(action) // Continue the Action
  switch (action.type) {
    case deleteOrder.type:
      deleteCurrentOrder(store, action)
      break
    case sendOrder.type:
      sendOutOrder(store, action)
      break
    case manualAutoDraftOrder.type:
      debouncedAutoDraftOrder(store.getState(), store.dispatch, action)
      break
    case startAcceptingOrder.type:
      startAccepting(store, action)
      break
    case acceptOrder.type:
      acceptCurrentOrder(store, action)
      break
    case setNewOrder.type:
      logService('merchant_start_order', [
        'supplierID',
        'screen',
        'language',
        'app_version'
      ])
      store.dispatch(resetOrderProductsState())
      markTestOrders(store)
      break
    case setPreferredDeliveryDate.type:
      logService('merchant_change_preferred_delivery_date', [
        'supplierID',
        'language',
        'view',
        'action',
        'app_version',
        'count_products_in_cart',
        'cart_total',
        'minimum_value_reached'
      ])
      logService('supplier_change_delivery_date', [
        'language',
        'app_version',
        'count_products_in_cart',
        'cart_total',
        'supplierID',
        'merchantID',
        'orderID'
      ])
      break
    default:
      break
  }
}

export { orderMiddleware }
