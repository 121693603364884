import React from 'react'
import { SectionList } from 'react-native'

import { Divider, OrderSectionHeader, SupplierOrderProduct } from '@components'

import { OrderProducts } from '@hierfoods/interfaces'

interface OrderDetailsProps {
  data: []
  isLocked: boolean
  orderHeaderAdditionalText: string
  updateProductAmount: () => void
  updateProductPrice: () => void
}

const OrderDetails = ({
  data,
  isLocked,
  orderHeaderAdditionalText,
  updateProductAmount,
  updateProductPrice
}: OrderDetailsProps) => (
  <SectionList
    sections={data}
    ItemSeparatorComponent={() => <Divider inset />}
    SectionSeparatorComponent={() => <Divider />}
    keyExtractor={(item, index) => item?.product?.product_id || index}
    renderItem={({ item }) => (
      <SupplierOrderProduct
        orderProduct={item as OrderProducts}
        locked={isLocked}
        onProductAmountChange={updateProductAmount}
        onPriceChange={updateProductPrice}
      />
    )}
    renderSectionHeader={({ section: { title, type } }) => (
      <OrderSectionHeader
        title={title}
        type={type}
        additionalInfo={orderHeaderAdditionalText}
      />
    )}
    stickySectionHeadersEnabled={false}
  />
)
export { OrderDetails }
