/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-plusplus */
import { Button, Icon } from '@components'
import { DeliveryDay } from '@hierfoods/interfaces'
import moment, { Moment } from 'moment'
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal, StyleSheet, View } from 'react-native'
import { Text, useTheme } from 'react-native-paper'
import { ListWithIcon, ListItemInterface } from './ListWithIcon'

interface DateButtonInterface {
  onPress: () => void
  preferredDeliveryDate: Moment
}

interface DateModalInterface {
  visible?: boolean
  onDismiss?: () => void
  supplierName: string
  value: Moment
  merchantSupplier: any
  onDateChange: (Moment) => void
}

const DateButton = ({
  onPress,
  preferredDeliveryDate
}: DateButtonInterface) => {
  const { t } = useTranslation()

  const calendarOptions = {
    lastDay: `[${t('calendar.yesterday')}]`,
    sameDay: `[${t('calendar.today')}]`,
    nextDay: `[${t('calendar.tomorrow')}]`,
    nextWeek: `dddd, D.M.`,
    sameElse: 'L'
  }
  if (!preferredDeliveryDate?.calendar) return null
  return (
    <Button onPress={onPress} type="select" mode="outlined">
      {`${preferredDeliveryDate.calendar(null, calendarOptions)} `}
      <Icon size={16} name="chevron-down-outline" forcePlatform />
    </Button>
  )
}

const DateModal = ({
  visible = false,
  onDismiss = () => null,
  supplierName,
  value,
  merchantSupplier,
  onDateChange
}: DateModalInterface) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const [days, setDays] = useState<any[] | null>()
  const [numberOfDays, setNumberOfDays] = useState(7)

  const calendarOptions = {
    lastDay: `[${t('calendar.yesterday')}]`,
    sameDay: `[${t('calendar.today')}]`,
    nextDay: `[${t('calendar.tomorrow')}]`,
    nextWeek: `dddd, D.M.`,
    sameElse: 'dddd, D.M.'
  }
  // Convert Legacy-Data to new Format
  const mappedDeliveryDays: DeliveryDay[] = useMemo(() => {
    if (!merchantSupplier.deliveryDays) return null
    if (typeof merchantSupplier.deliveryDays[0] === 'number')
      return merchantSupplier.deliveryDays.map(day => ({ day }))
    return merchantSupplier.deliveryDays
  }, [merchantSupplier.deliveryDays])

  const Styles = StyleSheet.create({
    Modal: {
      justifyContent: 'flex-end',
      margin: 0,
      width: '100%',
      borderWidth: 0
    },
    ModalContent: {
      backgroundColor: theme.colors.surface,
      paddingVertical: 32,
      borderRadius: 8
    },
    AlertInactive: {
      marginLeft: 5,
      fontSize: 16
    },
    AlertActive: {
      marginLeft: 5,
      fontSize: 16,
      color: 'orange'
    }
  })

  const onDataChanged = date => {
    onDateChange(moment(date).hour(12).minute(0).second(0))
  }

  const isPastOrderDeadline = (day): boolean => {
    if (!mappedDeliveryDays) return false
    const matchingDay = mappedDeliveryDays.find(
      m => m.day === day.value.locale('de').weekday()
    )
    if (!matchingDay || matchingDay.orderBy === undefined) return false
    return moment(day.value)
      .endOf('day')
      .subtract(matchingDay.orderBy, 'hours')
      .isBefore(moment())
  }

  const isDeliveryDay = (day): boolean => {
    if (!mappedDeliveryDays) return true
    return mappedDeliveryDays.some(
      deliveryDay => day.value.locale('de').weekday() === deliveryDay.day
    )
  }

  useEffect(() => {
    const mappedDays = []
    for (let i = 0; i < numberOfDays; i++) {
      mappedDays.push({
        title: moment()
          .locale('de')
          .hour(12)
          .minute(0)
          .second(0)
          .add(i, 'days')
          .calendar(null, calendarOptions),
        value: moment().locale('de').add(i, 'days')
      })
    }
    if (value && value.isAfter(mappedDays[mappedDays.length - 1].value)) {
      setNumberOfDays(d => d + 7)
      return
    }
    const deliveryDays = mappedDays.filter(day => isDeliveryDay(day))
    // Timeout to wait till animation has finished
    setTimeout(() => {
      setDays(mappedDays)
      if (mappedDeliveryDays) {
        // Get the first deliveryDay that is not past its deadline
        // if the first one is today, only use if default_delivery_today is set,
        // else we are using the next one as default
        const nextDeliveryDay = deliveryDays.find(d => {
          if (!isDeliveryDay(d)) return false
          if (isPastOrderDeadline(d)) return false

          // is today, only return true if default_delivery_today is set
          if (d.value.isSame(moment(), 'day')) {
            return merchantSupplier?.default_delivery_today
          }
          // If none of the above returned, we have a deliveryDay that will work
          return true
        })

        if (
          nextDeliveryDay &&
          nextDeliveryDay.value &&
          (!value ||
            !isDeliveryDay({ value }) ||
            // If our value is not the next delivery-day, we jump to the next one
            !nextDeliveryDay.value.isSame(value, 'day'))
        ) {
          onDataChanged(nextDeliveryDay.value, false)
        }
      } else if (merchantSupplier?.default_delivery_today) {
        // Set the Preferred Delivery - Date to Today
        onDataChanged(moment().hour(12).minute(0).second(0), false)
      }
    }, 700)
  }, [mappedDeliveryDays, numberOfDays])

  const onPress = useCallback(item => {
    if (item.showMore) {
      setNumberOfDays(d => d + 7)
      return
    }
    if (isDeliveryDay(item)) {
      onDataChanged(item.value)
      onDismiss()
    }
  }, [])

  if (!days) return null
  const items: ListItemInterface<{ title: string; value: Moment }>[] = days.map(
    day => ({
      item: day,
      disabled: !isDeliveryDay(day),
      bold: isDeliveryDay(day) && !isPastOrderDeadline(day),
      active: day.value.isSame(value, 'day'),
      afterTitleElement: isPastOrderDeadline(day) && (
        <Text
          style={
            day.value.isSame(value, 'day')
              ? Styles.AlertActive
              : Styles.AlertInactive
          }>
          ⚠
        </Text>
      ),
      activeSubtitle:
        isPastOrderDeadline(day) &&
        t('dateModal.pastDeadlineMessage', { supplier: supplierName })
    })
  )
  items.push({
    item: {
      title: t('dateModal.showMoreDays'),
      showMore: true
    },
    disabledStyling: true,
    active: false
  })

  return (
    <Modal visible={visible} style={Styles.Modal}>
      <View style={Styles.ModalContent}>
        <ListWithIcon onPress={item => onPress(item)} items={items} />
      </View>
    </Modal>
  )
}
const MemoDateButton = memo(DateButton)
const MemoDateModal = memo(DateModal)
export { MemoDateModal as DateModal, MemoDateButton as DateButton }
