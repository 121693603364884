import { Platform } from 'react-native'
import { DarkTheme, DefaultTheme, Theme } from 'react-native-paper'

export interface CustomTheme extends Theme {
  borderRadius?: number
  dark: boolean
  headerIconSize?: number
  colors: Theme['colors'] & {
    field?: string
    secondary?: string
    textSecondary?: string
    textTertiary?: string
    secondaryButton?: string
    header?: string
    green?: string
    yellow?: string
    overlay?: string
    border?: string
  }
}
export const Light: CustomTheme = {
  ...DefaultTheme,
  headerIconSize: 40,
  colors: {
    ...DefaultTheme.colors,
    primary: '#2F708B',
    secondary: '#CFDBE6',
    accent: '#FE8EA1',
    background: '#FCFCFD',
    surface: '#F9FAFB',
    error: '#B35262',
    text: '#2B343B',
    onSurface: '#2B343B',
    onBackground: '#2B343B',
    disabled: '#B9BBBB',
    placeholder: '#B9BBBB',
    backdrop: '#2B343B',
    field: '#EDEDEE',
    textSecondary: '#5E5F5F',
    textTertiary: '#898A8A',
    secondaryButton: '#f3f3f3',
    header: '#2F708B',
    green: '#5AA167',
    yellow: '#FFCC00',
    overlay: 'rgba(20, 20, 20, 0.4)',
    red: '#C7545B',
    border: '#B9BBBB'
  },
  borderRadius: Platform.select({ android: 4, ios: 8, web: 8 })
}
export const Dark: CustomTheme = {
  ...DarkTheme,
  headerIconSize: 40,
  colors: {
    ...DarkTheme.colors,
    primary: '#A7DBF1',
    secondary: '#374850',
    accent: '#FE8EA1',
    error: '#B35262',
    field: 'rgb(26,26,26)',
    text: '#FCFCFD',
    textSecondary: '#C6C3C3',
    textTertiary: '#9B9797',
    background: 'black',
    surface: '#111517',
    onSurface: '#2B343B',
    onBackground: '#2B343B',
    disabled: '#5E5F5F',
    placeholder: '#5E5F5F',
    backdrop: '#2B343B',
    header: '#FCFCFD',
    secondaryButton: '#3F4040',
    green: '#5AA167',
    yellow: '#FFD60A',
    overlay: 'rgba(20, 20, 20, 0.8)',
    red: '#C7545B',
    border: '#5E5F5F'
  },
  borderRadius: Platform.select({ android: 4, ios: 8, web: 8 })
}
export const Overlay: CustomTheme = {
  ...Light,
  colors: {
    ...Light.colors,
    placeholder: 'rgba(256, 256, 256, 0.7)',
    text: 'white',
    primary: 'rgba(256, 256, 256, 0.7)',
    field: 'rgba(256, 256, 256, 0.3)'
  },
  borderRadius: Platform.select({ android: 4, ios: 8 })
}
