import { useIsFocused } from '@react-navigation/core'
import React from 'react'
import { useSelector } from 'react-redux'
import { useFirebaseConnect } from 'react-redux-firebase'
import useTrueStateAfterTimeout from '../helpers/useTrueStateAfterTimeout'
import { selectUID } from '../redux/selectors'

/**
|--------------------------------------------------
| This file just Subscribes to the Realtime-Updates of Orders
| when the Screen it is used in is focused
| It also starts to Subscribe after 500ms only, because you could run into race-conditions
| if you switch tabs too fast
|--------------------------------------------------
*/

const Subscriber = () => {
  const uid = useSelector(selectUID)

  useFirebaseConnect([
    {
      path: 'orders',
      queryParams: ['orderByChild=merchant_id', `equalTo=${uid}`]
    }
  ])
  return <></>
}
const SubscriberWithTimeout = () => {
  const [show] = useTrueStateAfterTimeout([500])
  if (!show) return null
  return <Subscriber />
}

export const OrderSubscriber = () => {
  const isFocused = useIsFocused()
  if (!isFocused) return null
  return <SubscriberWithTimeout />
}
