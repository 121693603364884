import * as React from 'react'
import { LayoutChangeEvent, Platform, StyleSheet } from 'react-native'
import { Button, useTheme } from 'react-native-paper'
import { CustomTheme } from '@themes/variables'
import { IconSource } from 'react-native-paper/lib/typescript/src/components/Icon'

export interface ButtonProps {
  children?: any
  disabled?: boolean
  icon?: IconSource
  labelStyle?: {}
  loading?: boolean
  mode?: 'text' | 'outlined' | 'contained'
  onPress?: (param: any) => any
  size?: 'M' | 'L' | 'S'
  style?: {}
  theme?: CustomTheme
  type?: 'primary' | 'secondary' | 'tertiary' | 'select'
  testID?: string
  onLayout?: (event: LayoutChangeEvent) => void
}

const CustomButton = ({
  children,
  disabled = false,
  icon,
  labelStyle,
  loading = false,
  mode = 'contained',
  onPress = () => null,
  size = 'M',
  style,
  theme,
  type = 'primary',
  testID,
  onLayout
}: ButtonProps) => {
  const backupTheme: CustomTheme = useTheme()
  const colors = theme ? theme.colors : backupTheme.colors
  const borderRadius = theme ? theme.borderRadius : backupTheme.borderRadius

  const sizeStyle = {
    L: { borderRadius },
    M: { borderRadius },
    S: { borderRadius }
  }[size]

  const sizeLabelStyle = {
    L: {
      fontSize: 18
    },
    M: { fontSize: 15 },
    S: {
      fontSize: 13,
      marginHorizontal: 5,
      marginVertical: 7
    }
  }[size]

  const typeStyle = {
    primary: {
      backgroundColor: disabled ? colors.field : colors.primary,
      borderColor: disabled ? colors.field : colors.primary
    },
    secondary: {
      backgroundColor: disabled ? colors.field : colors.textSecondary,
      borderColor: disabled ? colors.field : colors.textSecondary
    },
    tertiary: {
      backgroundColor: disabled ? colors.field : colors.textSecondary,
      borderColor: disabled ? colors.field : colors.textSecondary
    },
    select: {}
  }[type]

  const typeLabelStyle = {
    primary: {
      color: disabled ? colors.disabled : colors.primary,
      fontWeight: Platform.select({ ios: '600' })
    },
    secondary: {
      color: disabled
        ? colors.disabled
        : Platform.select({ ios: colors.primary, android: colors.text })
    },
    tertiary: {
      color: disabled ? colors.disabled : colors.textSecondary
    },
    select: {
      color: disabled ? colors.disabled : colors.primary
    }
  }[type]

  const platformStyle = Platform.select({
    ios: {
      elevation: 0
    },
    android: {},
    web: {
      flex: 1
    }
  })

  const platformLabelStyle = Platform.select({
    ios: {
      borderRadius
    },
    android: { borderRadius: borderRadius / 2 },
    web: {
      fontSize: 12
    }
  })

  const modeStyle = {
    contained: {},
    outlined: {
      backgroundColor: 'rgba(0,0,0,0)',
      borderWidth: StyleSheet.hairlineWidth
    },
    text: {
      backgroundColor: 'rgba(0,0,0,0)',
      paddingHorizontal: 0,
      borderRadius: 0
    }
  }[mode]

  const modeLabelStyle = {
    contained: {
      color: colors.background,
      fontWeight: '800'
    },
    outlined: {},
    text: {
      backgroundColor: 'rgba(0,0,0,0)',
      marginLeft: 10
    }
  }[mode]

  return (
    <Button
      testID={testID}
      style={[sizeStyle, typeStyle, platformStyle, modeStyle, style]}
      labelStyle={[
        sizeLabelStyle,
        typeLabelStyle,
        platformLabelStyle,
        modeLabelStyle,
        labelStyle
      ]}
      icon={icon}
      uppercase={Platform.select({ ios: false, android: true })}
      mode={mode}
      loading={loading}
      onPress={onPress}
      onLayout={onLayout}
      disabled={disabled}>
      {children}
    </Button>
  )
}
export { CustomButton as Button }
