import { createIconSetFromIcoMoon } from '@expo/vector-icons'
import { useFonts } from 'expo-font'
import React from 'react'
import { StyleSheet, TouchableOpacity } from 'react-native'
import { useTheme, Text } from 'react-native-paper'
import icoMoonConfig from '../../../assets/header/selection.json'
import useIsTablet from '../../helpers/useIsTablet'
import { CustomTheme } from '../../themes/variables'

const icomoonFont = require('../../../assets/header/icomoon.ttf')

const FontIcon = ({
  name,
  onPress,
  text,
  style
}: {
  name: 'phone' | 'scan' | 'add'
  style?: any
  onPress?: () => void
  text: string
}) => {
  const theme: CustomTheme = useTheme()
  const [fontsLoaded] = useFonts({
    icomoon: icomoonFont
  })
  const isTablet = useIsTablet()
  const Styles = React.useMemo(
    () =>
      StyleSheet.create({
        IconContainer: {
          width: isTablet ? theme.headerIconSize * 2 : theme.headerIconSize,
          height: isTablet ? theme.headerIconSize + 20 : theme.headerIconSize,
          alignItems: 'center',
          justifyContent: 'center',
          marginLeft: 10
        },

        TabletDescription: {
          marginTop: -5,
          textAlign: 'center'
        }
      }),
    [theme, isTablet]
  )

  if (!fontsLoaded) return null
  const Icon = createIconSetFromIcoMoon(icoMoonConfig, 'icomoon', 'icomoon.ttf')

  return (
    <TouchableOpacity
      style={[Styles.IconContainer, style]}
      onPress={onPress}
      hitSlop={{
        bottom: 30,
        left: 30,
        right: 30,
        top: 30
      }}>
      <Icon
        name={name}
        color={theme.colors.primary}
        size={theme.headerIconSize}
      />
      {isTablet && <Text style={Styles.TabletDescription}>{text}</Text>}
    </TouchableOpacity>
  )
}
export default FontIcon
