import { selectUnreadCount } from '@redux/selectors'
import * as React from 'react'
import { Platform, StyleSheet, View } from 'react-native'
import { Text } from 'react-native-paper'
import { useSelector } from 'react-redux'
import { Fix } from '@helpers/helpers'

const Styles = StyleSheet.create({
  Container: {
    alignItems: 'center',
    justifyContent: 'center'
  },
  UnreadBubble: {
    position: 'absolute',
    top: Platform.select({
      ios: -2,
      android: -5,
      web: -2
    }),
    right: -12,
    backgroundColor: 'red',
    borderRadius: 8,
    width: 16,
    height: 16,
    justifyContent: 'center',
    alignItems: 'center'
  },
  UnreadText: {
    color: 'white',
    fontSize: Fix(12)
  }
})

interface ChatIconProps {
  children: React.ReactChildren
}

const ChatIcon = ({ children }: ChatIconProps) => {
  const unreadCounter = useSelector(selectUnreadCount)
  return (
    <View style={Styles.Container}>
      {children}
      {unreadCounter > 0 && (
        <View style={Styles.UnreadBubble}>
          <Text style={[Styles.UnreadText]}>{unreadCounter}</Text>
        </View>
      )}
    </View>
  )
}
export { ChatIcon }
