import { Image } from '@components'
import { momentToUnixInt, Scale } from '@helpers/helpers'
import { useNavigation } from '@react-navigation/native'
import { setNewOrder } from '@redux/reducer/order'
import { setCurrentSupplier } from '@redux/reducer/supplier'
import { useScopedDispatch } from '@store'
import { CustomTheme } from '@themes/variables'
import moment from 'moment'
import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { Platform, StyleSheet, Text, View } from 'react-native'
import { TouchableRipple, useTheme } from 'react-native-paper'
import { LocalOrder } from '@hierfoods/interfaces'
import { Icon } from './Icon'
import { OrderListItemPlaceholder } from './OrderListItemPlaceholder'

interface OrderListItemProps {
  disabled?: boolean
  item: LocalOrder
  goToScreen?: string
  screenParams?: any
  orderIsArchived?: boolean
}
const OrderListItem = ({
  disabled,
  item,
  goToScreen = 'OrderDetails',
  screenParams,
  orderIsArchived
}: OrderListItemProps) => {
  const theme: CustomTheme = useTheme()
  const Styles = StyleSheet.create({
    Container: {
      flex: 1,
      flexDirection: 'row',
      paddingHorizontal: Scale(16),
      paddingVertical: Scale(8),
      backgroundColor: theme.colors.background,
      alignItems: 'center'
    },
    Avatar: {
      height: Scale(48),
      width: Scale(48),
      borderRadius: Scale(24)
    },
    AvatarPlaceholder: {
      height: Scale(48),
      width: Scale(48),
      borderRadius: Scale(24),
      marginRight: Scale(12)
    },
    Body: {
      flex: 1,
      marginLeft: Scale(12),
      justifyContent: 'space-evenly'
    },
    Foot: {
      flexDirection: 'row',
      alignItems: 'center',
      paddingTop: Scale(5)
    },
    SupplierName: {
      fontSize: 17,
      color: disabled ? theme.colors.disabled : theme.colors.primary,
      fontWeight: '600'
    },
    Etd: {
      textAlign: 'right',
      fontSize: 12,
      color: '#B9BBBB'
    },
    Teaser: {
      overflow: 'hidden',
      color: '#B9BBBB',
      flex: 1
    },
    TeaserIcon: {
      color: '#B9BBBB',
      marginRight: 5,
      fontSize: 18
    },
    Loader: {
      marginRight: Scale(15)
    },
    SentCheckmark: {
      marginLeft: 5,
      height: Platform.select({ ios: 24, android: 16 })
    },
    ConfirmedCheckmark: {
      height: Platform.select({ ios: 24, android: 16 }),
      position: 'absolute',
      right: -7
    }
  })
  const navigation = useNavigation()
  const scopedDispatch = useScopedDispatch()
  const { t } = useTranslation()
  const calendarOptions = {
    lastDay: `[${t('calendar.yesterday')}], DD. MMMM YYYY`,
    sameDay: `[${t('calendar.today')}], DD. MMMM YYYY`,
    nextDay: `[${t('calendar.tomorrow')}], DD. MMMM YYYY`,
    nextWeek: `DD. MMMM YYYY`,
    lastWeek: `DD. MMMM YYYY`,
    sameElse: 'DD. MMMM YYYY'
  }
  const goToOrderOverview = async () => {
    if (disabled) return

    scopedDispatch(
      setNewOrder({
        ...item,
        preferred_delivery_date: moment(item.preferred_delivery_date, 'x')
      })
    )
    scopedDispatch(setCurrentSupplier(item.supplier_id))
    navigation.navigate(goToScreen, screenParams)
  }

  const orderDisplayType:
    | 'archived'
    | 'estimated'
    | 'sent'
    | 'draft'
    | 'delivered' = (() => {
    if (item.status === 'draft' || !item.status) return 'draft'

    // If we are on the archived screen we can be sure this is archived ;)
    if (orderIsArchived) return 'archived'

    // When the DeliveryDate was before today and the Order is either Sent, Confirmed or Delivered
    if (
      (['sent', 'confirmed', 'delivered'].includes(item.status) &&
        item.preferred_delivery_date <
          momentToUnixInt(moment().hour(0).minute(0).second(0))) ||
      item.status === 'delivered'
    )
      return 'delivered'

    // If the Order was last updated <1 hour ago and not all notifications are sent out yet, show sent
    if (
      item.updated_at < momentToUnixInt(moment().add(1, 'hour')) &&
      !item?.all_notifications_sent &&
      item.status !== 'confirmed'
    )
      return 'sent'
    // else return estimated
    return 'estimated'
  })()

  if (!item?.order_products_string) {
    return (
      <TouchableRipple onPress={goToOrderOverview}>
        <OrderListItemPlaceholder small />
      </TouchableRipple>
    )
  }

  return (
    <TouchableRipple onPress={goToOrderOverview}>
      <View style={Styles.Container}>
        <Image style={Styles.Avatar} fallback={item?.supplier_icon_path} />
        <View style={Styles.Body}>
          <Text
            style={Styles.SupplierName}
            ellipsizeMode="tail"
            numberOfLines={1}
            selectable>
            {`${
              item.order_number
                ? `#${item.order_number}: `
                : `${moment(item.updated_at).format('DD/MM/YY')} `
            }${item.order_products_string}`}
          </Text>
          <View style={Styles.Foot}>
            <Icon
              name="account-outline"
              iconSet="MaterialCommunityIcons"
              style={Styles.TeaserIcon}
            />
            <Text
              style={Styles.Teaser}
              ellipsizeMode="tail"
              numberOfLines={1}
              selectable>
              {`${item?.supplier_name} ${
                disabled ? t('supplier.isLocked') : ''
              }`}
            </Text>
          </View>

          <View style={Styles.Foot}>
            {orderDisplayType === 'estimated' && (
              <>
                <Icon
                  name="truck-outline"
                  iconSet="MaterialCommunityIcons"
                  style={Styles.TeaserIcon}
                />
                <Text
                  ellipsizeMode="tail"
                  numberOfLines={1}
                  selectable
                  style={Styles.Teaser}>
                  {`${t('orderListItem.estimated')} ${moment(
                    item.preferred_delivery_date,
                    moment(item.preferred_delivery_date).isValid()
                      ? undefined
                      : 'x'
                  ).calendar(null, calendarOptions)}`}
                </Text>
              </>
            )}
            {orderDisplayType === 'delivered' && (
              <>
                <Icon
                  name="truck-check-outline"
                  iconSet="MaterialCommunityIcons"
                  style={Styles.TeaserIcon}
                />
                <Text
                  ellipsizeMode="tail"
                  numberOfLines={1}
                  selectable
                  style={Styles.Teaser}>
                  {t('orderListItem.delivered')}
                </Text>
              </>
            )}
            {orderDisplayType === 'archived' && (
              <>
                <Icon
                  name="truck-check-outline"
                  iconSet="MaterialCommunityIcons"
                  style={Styles.TeaserIcon}
                />
                <Text
                  ellipsizeMode="tail"
                  numberOfLines={1}
                  selectable
                  style={Styles.Teaser}>
                  {t('orderListItem.archived')}
                </Text>
              </>
            )}
            {orderDisplayType === 'sent' && (
              <>
                <Icon
                  name="email-send-outline"
                  iconSet="MaterialCommunityIcons"
                  style={Styles.TeaserIcon}
                />
                <Text
                  ellipsizeMode="tail"
                  numberOfLines={1}
                  selectable
                  style={Styles.Teaser}>
                  {t('orderListItem.sent')}
                </Text>
              </>
            )}
          </View>
        </View>
      </View>
    </TouchableRipple>
  )
}
function areEqual(prevProps, nextProps) {
  return (
    prevProps?.disabled === nextProps?.disabled &&
    prevProps?.item?.order_products_string ===
      nextProps?.item?.order_products_string &&
    prevProps?.item?.order_number === nextProps?.item?.order_number &&
    prevProps?.item?.supplier_name === nextProps?.item?.supplier_name &&
    prevProps?.item?.supplier_icon_path ===
      nextProps?.item?.supplier_icon_path &&
    prevProps?.item?.status === nextProps?.item?.status &&
    prevProps?.item?.all_notifications_sent ===
      nextProps?.item?.all_notifications_sent
  )
}
const MemoOrderListItem = memo(OrderListItem, areEqual)
export { MemoOrderListItem as OrderListItem }
