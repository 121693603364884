import * as React from 'react'
import { Text, StyleSheet } from 'react-native'

const Styles = StyleSheet.create({
  Default: {
    fontWeight: 'bold'
  }
})

interface BoldProps {
  children: React.ReactChildren
  style: any
}

const Bold = ({ style, children }: BoldProps) => (
  <Text style={[Styles.Default, style]}>{children}</Text>
)
export { Bold }
