import { useEffect, useState, useMemo } from 'react'
import { useFirebase } from 'react-redux-firebase'
import { set } from 'lodash'

import { useFirebaseData } from '@helpers/useFirebaseData'
import { getFirebaseData, orderByWithUmlaut, findByProductId } from './helpers'
import { LINKED_PROPERTIES } from './constants'

export function useCompleteProducts(order) {
  const [completeProducts, setCompleteProducts] = useState([])

  const firebase = useFirebase()

  const products = useFirebaseData('order_products', {
    orderByChild: 'order_id',
    equalTo: order?.id,
    waitFor: order
  })

  const supplierProducts = useFirebaseData(`products/${order?.supplier_id}`, {
    waitFor: order
  })

  const productsWithDetails = useMemo(
    () =>
      products && supplierProducts
        ? Object.keys(products).map(key => {
            const product = products[key]
            const productId = product.product_id

            return {
              product: { ...product, id: key },
              productDetails: supplierProducts[productId]
            }
          })
        : [],
    [products, supplierProducts]
  )

  useEffect(() => {
    async function getLinkedProperties() {
      // copy list, modify it and use it to set new state
      const updatedProducts = productsWithDetails.slice()
      for await (const { productDetails, product } of updatedProducts) {
        for await (const { valueKey, idKey, dbKey } of LINKED_PROPERTIES) {
          if (!productDetails?.[valueKey] && product?.[idKey]) {
            let value = productDetails?.[valueKey]
            if (!value) {
              value = await getFirebaseData(
                firebase,
                `/${dbKey}/${productDetails?.[idKey]}/title`
              )
            }
            if (!product.originalData) {
              product.originalData = {}
            }
            if (value) {
              product.originalData[valueKey] = value
            }
          }
        }
      }
      setCompleteProducts(
        orderByWithUmlaut(
          updatedProducts,
          ['product.originalData.productType', 'productDetails.title'],
          ['asc', 'asc']
        )
      )
    }
    getLinkedProperties()
  }, [productsWithDetails, firebase])

  const updateProductAmount = (id, amount) => {
    const updatedProducts = productsWithDetails.slice()
    const indexToUpdate = findByProductId(updatedProducts, id)
    set(updatedProducts[indexToUpdate], 'product.amount', amount)
    setCompleteProducts(updatedProducts)
  }

  const updateProductPrice = (id, price) => {
    const updatedProducts = productsWithDetails.slice()
    const indexToUpdate = findByProductId(updatedProducts, id)
    set(updatedProducts[indexToUpdate], 'product.originalData.bulkPrice', price)
    setCompleteProducts(updatedProducts)
  }

  return [completeProducts, updateProductAmount, updateProductPrice]
}
