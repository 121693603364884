import * as React from 'react'
import { Scale } from '@helpers/helpers'
import { useTranslation } from 'react-i18next'
import { Image, StyleSheet, Text, View } from 'react-native'

const emptyOverviewImage = require('../../assets/empty-overview.png')

const OverviewEmpty = () => {
  const { t } = useTranslation()
  const Styles = StyleSheet.create({
    OrderHint: {
      flex: 1,
      justifyContent: 'flex-end',
      alignItems: 'center'
    },
    OrderHintTitle: {
      color: '#2F708B',
      fontSize: Scale(20),
      fontWeight: 'bold',
      textAlign: 'center',
      width: '50%',
      paddingBottom: Scale(16)
    },
    OrderHintCopy: {
      color: '#898a8a',
      fontSize: Scale(16),
      textAlign: 'center',
      width: '50%',
      paddingBottom: Scale(20),
      lineHeight: Scale(24)
    },
    OrderHintImage: {
      marginBottom: Scale(-70),
      paddingBottom: Scale(64),
      width: '100%',
      height: '50%'
    },
    OrderHintImageImage: {
      resizeMode: 'cover'
    }
  })

  return (
    <View style={Styles.OrderHint}>
      <Text style={Styles.OrderHintTitle}>
        {t('orderOverview.orderHintTitle')}
      </Text>
      <Text style={Styles.OrderHintCopy}>
        {t('orderOverview.orderHintCopy')}
      </Text>
      <Image style={Styles.OrderHintImage} source={emptyOverviewImage} />
    </View>
  )
}
export { OverviewEmpty }
