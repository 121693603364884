import { Icon } from '@components'
import React, { memo } from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
import { Text, useTheme } from 'react-native-paper'
import { Scale } from '@helpers/helpers'
import { CustomTheme } from '@themes/variables'
import { useTranslation } from 'react-i18next'

interface NewCategoryItemProps {
  count: number
  onPress: () => void
}

const NewCategoryItem = ({ count, onPress }: NewCategoryItemProps) => {
  const { t } = useTranslation()
  const theme: CustomTheme = useTheme()
  const Styles = StyleSheet.create({
    Container: {
      paddingHorizontal: Scale(16),
      paddingVertical: 16,
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: theme.colors.green
    },
    Title: {
      fontSize: 16,
      color: 'white',
      flex: 1,
      fontWeight: '600',
      marginLeft: 4
    },
    CountItemContainer: {
      backgroundColor: 'white',
      width: 24,
      height: 24,
      borderRadius: 12,
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: 10
    },
    CountItem: {
      color: theme.colors.green,
      fontWeight: '800',
      fontSize: count > 99 ? 12 : 15
    }
  })

  return (
    <TouchableOpacity style={Styles.Container} onPress={onPress}>
      <Icon
        color="white"
        size={Scale(20)}
        name="bell-circle"
        iconSet="MaterialCommunityIcons"
      />
      <Text style={Styles.Title}>{t('categories.new')}</Text>
      <View style={Styles.CountItemContainer}>
        <Text style={Styles.CountItem}>{count > 99 ? '99+' : count}</Text>
      </View>
      <Icon name="arrow-forward" color="white" size={Scale(18)} />
    </TouchableOpacity>
  )
}
function areEqual(prevProps, nextProps) {
  return prevProps?.count === nextProps?.count
}

const MemoCategoryItem = memo(NewCategoryItem, areEqual)
export { MemoCategoryItem as NewCategoryItem }
