import * as React from 'react'
import { useNavigation } from '@react-navigation/native'
import { Platform } from 'react-native'
import { Button } from '@components'
import { useTranslation } from 'react-i18next'

interface OrderButtonProps {
  chooseSupplierScreen?: 'NewOrderChooseSupplier' | 'ChatNewOrderChooseSupplier'
  onPress?: (param: any) => any
}

const addOrderIcon = require('../../assets/icons/add-order.png')

const OrderButton = ({
  chooseSupplierScreen = 'NewOrderChooseSupplier',
  onPress
}: OrderButtonProps) => {
  const navigation = useNavigation()
  const { t } = useTranslation()
  const goToChooseSupplierScreen = () =>
    navigation.navigate(chooseSupplierScreen)
  const navigateToOrderScreen = onPress || goToChooseSupplierScreen

  return (
    <Button
      mode="text"
      icon={addOrderIcon}
      onPress={navigateToOrderScreen}
      style={{
        marginRight: Platform.select({ ios: -16, android: 0 })
      }}>
      {t('button.order')}
    </Button>
  )
}
export { OrderButton }
