import { Platform, StyleSheet } from 'react-native'
import { Scale } from '@helpers/helpers'

const Styles = StyleSheet.create({
  Container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'stretch',
    maxWidth: Scale(112),
    minWidth: Scale(80),
    maxHeight: Scale(40),
    ...Platform.select({
      web: {
        overflow: 'hidden',
        minWidth: Scale(80),
        justifyContent: 'space-around'
      }
    })
  },
  Input: {
    textAlign: 'center',
    maxWidth: 48,
    fontWeight: '700',
    ...Platform.select({
      web: {
        maxWidth: 36
      }
    })
  }
})

export default Styles
