import { Platform, StyleSheet, Dimensions } from 'react-native'
import { Scale } from '@helpers/helpers'

export default StyleSheet.create({
  Container: { flex: 1 },
  Sum: {
    fontSize: 17,
    fontWeight: '700'
  },
  OrderPanel: {
    borderTopWidth: StyleSheet.hairlineWidth,
    borderTopColor: '#dddddd',
    paddingHorizontal: Scale(16),
    paddingTop: Scale(16)
  },
  OrderInfo: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingBottom: Scale(8)
  },
  OrderUser: {
    marginRight: 'auto',
    fontWeight: '600'
  },
  OrderActions: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: Scale(16)
  },
  Button: {
    flexShrink: 1,
    flex: 1,
    marginHorizontal: 10
  },
  Draft: {
    marginRight: 16
  },

  ButtonGroup: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: Scale(16)
  },
  ButtonRow: { flexDirection: 'row' },
  FullWidth: {
    width: '100%'
  },
  ContentContainerStyle: {
    paddingTop: 10
  },

  HeaderRight: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    ...Platform.select({
      ios: {
        height: '100%',
        alignItems: 'center'
      },
      android: {
        width: Dimensions.get('window').width / 2,
        height: 50,
        marginBottom: 40,
        justifyContent: 'flex-end',
        alignItems: 'center'
      }
    })
  },
  AddButton: {
    marginVertical: 15
  },
  SectionsTitle: {
    padding: 10
  },
  SectionsTitleText: {
    fontSize: 30
  },
  OrderSendingModal: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
    margin: 0,
    padding: 30
  },
  OrderSendingModalTitle: {
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: 24,
    marginBottom: 30,
    textAlign: 'center'
  }
})
