/* eslint-disable react-hooks/exhaustive-deps */
import { useFirebaseData } from '@helpers/useFirebaseData'
import { Merchant } from '@hierfoods/interfaces'
import { ChatMessage } from '@hierfoods/interfaces/misc'
import { StackActions, useNavigation, useRoute } from '@react-navigation/native'
import { CustomTheme } from '@themes/variables'
import moment from 'moment'
import React, { memo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'
import { GiftedChat, InputToolbar } from 'react-native-gifted-chat'
import { useTheme } from 'react-native-paper'
import { useFirebase } from 'react-redux-firebase'
import Styles from './styles'

const OrderDetails = memo(() => {
  const navigation = useNavigation()
  const route = useRoute()
  const chatShortCode = route.params?.code
  const { t } = useTranslation()
  const theme: CustomTheme = useTheme()
  const firebase = useFirebase()
  const [merchantId, setMerchantId] = useState<string | null>(null)
  const [supplierId, setSupplierId] = useState<string | null>(null)
  const [merchant, setMerchant] = useState<Merchant | null>(null)
  const [limit, setLimit] = useState(30)
  const [moreDisabled, setMoreDisabled] = useState(false)

  /* Navigate back to the EnterScreen */
  const goBackToEnter = () => {
    navigation.dispatch(StackActions.replace('enterChatCode'))
  }
  /* No Shortcode - go back */
  if (!chatShortCode) goBackToEnter()

  /* Get all Data for the screen */
  const getData = async () => {
    try {
      const database = firebase.database()
      /* Get Order */
      const merchantSupplierShortcodeSnapshot = await database
        .ref(`merchant_supplier_shortcodes/${chatShortCode}`)
        .once('value')

      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { merchant_id, supplier_id } =
        merchantSupplierShortcodeSnapshot.val()
      const merchantSnapshot = await database
        .ref(`merchants/${merchant_id}`)
        .once('value')
      setMerchantId(merchant_id)
      setMerchant(merchantSnapshot.val())
      setSupplierId(supplier_id)
    } catch (e) {
      console.error(e)
      goBackToEnter()
    }
  }

  useEffect(() => {
    getData()
  }, [])

  const transformMessages = React.useCallback(
    messages =>
      Object.entries(messages)
        .map(([key, value]: [string, ChatMessage]) => ({
          _id: key,
          text: value.message,
          createdAt: moment(value.created_at),
          orderId: value?.order_id,
          revisionOrderId: value?.revision_order_id,
          order_deleted: value?.order_deleted,
          user: {
            _id: value.from === 'supplier' ? supplierId : merchantId
          }
        }))
        .reverse(),
    [merchantId, supplierId]
  )

  const messages = useFirebaseData(
    `messages/${merchantId}/${supplierId}`,
    {
      orderByChild: 'created_at',
      limitToLast: limit,
      waitFor: merchantId && supplierId
    },
    transformMessages
  )

  const onSend = msg => {
    const message = msg[0]
    firebase.push(`messages/${merchantId}/${supplierId}`, {
      created_at: Date.now(),
      message: message.text,
      from: 'supplier'
    })
  }
  /* Set Title when Data is loaded */
  useEffect(() => {
    navigation.setOptions({
      title: merchant
        ? t('chatDetailsForSupplier.title', { name: merchant.name })
        : t('chatDetailsForSupplier.loading')
    })
  }, [merchant])

  const getMore = () => {
    setLimit(limit + 30)
  }
  useEffect(() => {
    setMoreDisabled(messages?.length < limit)
  }, [messages, limit])
  return (
    <View style={Styles.Container}>
      <GiftedChat
        locale="de"
        loadEarlier={!moreDisabled}
        onLoadEarlier={getMore}
        messages={messages}
        onSend={message => onSend(message)}
        renderAvatar={null}
        user={{
          _id: supplierId
        }}
        /* renderCustomView={({ currentMessage }) => {
          if (currentMessage?.orderId)
            return <OrderBubble orderId={currentMessage.orderId} />
          return null
        }} */
        placeholder={
          merchant
            ? t('chatDetailsForSupplier.title', { name: merchant.name })
            : t('chatDetailsForSupplier.loading')
        }
        textInputStyle={[
          Styles.TextInputStyle,
          {
            backgroundColor: theme.colors.field,
            color: theme.colors.text
          }
        ]}
        renderInputToolbar={(props: any) => (
          <InputToolbar
            {...props}
            containerStyle={[
              props.containerStyle,
              { backgroundColor: theme.colors.surface }
            ]}
          />
        )}
      />
    </View>
  )
})

export default OrderDetails
