import i18next from 'i18next'
import moment from 'moment'
import * as Localization from 'expo-localization'
import { initReactI18next } from 'react-i18next'
import de from './locales/de-DE.json'
import en from './locales/en-US.json'
import 'moment/locale/de'

const namespace = 'translations'
const resources = {
  de: { [namespace]: de },
  en: { [namespace]: en }
}
// We only have english and german for now with german as standard
moment.locale(Localization.locale.startsWith('en') ? 'en' : 'de')

i18next.use(initReactI18next).init({
  lng: Localization.locale,
  fallbackLng: 'de',
  defaultNS: namespace,
  load: 'all',
  lowerCaseLng: true,
  interpolation: {
    escapeValue: false
  },
  react: { wait: true },
  debug: false,
  resources
})
export default i18next
