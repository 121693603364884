import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  Container: {
    flex: 1
  },
  TextInputStyle: {
    borderRadius: 20,
    paddingLeft: 8,
    marginHorizontal: 10,
    marginVertical: 5
  }
})
