import { action, on, payload, reducer } from 'ts-action'

export const setCurrentSupplier = action(
  '[SUPPLIER] setCurrentSupplier',
  payload<string>()
)

const initialState: {
  supplier?: string
} = {}

export const supplierReducer = reducer(
  initialState,
  on(setCurrentSupplier, (state, { payload }) => ({
    ...state,
    supplier: payload
  }))
)
