import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  Container: {
    padding: 16,
    flex: 1,
    alignItems: 'center'
  },
  Title: {
    fontSize: 25,
    fontWeight: 'bold'
  },
  Description: {
    fontSize: 18,
    fontWeight: '500',
    marginTop: 50
  },
  Logo: {
    width: 100,
    height: 100,
    borderRadius: 50,
    marginTop: 40
  }
})
