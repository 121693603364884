import { combineReducers, createStore, applyMiddleware } from 'redux'
import { persistReducer, persistStore } from 'redux-persist'
// import ExpoFileSystemStorage from 'redux-persist-expo-filesystem'
import hardSet from 'redux-persist/es/stateReconciler/hardSet'
import { firebaseReducer } from 'react-redux-firebase'
import { screenNameReducer } from '@redux/reducer/navigation'
import { orderReducer } from '@redux/reducer/order'
import { supplierReducer } from '@redux/reducer/supplier'
import { acceptingReducer } from '@redux/reducer/accepting'
import { categoryReducer } from '@redux/reducer/category'
import { feedbackReducer } from '@redux/reducer/feedback'
import { favoritesReducer } from '@redux/reducer/favorites'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import { orderMiddleware } from '@redux/middleware/orderMiddleware'
import { feedbackMiddleware } from '@redux/middleware/feedbackMiddleware'
import { favoritesMiddleware } from '@redux/middleware/favoritesMiddleware'
import { navigationMiddleware } from '@redux/middleware/navigationMiddleware'
import { Platform } from 'react-native'
import Sentry from '@errorhandler'
import createSentryMiddleware from 'redux-sentry-middleware'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { createContext } from 'react'
import {
  createDispatchHook,
  createSelectorHook,
  createStoreHook
} from 'react-redux'
import { orderProductReducer } from '@redux/reducer/orderProducts'
import { orderProductMiddleware } from '@redux/middleware/orderProductMiddleware'

/**
 * This persistor splits up the state into its keys and stringifys each key by itself,
 * which is less heavy for the CPU and should prevent OutOfMemory-Exceptions
 */
const asyncstoragepersistor = {
  ...AsyncStorage,
  getItem: async (key: string) => {
    const value = await AsyncStorage.getItem(key)
    if (value === null) {
      return null
    }
    return JSON.parse(value)
  },
  setItem: async (key: string, value: any) =>
    AsyncStorage.setItem(key, JSON.stringify(value))
}

const rootReducer = combineReducers({
  firebase: persistReducer(
    {
      key: 'firebaseState',
      storage: asyncstoragepersistor, // ExpoFileSystemStorage,
      stateReconciler: hardSet,
      whitelist: ['auth', 'data', 'ordered'],
      throttle: 3000,
      serialize: false
    },
    firebaseReducer
  ),
  navigation: screenNameReducer
})
const scopedReducers = combineReducers({
  order: orderReducer,
  supplier: supplierReducer,
  accepting: acceptingReducer,
  category: categoryReducer,
  feedback: feedbackReducer,
  favorites: favoritesReducer,
  orderProducts: orderProductReducer
})
const composeEnhancers = composeWithDevTools({
  maxAge: 100
})
export const globalStore = createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(
      orderMiddleware,
      feedbackMiddleware,
      favoritesMiddleware,
      navigationMiddleware,
      createSentryMiddleware(Platform.OS === 'web' ? Sentry : Sentry.Native)
    )
  )
)
export const orderOverviewStore = createStore(
  scopedReducers,
  composeEnhancers(
    applyMiddleware(
      orderMiddleware,
      orderProductMiddleware,
      feedbackMiddleware,
      favoritesMiddleware,
      navigationMiddleware,
      createSentryMiddleware(Platform.OS === 'web' ? Sentry : Sentry.Native)
    )
  )
)

export const orderCreateStore = createStore(
  scopedReducers,
  composeEnhancers(
    applyMiddleware(
      orderMiddleware,
      orderProductMiddleware,
      feedbackMiddleware,
      favoritesMiddleware,
      navigationMiddleware,
      createSentryMiddleware(Platform.OS === 'web' ? Sentry : Sentry.Native)
    )
  )
)

export const chatStackStore = createStore(
  scopedReducers,
  composeEnhancers(
    applyMiddleware(
      orderMiddleware,
      orderProductMiddleware,
      feedbackMiddleware,
      favoritesMiddleware,
      navigationMiddleware,
      createSentryMiddleware(Platform.OS === 'web' ? Sentry : Sentry.Native)
    )
  )
)
export const persistor = persistStore(globalStore)
export const ScopedReduxContext = createContext(null)
export const useScopedSelector = createSelectorHook(ScopedReduxContext)
export const useScopedDispatch = createDispatchHook(ScopedReduxContext)
export const useScopedStore = createStoreHook(ScopedReduxContext)
