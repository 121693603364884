/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useCallback } from 'react'
import { useSelector } from 'react-redux'

import { useScopedDispatch, useScopedSelector } from '@store'
import {
  selectAccepting,
  selectAmountForOrderProduct,
  selectSearch,
  selectUID,
  selectSupplierID
} from '@redux/selectors'

import { useFunnel } from '@services/log-service'

import {
  updateOrderProduct,
  orderProductMinusPressed,
  orderProductPlusPressed
} from '../redux/reducer/orderProducts'
import { ProductAmount } from './ProductAmount'

function areEqual(prevProps, nextProps) {
  return (
    prevProps?.editable === nextProps?.editable &&
    prevProps?.productId === nextProps?.productId
  )
}
interface OrderProductAmountProps {
  editable?: boolean
  productId?: string
  supplierProductId?: string
  ean?: string
  autoFocus?: boolean
  isFavorite?: boolean
  screenName: string
}
const OrderProductAmount = memo(
  ({
    editable = false,
    productId,
    supplierProductId,
    ean,
    autoFocus,
    isFavorite,
    screenName
  }: OrderProductAmountProps) => {
    const accepting = useScopedSelector(selectAccepting)
    const supplierId = useScopedSelector(selectSupplierID)
    const uid = useSelector(selectUID)
    const searchString = useScopedSelector(selectSearch)
    const scopedDispatch = useScopedDispatch()
    const funnel = useFunnel()

    const reduxAmount = useScopedSelector(
      selectAmountForOrderProduct(productId)
    )
    const amountAsString = reduxAmount === -1 ? '' : reduxAmount.toString()

    const onChange = useCallback(
      (inputValue: string) => {
        // @ts-ignore
        const changedAmount = parseInt(
          inputValue.match(/\d+/g)?.map(Number),
          10
        )
        scopedDispatch(
          updateOrderProduct({
            amount:
              !Number.isNaN(changedAmount) &&
              changedAmount.toString().length > 0
                ? changedAmount
                : -1,
            id: productId
          })
        )
      },
      [accepting, productId]
    )

    const searchItemExactMatch = searchString
      ? `${supplierProductId || ''}`.toLowerCase() ===
          searchString.toLowerCase().trim() ||
        `${ean || ''}`.toLowerCase() === searchString.toLowerCase().trim()
      : false

    const onDecrement = useCallback(() => {
      funnel.log({
        componentName: 'OrderProductAmount',
        entity: 'ChangeQtyButton',
        action: 'Tap',
        attributes: {
          supplier_id: supplierId,
          merchant_id: uid,
          product_id: productId,
          is_favorite: isFavorite,
          placed_screen_name: screenName
        }
      })
      scopedDispatch(orderProductMinusPressed(productId))
    }, [productId])
    const onIncrement = useCallback(() => {
      funnel.log({
        componentName: 'OrderProductAmount',
        entity: 'ChangeQtyButton',
        action: 'Tap',
        attributes: {
          supplier_id: supplierId,
          merchant_id: uid,
          product_id: productId,
          is_favorite: isFavorite,
          placed_screen_name: screenName
        }
      })
      scopedDispatch(orderProductPlusPressed(productId))
    }, [productId])
    const onEmptyValue = useCallback(
      e => {
        if (e.nativeEvent.text === '')
          scopedDispatch(
            updateOrderProduct({
              amount: 0,
              id: productId
            })
          )
      },
      [accepting, productId]
    )

    return (
      <ProductAmount
        editable={editable}
        amountAsString={amountAsString}
        onDecrement={onDecrement}
        onIncrement={onIncrement}
        onBlur={onEmptyValue}
        onChange={onChange}
        autoFocus={autoFocus && searchItemExactMatch}
      />
    )
  },
  areEqual
)

export { OrderProductAmount }
