/* eslint-disable no-irregular-whitespace */
/* eslint-disable react-hooks/exhaustive-deps */
import { Image, ProductAmount } from '@components'
import { OrderProducts } from '@hierfoods/interfaces'
import logService from '@services/log-service'
import { CustomTheme } from '@themes/variables'
import React, { useCallback, useMemo, useState } from 'react'
import { StyleSheet, TextInput, View } from 'react-native'
import { Text, useTheme } from 'react-native-paper'
import { getPriceForOrderProduct } from '@helpers/helpers'

interface OrderProductProps {
  orderProduct: OrderProducts
  locked: boolean
  // TODO: should product type be used to type the arguments?
  onProductAmountChange: (productId: string | number, amount: number) => void
  onPriceChange: (productId: string | number, price: string) => void
}
const priceRegex = /^\d+(?:\.\d{1,2})?$/

const SupplierOrderProduct = ({
  orderProduct,
  locked = false,
  onProductAmountChange,
  onPriceChange
}: OrderProductProps) => {
  const { productDetails, product } = orderProduct
  const theme: CustomTheme = useTheme()
  const Styles = StyleSheet.create({
    Container: {
      flex: 1,
      flexDirection: 'row',
      padding: 8,
      backgroundColor: theme.colors.background
    },
    Body: {
      flex: 1,
      flexDirection: 'row',
      paddingLeft: 16
    },
    Image: {
      width: 60,
      height: 60,
      borderRadius: 8
    },
    Title: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'baseline',
      paddingTop: 4
    },
    ProductType: {
      color: theme.colors.text,
      fontWeight: '600',
      fontSize: 14
    },
    ProductTitle: {
      color: theme.colors.text,
      paddingLeft: 4,
      fontSize: 14
    },
    Label: {
      color: theme.colors.textTertiary,
      fontSize: 12
    },
    Details: {
      flex: 1,
      flexDirection: 'row',
      height: 40
    },
    PriceInput: {
      textAlign: 'center',
      color: theme.colors.primary,
      fontSize: 16,
      width: 60,
      height: '100%',
      fontWeight: '700',
      alignSelf: 'flex-end',
      borderWidth: 0,
      outlineWidth: 0
    },
    PriceCurrency: {
      position: 'absolute',
      left: 5
    },
    TextInputContainer: {
      backgroundColor: theme.colors.field,
      borderRadius: theme.borderRadius,
      width: 70,
      maxHeight: 40,
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center'
    },
    InputError: {
      borderColor: 'red',
      borderWidth: 2
    },
    InputContainers: {
      alignItems: 'center',
      paddingHorizontal: 10,
      height: '100%'
    },
    TitleContainer: {
      flex: 1
    }
  })

  const initialAmount =
    orderProduct?.initialAmount || orderProduct.product.amount

  const [currentPrice, setCurrentPrice] = useState(
    (getPriceForOrderProduct(orderProduct) / 100).toFixed(2).replace(',', '.')
  )
  const [amount, setAmount] = useState(initialAmount)

  const bulkUnit = product?.originalData?.bulkUnit || ''
  const productType = product?.originalData?.productType

  const updateAmount = useCallback(
    newAmount => {
      if (locked) return
      const amount =
        newAmount === ''
          ? 0
          : parseInt(newAmount.toString().match(/\d+/g)?.map(Number), 10)
      setAmount(amount)
      onProductAmountChange(orderProduct.product.id, amount)

      logService(
        amount === 0 ? 'supplier_remove_from_cart' : 'supplier_change_amount'
      )
    },
    [locked]
  )

  const updatePrice = useCallback(
    priceString => {
      if (locked) return
      setCurrentPrice(priceString)
      onPriceChange(
        orderProduct.product.id,
        Math.round(parseFloat(priceString) * 100).toString()
      )
      logService('supplier_change_price')
    },
    [orderProduct]
  )

  const title = useMemo(
    () => product?.originalData?.name || productDetails?.title,
    [product, productDetails]
  )

  const quantitiesText = useMemo(() => {
    const { weight, volume } = productDetails
    const bulkAmount = productDetails.bulk_amount || ''
    const singleUnit = product?.originalData?.singleUnit || ''

    let quantity = ''

    if (volume) {
      quantity = volume >= 1000 ? `${volume / 1000} l` : `${volume} ml`
    }

    if (weight) {
      quantity = weight >= 1000 ? `${weight / 1000} kg` : `${weight} g`
    }

    if (quantity && bulkAmount) {
      quantity = ` x ${quantity}`
    }

    return `${bulkAmount}${quantity} ${singleUnit} ${
      bulkUnit ? `/ ${bulkUnit}` : ''
    }`
  }, [productDetails])

  return (
    <View style={Styles.Container}>
      <Image
        style={Styles.Image}
        path={productDetails?.image_path_full}
        fallback={productDetails?.image_path}
      />

      <View style={Styles.Body}>
        <View style={Styles.TitleContainer}>
          <View style={Styles.Title}>
            <Text numberOfLines={2} style={Styles.ProductType} selectable>
              {productType}
              <Text style={Styles.ProductTitle} ellipsizeMode="tail" selectable>
                {title}
              </Text>
            </Text>
          </View>
          <Text style={[Styles.Label]}>{quantitiesText}</Text>
          {productDetails?.supplier_product_id && (
            <Text style={[]}>{`# ${productDetails?.supplier_product_id}`}</Text>
          )}
        </View>

        <View style={Styles.InputContainers}>
          <ProductAmount
            amountAsString={orderProduct.product.amount}
            editable={!locked}
            onChange={updateAmount}
            onIncrement={() => {
              updateAmount(amount + 1)
            }}
            onDecrement={() => {
              updateAmount(Math.max(amount - 1, 0))
            }}
          />
          <Text style={[Styles.Label]}>{`x ${bulkUnit}`}</Text>
        </View>

        <View style={Styles.InputContainers}>
          <View style={Styles.TextInputContainer}>
            <Text style={Styles.PriceCurrency}>€</Text>
            <TextInput
              value={currentPrice.replace(',', '.')}
              enablesReturnKeyAutomatically
              step=".01"
              min={0}
              type="text"
              inputMode="decimal"
              autoCapitalize="none"
              autoCompleteType="off"
              editable={!locked}
              autoCorrect={false}
              spellCheck={false}
              onChangeText={updatePrice}
              style={[
                Styles.PriceInput,
                !priceRegex.test(currentPrice.toString().replace(',', '.')) &&
                  Styles.InputError
              ]}
            />
          </View>
          <Text style={[Styles.Label]}>{`pro ${bulkUnit}`}</Text>
        </View>
      </View>
    </View>
  )
}
export { SupplierOrderProduct }
