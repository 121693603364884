import { CustomTheme } from '@themes/variables'
import React from 'react'
import { StyleSheet } from 'react-native'
import { useTheme } from 'react-native-paper'
import {
  Placeholder,
  PlaceholderLine,
  PlaceholderMedia,
  Shine
} from 'rn-placeholder'
import { Scale } from '@helpers/helpers'

const OrderListItemPlaceholder = ({
  small,
  widthMultiplier = 1
}: {
  small?: boolean
  widthMultiplier?: number
  style: any
}) => {
  const theme: CustomTheme = useTheme()
  const Styles = StyleSheet.create({
    PlaceholderContainer: {
      paddingHorizontal: 16,
      paddingVertical: 10,
      justifyContent: 'center',
      alignItems: 'center'
    },
    AvatarPlaceholder: {
      width: 80,
      height: 80,
      borderRadius: 15,
      marginRight: 10,
      backgroundColor: theme.colors.placeholder
    },
    AvatarPlaceholderSmall: {
      height: Scale(48),
      width: Scale(48),
      borderRadius: Scale(24),
      marginRight: Scale(12),
      backgroundColor: theme.colors.placeholder
    },
    Container: {
      flex: 1,
      flexDirection: 'row',

      paddingHorizontal: Scale(16),
      paddingVertical: Scale(8),
      backgroundColor: theme.colors.background
    },
    Line: {
      backgroundColor: theme.colors.placeholder
    }
  })
  if (small)
    return (
      <Placeholder
        testID="order-list-item-placeholder-small"
        style={Styles.Container}
        Animation={Shine}
        Left={props => (
          <PlaceholderMedia
            style={[Styles.AvatarPlaceholderSmall, props.style]}
            isRound
          />
        )}
        Right={() => <PlaceholderLine width={15} style={Styles.Line} />}>
        <PlaceholderLine width={40 * widthMultiplier} style={Styles.Line} />
        <PlaceholderLine width={80 * widthMultiplier} style={Styles.Line} />
      </Placeholder>
    )
  return (
    <Placeholder
      testID="order-list-item-placeholder"
      style={[
        Styles.PlaceholderContainer,
        { backgroundColor: theme.colors.background }
      ]}
      Animation={Shine}
      Left={() => <PlaceholderMedia style={[Styles.AvatarPlaceholder]} />}>
      <PlaceholderLine width={50} height={15} style={Styles.Line} />
      <PlaceholderLine width={40} style={Styles.Line} />
      <PlaceholderLine width={30} noMargin style={Styles.Line} />
    </Placeholder>
  )
}
export { OrderListItemPlaceholder }
