import { useRef, useEffect } from 'react'
import { PixelRatio } from 'react-native'
import moment from 'moment'
import orderBy from 'lodash/orderBy'
import get from 'lodash/get'
import { Many, findIndex } from 'lodash'

import Sentry from '@errorhandler'
import Constants from 'expo-constants'

import { OrderProducts } from '@hierfoods/interfaces'

const Scale = amount => PixelRatio.getFontScale() * amount
const Fix = amount => amount / PixelRatio.getFontScale()
/* MAGIC */
const orderByWithUmlaut = (
  value,
  properties,
  sorting?: Many<boolean | 'asc' | 'desc'>
) =>
  orderBy(
    value,
    properties?.map(
      p => prop =>
        get(prop, p, '')
          .toString()
          .toLowerCase()
          .replace('ä', 'ae')
          .replace('ö', 'ue')
          .replace('ü', 'ue')
    ),
    sorting
  ) || []

const getProductType = (productDetails, productTypes) =>
  productTypes?.[productDetails?.product_type_id]?.title || ''
const getSingleUnit = (productDetails, singleUnits) =>
  singleUnits?.[productDetails?.single_unit_id]?.title || ''
const getBulkUnit = (productDetails, bulkUnits) =>
  bulkUnits?.[productDetails?.bulk_unit_id]?.title || ''
const getVAT = (productDetails, vats) => {
  if (productDetails?.vat_id)
    return vats?.[productDetails?.vat_id]?.value || undefined
  return undefined
}
const getPriceForOrderProduct = op => {
  if (op?.orderProductMeta?.bulk_price) {
    return parseFloat(op?.orderProductMeta?.bulk_price)
  }
  if (op?.product?.originalData?.bulkPrice)
    return parseFloat(op.product.originalData.bulkPrice)
  if (op?.productDetails?.bulk_price)
    return parseFloat(op.productDetails.bulk_price)
  if (op?.product?.price)
    return parseFloat(op.product.price) * op.productDetails.bulk_amount
  if (op?.productDetails?.single_price)
    return (
      parseFloat(op.productDetails.single_price) * op.productDetails.bulk_amount
    )
  return 0
}

const findByProductId = (list: OrderProducts[], id) =>
  findIndex(list, item => item.product.id === id)

const getProductsTotal = products =>
  products.reduce((sum, product) => {
    const productPrice = getPriceForOrderProduct(product)
    return sum + productPrice * product.product.amount
  }, 0)

const usePrevious = <T extends unknown>(value: T): T | undefined => {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}
const mapToArrayWithID = (object: object) => {
  if (!object) return []
  return Object.entries(object).map(([key, value]) => ({ ...value, id: key }))
}
const momentToUnixInt = (m: moment.Moment): number =>
  parseInt(moment(m).format('x'), 10)

const makeTimestampToMoment = (
  t: number | moment.Moment,
  defaultMoment: moment.Moment = moment()
): moment.Moment => {
  try {
    const ifAfterThisIFuckedUp = moment('01.01.2100', 'DD.MM.YYYY')
    const ifBeforeThisIFuckedUp = moment('01.01.2019', 'DD.MM.YYYY')
    const checkMoment = (m: moment.Moment): boolean =>
      m.isValid() &&
      !m.isAfter(ifAfterThisIFuckedUp) &&
      !m.isBefore(ifBeforeThisIFuckedUp)
    let momentifiedTimestamp = moment(t)
    if (checkMoment(momentifiedTimestamp)) return momentifiedTimestamp

    // Moment seems to be invalid, or unix in milliseconds
    momentifiedTimestamp = moment(t, 'X')
    if (checkMoment(momentifiedTimestamp)) return momentifiedTimestamp
    // Moment seems to be invalid, or unix in milliseconds
    momentifiedTimestamp = moment(t, 'x')
    if (checkMoment(momentifiedTimestamp)) return momentifiedTimestamp
    // Jump to Catch
    throw new Error()
  } catch (e) {
    // We majorly fucked up here?? it's neither unix, nor unix in miliseconds? Log error and return today, so UI doesnt crash
    console.log(`Timestamp ${t} is broken`, typeof t)
    if (Sentry?.Native)
      Sentry.Native.captureException(new Error(`Timestamp ${t} is fucked`))
    return defaultMoment
  }
}
const nextTick = (callback: any) => {
  setTimeout(() => callback, 0)
}

const getCloudFunctionsBaseURL = (): string => {
  if (Constants.manifest.extra?.staging) {
    return 'https://us-central1-hierfoods-staging.cloudfunctions.net'
  }
  if (Constants.manifest.extra?.development) {
    return 'https://us-central1-hierfoods-develop.cloudfunctions.net'
  }
  if (Constants.manifest.extra?.release) {
    return 'https://us-central1-hierfoods.cloudfunctions.net'
  }

  return 'http://localhost:5001/hierfoods-develop/us-central1'
}
export {
  orderByWithUmlaut,
  usePrevious,
  Scale,
  Fix,
  getProductType,
  getSingleUnit,
  getBulkUnit,
  getPriceForOrderProduct,
  getVAT,
  mapToArrayWithID,
  momentToUnixInt,
  makeTimestampToMoment,
  nextTick,
  getCloudFunctionsBaseURL,
  findByProductId,
  getProductsTotal
}

export const getFirebaseData = async (
  firebase,
  path,
  orderByChild?,
  equalTo?
) => {
  const getSnapshot = async () => {
    let ref = await firebase.database().ref(path)

    if (orderByChild) {
      ref = ref.orderByChild(orderByChild)
    }

    if (equalTo) {
      ref = ref.equalTo(equalTo)
    }

    return ref.once('value')
  }

  const dataSnapshot = await getSnapshot()
  const data = dataSnapshot.val()
  return data
}
