import React, { memo, useMemo } from 'react'
import { Platform, Pressable, StyleSheet, Text } from 'react-native'
import { useTheme } from 'react-native-paper'

import { Scale } from '@helpers/helpers'
import { CustomTheme } from '@themes/variables'

interface OrderProductAmountButtonProps {
  editable: boolean
  title: string
  side?: 'right' | 'left'
  onPress: () => void
  testID?: string
}

function orderProductAmountButtonIsEqual(prevProps, nextProps) {
  return (
    prevProps?.title === nextProps?.title &&
    prevProps?.side === nextProps?.side &&
    prevProps?.editable === nextProps?.editable &&
    prevProps?.onPress === nextProps?.onPress
  )
}
const OrderProductAmountButton = memo(
  ({
    editable = false,
    title = 'Label',
    onPress = () => null,
    side,
    testID
  }: OrderProductAmountButtonProps) => {
    const theme: CustomTheme = useTheme()

    const Styles = useMemo(
      () =>
        StyleSheet.create({
          Button: {
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            width: Platform.OS === 'web' ? 18 : Scale(28),
            height: '100%',
            zIndex: 1000
          },
          Text: {
            fontSize: Platform.OS === 'web' ? 16 : Scale(20),
            color: theme.colors.textTertiary,
            fontWeight: 'bold'
          }
        }),
      [theme]
    )
    const hitSlop = useMemo(
      () => ({
        top: Scale(16),
        bottom: Scale(16),
        left: side === 'left' ? Scale(16) : 0,
        right: side === 'right' ? Scale(16) : 0
      }),
      [side]
    )
    return !editable ? null : (
      <Pressable
        testID={testID}
        onPress={onPress}
        style={Styles.Button}
        hitSlop={hitSlop}>
        <Text style={Styles.Text}>{title}</Text>
      </Pressable>
    )
  },
  orderProductAmountButtonIsEqual
)

export { OrderProductAmountButton }
