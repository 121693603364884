import { action, on, payload, reducer } from 'ts-action'

export const setAccepting = action(
  '[ACCEPTING] setAccepting',
  payload<boolean>()
)

const initialState: boolean = false

export const acceptingReducer = reducer(
  initialState,
  on(setAccepting, (state, { payload }) => payload)
)
