import React, { useEffect, useRef } from 'react'
import { Platform, TextInput, View, ViewStyle } from 'react-native'
import { useTheme } from 'react-native-paper'

import { OrderProductAmountButton } from '@components'

import { CustomTheme } from '@themes/variables'
import Styles from './styles'

interface ProductAmountProps {
  editable: boolean
  amountAsString: string
  onDecrement: () => void
  onIncrement: () => void
  onBlur: () => void
  onChange: () => void
  autoFocus: () => void
}

const ProductAmount = ({
  editable,
  amountAsString,
  onDecrement,
  onIncrement,
  onBlur,
  onChange,
  autoFocus
}: ProductAmountProps) => {
  const theme: CustomTheme = useTheme()
  const input = useRef()

  const calculateAmountFontSize = (amount: string) => {
    const defaultFontSize = 22
    const fontSizeReduceStep = 2
    return defaultFontSize - fontSizeReduceStep * (amount.length - 1)
  }

  const additionalContainerStyles: ViewStyle = {
    backgroundColor: theme.colors.field,
    borderRadius: theme.borderRadius,
    justifyContent: editable ? 'space-between' : 'center'
  }

  const additionalInputStyles = {
    color: theme.colors.primary,
    fontSize:
      Platform.OS === 'web' ? 16 : calculateAmountFontSize(amountAsString)
  }

  useEffect(() => {
    setTimeout(() => {
      if (autoFocus && input?.current) {
        ;(input.current as any).focus()
      }
    }, 100)
  }, [autoFocus])

  return (
    <View style={[Styles.Container, additionalContainerStyles]}>
      <OrderProductAmountButton
        title="-"
        testID="deccrement-button"
        onPress={onDecrement}
        editable={editable}
        side="left"
      />
      <TextInput
        value={amountAsString}
        disableFullscreenUI={false}
        editable={editable}
        enablesReturnKeyAutomatically
        keyboardType="number-pad"
        onChangeText={onChange}
        style={[Styles.Input, additionalInputStyles]}
        autoCorrect={false}
        contextMenuHidden
        selectTextOnFocus
        onBlur={onBlur}
      />
      <OrderProductAmountButton
        title="+"
        testID="increment-button"
        onPress={onIncrement}
        editable={editable}
        side="right"
      />
    </View>
  )
}

export { ProductAmount }
