/* eslint-disable react-hooks/exhaustive-deps */

import {
  BottomButtons,
  Button,
  DateModal,
  Divider,
  Icon,
  ListItemInterface,
  ListWithIcon,
  OrderListItemPlaceholder,
  OrderProduct,
  OrderSectionHeader
} from '@components'
import { Scale } from '@helpers/helpers'
import useDeviceData from '@helpers/useDeviceData'
import useOrderUsers, { OrderUser } from '@helpers/useOrderUsers'
import { OrderProductDetails } from '@hierfoods/interfaces'
import { setOrderUser } from '@redux/reducer/order'
import {
  selectAllProductsForCurrentSupplierObject,
  selectCartStep,
  selectHideOrderUser,
  selectIsSupplierUsingCrawler,
  selectOrderProductIDs,
  selectOrderStatus,
  selectSupplierID,
  selectUID,
  selectMerchantSupplierDetails
} from '@redux/selectors'
import { useScopedDispatch, useScopedSelector } from '@store'
import { CustomTheme } from '@themes/variables'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Animated,
  Dimensions,
  Keyboard,
  StyleSheet,
  TouchableOpacity,
  View
} from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { FlatList, gestureHandlerRootHOC } from 'react-native-gesture-handler'
import { Text, TextInput, useTheme } from 'react-native-paper'
import { useSelector } from 'react-redux'
import { useFirebase } from 'react-redux-firebase'
import { useFunnel } from '@services/log-service'

interface OrderUserWithCustomConfig extends OrderUser {
  showAll?: boolean
  addNew?: boolean
}
interface CartInterface {
  onAddPress?: () => void
  loading?: boolean
}
const Cart = gestureHandlerRootHOC(({ onAddPress, loading }: CartInterface) => {
  // #region Hooks
  /**
   * Hooks
   */
  const firebase = useFirebase()
  const scopedDispatch = useScopedDispatch()
  const { t } = useTranslation()
  const previousCartStep = useRef()
  const [orderUser, deviceOrderUsers, allOrderUsers] = useOrderUsers()
  const theme: CustomTheme = useTheme()
  const [, deviceDataPath] = useDeviceData()
  const funnel = useFunnel()
  // #endregion

  // #region State
  /**
   * State
   */
  const [userName, setUserName] = useState('')
  const [showAllOrderUsers, setShowAllOrderUsers] = useState(false)
  const computedShowAllOrderUsers =
    showAllOrderUsers || deviceOrderUsers?.length === 0
  // #endregion

  // #region ScopedSelectors
  /**
   * ScopedSelectors
   */
  const supplierId = useScopedSelector(selectSupplierID)
  const orderProductIDs = useScopedSelector(selectOrderProductIDs)
  const cartStep = useScopedSelector(selectCartStep)
  const orderStatus = useScopedSelector(selectOrderStatus)
  // #endregion

  // #region Selectors
  /**
   * Selectors
   */
  const allSupplierProducts = useSelector(
    selectAllProductsForCurrentSupplierObject(supplierId)
  )
  const orderProductsWithTypesSorted = orderProductIDs.map(
    id => allSupplierProducts[id]
  )
  const merchantSupplierDetails = useSelector(
    selectMerchantSupplierDetails(supplierId)
  )
  const isUsingCrawler = useSelector(selectIsSupplierUsingCrawler(supplierId))
  const uid = useSelector(selectUID)
  const hideOrderUser = useSelector(selectHideOrderUser)
  // #endregion

  // #region Values
  /**
   * Values
   */
  const animatedValue = new Animated.Value(0)
  const { width } = Dimensions.get('window')
  const disableUpdate = isUsingCrawler && orderStatus !== 'draft'
  // #endregion

  // #region Styles
  const Styles = StyleSheet.create({
    Container: {
      backgroundColor: theme.colors.background,
      flex: 1
    },
    UpperItemsContainer: {
      flex: 1
    },
    AddButton: {
      marginVertical: 15
    },
    AnimationContainer: {
      flex: 1
    },
    TitleContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      paddingLeft: 10,
      marginTop: 30
    },
    Title: {
      marginLeft: 5,
      paddingTop: 2,
      fontSize: 13,
      letterSpacing: 0.2
    },
    ListContainer: {
      marginTop: 8,
      backgroundColor: theme.colors.surface
    },
    NameInputContainer: {
      flexDirection: 'row',
      width: '100%',
      alignItems: 'center',
      alignContent: 'center',
      paddingLeft: 40,
      paddingRight: 20,
      height: 35,
      marginVertical: 10
    },
    NameInput: {
      height: 35,
      marginBottom: 5,
      marginRight: 10,
      flex: 1
    },
    ScrollViewContainer: {
      paddingBottom: 30
    },
    CustomMessage: {
      padding: 16,
      fontWeight: '500',
      backgroundColor: theme.colors.yellow + 55,
      margin: 16,
      marginBottom: 8,
      borderRadius: 8
    },
    CustomMessageText: {
      fontSize: 14,
      letterSpacing: 0.2,
      color: theme.colors.text,
      fontWeight: '500'
    }
  })
  // #endregion

  // #region Animation stuff
  /**
   * Animation stuff
   */
  const leftTranslate = animatedValue.interpolate({
    inputRange: [0, 1],
    outputRange: [0, -width]
  })
  const rightTranslate = animatedValue.interpolate({
    inputRange: [0, 1],
    outputRange: [width, 0]
  })

  const swipeAnimation = () => {
    if (previousCartStep.current) {
      const nextValue = cartStep === 1 ? 0 : 1
      if (previousCartStep.current !== cartStep) {
        animatedValue.setValue(cartStep === 1 ? 1 : 0)
        Animated.spring(animatedValue, {
          useNativeDriver: true,
          toValue: nextValue
        }).start()
      } else animatedValue.setValue(cartStep === 1 ? 0 : 1)
    }
    previousCartStep.current = cartStep
  }
  useEffect(() => {
    swipeAnimation()
  }, [cartStep, orderUser, deviceDataPath])
  // #endregion

  const setCurrentOrderUser = async (id: string) => {
    if (!deviceOrderUsers?.some(d => d.id === id))
      await firebase
        .database()
        .ref(deviceDataPath)
        .update({
          order_users: [...(deviceOrderUsers.map(d => d.id) || []), id]
        })
    scopedDispatch(setOrderUser(id))
  }

  // #region AddUser
  const addUser = useCallback(async () => {
    if (!userName) {
      Keyboard.dismiss()
      return
    }
    const { key } = await firebase.push(`order_users/${uid}`, {
      title: userName
    })
    await setCurrentOrderUser(key)
    setUserName('')
    setShowAllOrderUsers(false)
  }, [deviceDataPath, uid, userName])
  // #endregion
  const renderItem = useCallback(
    data => {
      const { item } = data
      return (
        <OrderProduct
          productDetails={item as OrderProductDetails}
          locked={!onAddPress || disableUpdate}
          screenName="Cart"
          hideFavorite
          swipeable={false}
        />
      )
    },
    [onAddPress, disableUpdate]
  )

  // #region OrderUsers-Array
  /**
   * The calculated List of available OrderUsers
   */
  const orderUsers: ListItemInterface<OrderUserWithCustomConfig>[] =
    useMemo(() => {
      const usersToMap = computedShowAllOrderUsers
        ? allOrderUsers
        : deviceOrderUsers
      const output: ListItemInterface<OrderUserWithCustomConfig>[] =
        usersToMap.map(d => ({
          item: d,
          active: d.id === orderUser.id
        }))
      if (!computedShowAllOrderUsers)
        output.push({
          item: {
            title: t('orderUser.iAmNotInThere'),
            showAll: true
          },
          disabledStyling: true,
          active: false
        })
      return output
    }, [computedShowAllOrderUsers, orderUser, deviceDataPath])
  // #endregion

  /**
   * OrderUser-onPress-Handler
   */
  const onOrderUserPressed = (item: OrderUserWithCustomConfig) => {
    if (item.showAll) {
      setShowAllOrderUsers(true)
      funnel.log({
        componentName: 'CartCheckout',
        entity: 'ShowAllOrderersButton',
        action: 'Tap',
        attributes: {
          supplier_id: supplierId,
          merchant_id: uid
        }
      })
    } else {
      setCurrentOrderUser(item.id)
      funnel.log({
        componentName: 'CartCheckout',
        entity: 'PickOrdererButton',
        action: 'Tap',
        attributes: {
          supplier_id: supplierId,
          merchant_id: uid
        }
      })
    }
  }
  const handleAddPressed = React.useCallback(() => {
    if (onAddPress) {
      onAddPress()
      funnel.log({
        componentName: 'CartOverview',
        entity: 'AddProductButton',
        action: 'Tap',
        attributes: {
          supplier_id: supplierId,
          merchant_id: uid
        }
      })
    }
  }, [uid, supplierId, onAddPress])
  return (
    <View style={Styles.Container}>
      <View style={Styles.AnimationContainer}>
        {/* Page 1 */}
        <Animated.View
          style={[
            StyleSheet.absoluteFill,
            { transform: [{ translateX: leftTranslate }] }
          ]}>
          <KeyboardAwareScrollView
            bounces={false}
            keyboardShouldPersistTaps="handled">
            {/* We need Touchable to Capture the swipe, weird bug */}
            {merchantSupplierDetails?.custom_message && (
              <View style={Styles.CustomMessage}>
                <Text style={Styles.CustomMessageText}>
                  {merchantSupplierDetails.custom_message}
                </Text>
              </View>
            )}
            <TouchableOpacity activeOpacity={1}>
              <FlatList
                keyboardShouldPersistTaps="handled"
                data={orderProductsWithTypesSorted}
                renderItem={renderItem}
                keyExtractor={(item, index) => `${item?.id}${index}`}
                ListHeaderComponent={() => (
                  <>
                    <OrderSectionHeader
                      title={t('orderCreate.cart')}
                      type="cart"
                    />
                    <Divider />
                    {loading && <OrderListItemPlaceholder />}
                  </>
                )}
                ListFooterComponent={() => (
                  <>
                    <Divider />
                    {onAddPress && !disableUpdate && (
                      <Button
                        mode="text"
                        onPress={handleAddPressed}
                        style={Styles.AddButton}
                        icon={() => (
                          <Icon
                            name="add-circle-outline"
                            size={20}
                            color={theme.colors.primary}
                          />
                        )}>
                        {t('cart.addProducts')}
                      </Button>
                    )}
                  </>
                )}
                ItemSeparatorComponent={() => <Divider inset={Scale(16)} />}
              />
            </TouchableOpacity>
          </KeyboardAwareScrollView>
        </Animated.View>
        {/* Page 2 */}
        <Animated.View
          style={[
            StyleSheet.absoluteFill,
            { transform: [{ translateX: rightTranslate }] }
          ]}>
          <KeyboardAwareScrollView
            extraHeight={40}
            bounces={false}
            keyboardShouldPersistTaps="handled"
            contentContainerStyle={Styles.ScrollViewContainer}>
            <View style={Styles.TitleContainer}>
              <Icon
                name="ios-calendar"
                size={20}
                color={theme.colors.text}
                forcePlatform
              />
              <Text style={Styles.Title}>{t('deliveryDate')}</Text>
            </View>
            <View style={Styles.ListContainer}>
              <DateModal showAsList />
            </View>
            {!hideOrderUser && (
              <>
                <View style={Styles.TitleContainer}>
                  <Icon
                    name="md-person"
                    size={20}
                    color={theme.colors.text}
                    forcePlatform
                  />
                  <Text style={Styles.Title}>{t('orderUser.orderUser')}</Text>
                </View>

                <View style={Styles.ListContainer}>
                  <ListWithIcon<OrderUserWithCustomConfig>
                    onPress={onOrderUserPressed}
                    items={orderUsers}
                    iconName="account-check"
                    iconSet="MaterialCommunityIcons"
                  />
                  {computedShowAllOrderUsers && (
                    <>
                      <Divider inset={40} />
                      <View style={Styles.NameInputContainer}>
                        <TextInput
                          style={Styles.NameInput}
                          mode="outlined"
                          label={t('yourName')}
                          value={userName}
                          testID="order-user-name-input"
                          onChangeText={text => setUserName(text)}
                        />
                        <Button
                          testID="order-user-name-confirm"
                          size="M"
                          onPress={addUser}>
                          {t('addProduct.addButton')}
                        </Button>
                      </View>
                    </>
                  )}
                </View>
              </>
            )}
          </KeyboardAwareScrollView>
        </Animated.View>
      </View>
      <BottomButtons />
    </View>
  )
})
export { Cart }
