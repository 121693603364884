import * as React from 'react'
import { TextInput as IosTextInput, Platform } from 'react-native'
import { TextInput as AndroidTextInput, useTheme } from 'react-native-paper'
import { CustomTheme } from '@themes/variables'

interface CustomTextInputProps {
  style: {}
  theme: CustomTheme
  label: string
  placeholder: string
}

const CustomTextInput = (props: CustomTextInputProps) => {
  const { style, theme, label, placeholder, ...otherProps } = props

  const defaultTheme = useTheme()
  const customTheme: CustomTheme = theme || defaultTheme

  if (Platform.OS === 'ios' || Platform.OS === 'web') {
    const iosStyle = {
      color: customTheme.colors.text,
      backgroundColor: customTheme.colors.field,
      padding: 16,
      borderRadius: customTheme.borderRadius,
      fontSize: 16
    }
    return (
      <IosTextInput
        placeholder={label}
        style={[iosStyle, style]}
        placeholderTextColor={customTheme.colors.placeholder}
        {...otherProps}
      />
    )
  }
  return (
    <AndroidTextInput
      placeholder={placeholder}
      label={label}
      style={[
        {
          backgroundColor: customTheme.colors.field,
          color: customTheme.colors.text
        },
        style
      ]}
      theme={customTheme}
      {...otherProps}
    />
  )
}

export { CustomTextInput as TextInput }
