import * as React from 'react'
import { StyleSheet, View } from 'react-native'
import { Text, useTheme } from 'react-native-paper'
import { CustomTheme } from '@themes/variables'

const Styles = StyleSheet.create({
  Container: {
    height: 42,
    paddingLeft: 16,
    justifyContent: 'center'
  },
  Text: {
    fontSize: 20,
    fontWeight: 'bold'
  }
})

interface OrderOverviewSubtitleProps {
  title: string
  testID?: string
}

export const OrderOverviewSubtitle = ({
  title,
  testID
}: OrderOverviewSubtitleProps) => {
  const { colors }: CustomTheme = useTheme()

  return (
    <View style={[{ backgroundColor: colors.background }, Styles.Container]}>
      <Text testID={testID} style={[Styles.Text, { color: colors.primary }]}>
        {title}
      </Text>
    </View>
  )
}
