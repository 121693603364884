import React from 'react'
import { StyleSheet, View } from 'react-native'
import { Button, ButtonProps } from './Button'

const Styles = StyleSheet.create({
  Container: { padding: 15 }
})
const FloatingBottomButton = (props: ButtonProps) => (
  <View style={[Styles.Container]}>
    <Button {...props} />
  </View>
)

export { FloatingBottomButton }
