import { selectAllOrderUsers, selectOrderUser } from '@redux/selectors'
import { useScopedSelector } from '@store'
import { useSelector } from 'react-redux'
import { mapToArrayWithID } from './helpers'
import useDeviceData from './useDeviceData'

export interface OrderUser {
  title: string
  id?: string
}

export default function useOrderUsers(): [OrderUser, OrderUser[], OrderUser[]] {
  const allOrderUsers = useSelector(selectAllOrderUsers)
  const allOrderUsersMapped = mapToArrayWithID(allOrderUsers)
  const orderUser = useScopedSelector(selectOrderUser)
  const [deviceData] = useDeviceData()
  const currentOrderUser = { ...allOrderUsers?.[orderUser], id: orderUser }
  const deviceOrderUsers = allOrderUsersMapped.filter(ou =>
    deviceData?.order_users?.includes(ou.id)
  )
  return [currentOrderUser, deviceOrderUsers, allOrderUsersMapped]
}
