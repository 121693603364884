import { action, payload, reducer } from 'ts-action'
/**
 * This is just used as an AppWide Action-Dispatcher
 * The Handling is done in the FeedbackMiddleware
 */
export const showError = action(
  '[FEEDBACK] showError',
  payload<{ message: string; params?: any }>()
)

const initialState = {}

export const feedbackReducer = reducer(initialState)
