import logService from '@services/log-service'
import {
  addOrderProduct,
  orderProductMinusPressed,
  orderProductPlusPressed,
  removeOrderProduct,
  setOrderProductAmount,
  updateOrderProduct
} from '../reducer/orderProducts'
import { debouncedAutoDraftOrder, makeOrderEdited } from './orderFunctions'

const onUpdate = (state, dispatch, action) => {
  const { orderProducts } = state

  if (orderProducts[action.payload.id]) {
    if (action.payload.amount > 0) {
      // We have the product, just update the amount
      dispatch(setOrderProductAmount(action.payload))
      return
    }
    // amount is smaller, remove the product
    dispatch(removeOrderProduct(action.payload.id))

    logService(
      'merchant_remove_from_cart',
      ['language', 'view', 'action', 'app_version'],
      {
        supplierID: state.supplier_id,
        productID: action.payload.id
      }
    )
    return
  }
  // If we dont have the product yet and the amount is 0, dont do anything
  if (action.payload.amount < 1) return
  // We dont have the product yet, add it
  dispatch(
    addOrderProduct({
      id: action.payload.id,
      orderProduct: {
        amount: action.payload.amount,
        product_id: action.payload.id
      }
    })
  )

  /* Product is first added */
  logService(
    'merchant_add_to_cart',
    ['language', 'view', 'action', 'app_version'],
    {
      supplierID: state.supplier_id,
      productID: action.payload.id
    }
  )
}
const updatedOrderProductHandler = (state, store, action) => {
  onUpdate(state, store.dispatch, action)
  makeOrderEdited(state, store.dispatch)
  debouncedAutoDraftOrder(store.getState(), store.dispatch, action)
}

const onPlusPressed = (store, action) => {
  const state = store.getState()
  const { orderProducts } = state
  const amount = orderProducts?.[action.payload]?.amount || 0
  updatedOrderProductHandler(state, store, {
    payload: { id: action.payload, amount: amount + 1 }
  })
}

const onMinusPressed = (store, action) => {
  const state = store.getState()
  const { orderProducts } = state
  const amount = orderProducts?.[action.payload]?.amount || 0

  if (amount === 0) return

  updatedOrderProductHandler(state, store, {
    payload: { id: action.payload, amount: Math.max(amount - 1, 0) }
  })
}

const orderProductMiddleware = store => next => action => {
  // Do something in here, when each action is dispatched

  next(action) // Continue the Action
  switch (action.type) {
    case updateOrderProduct.type:
      updatedOrderProductHandler(store.getState(), store, action)
      break
    case orderProductPlusPressed.type:
      onPlusPressed(store, action)
      break
    case orderProductMinusPressed.type:
      onMinusPressed(store, action)
      break
    default:
      break
  }
}

export { orderProductMiddleware }
