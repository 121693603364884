/* eslint-disable react-hooks/exhaustive-deps */
import { Scale } from '@helpers/helpers'
import { useKeyboard } from '@helpers/useKeyboard'
import { useIsFocused } from '@react-navigation/native'
import { setSearchActive, setSearchString } from '@redux/reducer/order'
import {
  selectSearch,
  selectFusedProducts,
  selectSupplierID,
  selectUID
} from '@redux/selectors'
import logService, { useFunnel } from '@services/log-service'
import { useScopedDispatch, useScopedSelector } from '@store'
import { CustomTheme } from '@themes/variables'
import debounce from 'lodash/debounce'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Dimensions,
  FlatList,
  Keyboard,
  Platform,
  StyleSheet,
  TouchableOpacity,
  View
} from 'react-native'
import { Searchbar, Text, useTheme } from 'react-native-paper'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useSelector } from 'react-redux'
import { BarcodeModal } from './BarcodeModal'
import { Button } from './Button'
import { Divider } from './Divider'
import { OrderListItemPlaceholder } from './OrderListItemPlaceholder'
import { OrderProduct } from './OrderProduct'

const { width, height } = Dimensions.get('window')
const scannerHeight = (width / 3) * 4
const itemSeparatorComponent = React.memo(() => <Divider inset={Scale(16)} />)

const Search = React.memo(({ addProduct }: { addProduct: () => void }) => {
  const theme: CustomTheme = useTheme()
  const funnel = useFunnel()
  const Styles = React.useMemo(
    () =>
      StyleSheet.create({
        Searchbar: {
          height: 40,
          zIndex: 0,
          position: 'relative'
        },
        ResultsContainer: {
          backgroundColor: theme.colors.overlay,
          position: 'absolute',
          width: '100%',
          zIndex: 30,
          top: 50,
          bottom: 0,
          flex: 1
        },
        Backdrop: {
          width: '100%',
          flex: 1000
        },
        BarcodeScanner: {
          width: '100%',
          height: '100%',
          position: 'absolute',
          zIndex: -1,
          top: 0,
          bottom: 0
        },
        BarcodeContainer: {
          backgroundColor: 'black',
          padding: 0,
          margin: 0,
          justifyContent: 'flex-start'
        },
        BarcodeHeader: {
          backgroundColor: 'transparent',
          zIndex: 10,
          width: '100%'
        },
        BarcodeDescription: {
          position: 'absolute',
          bottom: '20%',
          textAlign: 'center',
          color: 'white',
          fontSize: 20,
          width: '100%',
          zIndex: 2
        },
        BardcodeScanArea: {
          width: '100%',
          top: (height - scannerHeight) / 2,
          height: scannerHeight,
          borderColor: 'rgba(0,0,0,0.5)',
          alignSelf: 'center',
          borderTopWidth: scannerHeight * 0.45,
          borderBottomWidth: scannerHeight * 0.45,
          borderLeftWidth: width * 0.25,
          borderRightWidth: width * 0.25,
          position: 'absolute',
          zIndex: 1
        },
        ContentContainer: {
          backgroundColor: theme.colors.background
        },
        DoneButtonContainer: {
          paddingHorizontal: 16,
          paddingVertical: 8,
          backgroundColor: theme.colors.background
        }
      }),
    [theme]
  )
  const { t } = useTranslation()
  const isFocused = useIsFocused()
  const scopedDispatch = useScopedDispatch()
  const supplierId = useScopedSelector(selectSupplierID)
  const uid = useSelector(selectUID)
  const searchQuery = useScopedSelector(selectSearch)
  const [localSearchQuery, setLocalSearchQuery] = useState(searchQuery)
  const [searchItems, setSearchItems] = useState([])
  const fusedProducts = useSelector(selectFusedProducts(supplierId))
  const keyboard = useKeyboard()
  const insets = useSafeAreaInsets()

  const searchInProducts = useCallback(
    (searchString: string) => {
      if (!searchString) return
      if (!fusedProducts) return
      const result = fusedProducts.search(searchString)

      if (!result || result.length === 0) return
      const perfectMatch = result
        .slice(0, 5)
        .find(
          r =>
            r.item.productDetails?.supplier_product_id ===
              searchString.toLowerCase() ||
            r.item.productDetails?.ean === searchString.toLowerCase()
        )
      if (perfectMatch) setSearchItems([perfectMatch.item])
      else {
        const mapped = result?.map(i => i.item) ?? []
        setSearchItems(mapped)
        funnel.log({
          componentName: 'SupplierLanding',
          entity: 'SearchBarResultSet',
          action: 'Create',
          attributes: {
            supplier_id: supplierId,
            merchant_id: uid
          }
        })
      }
    },
    [fusedProducts]
  )
  useEffect(() => {
    if (searchQuery !== localSearchQuery) setLocalSearchQuery(searchQuery)
    searchInProducts(searchQuery)
  }, [searchQuery])

  const debouncedLog = useCallback(
    debounce(query => {
      logService('merchant_search_start', undefined, { search_term: query })
    }, 3000),
    []
  )

  const debouncedDispatchSearchQuery = useCallback(
    debounce((query: string) => {
      scopedDispatch(setSearchString(query))
    }, 750),
    [scopedDispatch]
  )

  const onChangeSearch = query => {
    debouncedLog(query)
    setLocalSearchQuery(query)
    debouncedDispatchSearchQuery(query)
    funnel.log({
      componentName: 'SupplierLanding',
      entity: 'SearchBarInput',
      action: 'Tap',
      attributes: {
        supplier_id: supplierId,
        merchant_id: uid
      }
    })
  }

  const closeBarcode = useCallback(
    () => scopedDispatch(setSearchActive(false)),
    [scopedDispatch]
  )

  const closeSearch = useCallback(() => {
    scopedDispatch(setSearchString(''))
    Keyboard.dismiss()
    scopedDispatch(setSearchActive(false))
  }, [scopedDispatch])

  const handleBarCodeScanned = useCallback(
    ({ data }) => {
      scopedDispatch(setSearchString(data))
      logService('merchant_scan_success', undefined, { scan_result: data })
      closeBarcode()
    },
    [closeBarcode, scopedDispatch]
  )
  const handleBarCodeClose = useCallback(() => {
    logService('merchant_scan_cancel')
    closeBarcode()
  }, [closeBarcode])

  const closeAndAddProduct = useCallback(() => {
    closeSearch()
    addProduct()
  }, [addProduct, closeSearch])

  const listEmptyComponent = React.memo(() => {
    const ScopedStyles = React.useMemo(
      () =>
        StyleSheet.create({
          Text: {
            fontSize: 14,
            textAlign: 'center',
            marginBottom: 15
          },
          Container: {
            backgroundColor: theme.colors.background,
            padding: 20
          }
        }),
      []
    )
    return (
      <View style={ScopedStyles.Container}>
        <Text style={ScopedStyles.Text}>{t('orderCreate.searchNotFound')}</Text>
        <Button onPress={closeAndAddProduct} mode="text">
          {t('search.addProduct', { product: searchQuery })}
        </Button>
      </View>
    )
  })
  const renderProduct = useCallback(data => {
    const { item } = data
    return (
      <OrderProduct
        disableZoom
        productDetails={item.productDetails}
        swipeable
        autoFocus
        showEan
        screenName="search"
      />
    )
  }, [])
  const keyExtractor = useCallback(data => `${data?.productDetails?.id}`, [])

  const queryViewStyle = useMemo(() => {
    if (Platform.OS === 'android') return null
    return {
      paddingBottom: keyboard.keyboardShown
        ? keyboard.keyboardHeight - 45 - insets.bottom
        : 0
    }
  }, [insets.bottom, keyboard.keyboardHeight, keyboard.keyboardShown])
  return (
    <>
      <BarcodeModal
        handleBarCodeClose={handleBarCodeClose}
        handleBarCodeScanned={handleBarCodeScanned}
        isFocused={isFocused}
      />

      <Searchbar
        testID="search-input"
        placeholder={t('orderCreate.search')}
        onChangeText={onChangeSearch}
        value={localSearchQuery}
        style={Styles.Searchbar}
        autoCorrect={false}
      />

      {localSearchQuery?.length >= 2 && (
        <View
          style={[Styles.ResultsContainer, queryViewStyle]}
          testID="search-results-container">
          {localSearchQuery !== searchQuery ? (
            <>
              <OrderListItemPlaceholder />
              <OrderListItemPlaceholder />
              <OrderListItemPlaceholder />
            </>
          ) : (
            <>
              <FlatList
                maxToRenderPerBatch={2}
                initialNumToRender={2}
                removeClippedSubviews
                keyboardShouldPersistTaps="always"
                data={searchItems}
                renderItem={renderProduct}
                keyExtractor={keyExtractor}
                ItemSeparatorComponent={itemSeparatorComponent}
                ListEmptyComponent={listEmptyComponent}
                contentContainerStyle={Styles.ContentContainer}
              />
              <TouchableOpacity
                activeOpacity={1}
                onPress={closeSearch}
                style={Styles.Backdrop}
              />
              <View style={Styles.DoneButtonContainer}>
                <Button testID="search-done-button" onPress={closeSearch}>
                  {t('button.done')}
                </Button>
              </View>
            </>
          )}
        </View>
      )}
    </>
  )
})
export { Search }
