/* eslint-disable @typescript-eslint/no-unused-vars */
import { showMessage } from 'react-native-flash-message'
import { showError } from '../reducer/feedback'
import i18n from '../../translation/i18n'

const feedbackMiddleware = store => next => action => {
  // Do something in here, when each action is dispatched
  next(action) // Continue the Action
  switch (action.type) {
    case showError.type:
      showMessage({
        icon: 'auto',
        message: i18n.t(action.payload.message, action.payload.params),
        type: 'danger',
        duration: 6000,
        floating: true
      })
      break

    default:
      break
  }
}

export { feedbackMiddleware }
