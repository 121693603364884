import { action, on, payload, reducer } from 'ts-action'

export const setCategory = action('[CATEGORY] setCategory', payload<string>())

const initialState: {
  category?: string
} | null = {}

export const categoryReducer = reducer(
  initialState,
  on(setCategory, (state, { payload }) => ({
    ...state,
    category: payload
  }))
)
