import * as React from 'react'
import { Text, View, StyleSheet } from 'react-native'
import { Subheading, useTheme } from 'react-native-paper'
import { useTranslation } from 'react-i18next'

import { Moment } from 'moment'

import { CustomTheme } from '@themes/variables'
import { CALENDAR_OPTIONS } from '../helpers/constants'

const Styles = StyleSheet.create({
  TitleText: {
    paddingLeft: 15,
    paddingTop: 15,
    fontSize: 14,
    lineHeight: 16,
    flex: 1
  },
  HeaderContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center'
  },
  DateContainer: {
    borderWidth: 4,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 8,
    marginHorizontal: 16
  },
  Date: {
    fontWeight: 'bold',
    fontSize: 20
  }
})

interface OrderDetailsHeaderProps {
  date: Moment
}

const OrderDetailsHeader = ({ date }: OrderDetailsHeaderProps) => {
  const { t } = useTranslation()
  const theme: CustomTheme = useTheme()

  return (
    <View style={Styles.HeaderContainer}>
      <Subheading style={Styles.TitleText} selectable>
        {t('orderDetailsForSupplier.title')}
      </Subheading>
      {date && (
        <View
          style={[Styles.DateContainer, { borderColor: theme.colors.text }]}>
          <Text style={Styles.Date}>
            {date.calendar(null, CALENDAR_OPTIONS)}
          </Text>
        </View>
      )}
    </View>
  )
}
export { OrderDetailsHeader }
