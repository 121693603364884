import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  Container: {
    flex: 1,
    maxWidth: 800,
    width: '100%',
    overflow: 'hidden',
    alignSelf: 'center'
  },
  ButtonGroup: {
    flexDirection: 'row',
    padding: 16,
    justifyContent: 'space-between'
  },
  ConfirmButton: {
    marginLeft: 20
  },
  MessageBox: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 8,
    paddingHorizontal: 16
  },
  MessageInputContainer: {
    flex: 3,
    flexDirection: 'row',
    height: 32,
    borderWidth: StyleSheet.hairlineWidth,
    borderRadius: 25,
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden'
  },
  MessageInput: {
    flex: 1,
    outlineWidth: 0,
    backgroundColor: 'transparent'
  },
  MessageIcon: {
    fontSize: 20,
    marginLeft: 8
  },
  TitleText: {
    paddingLeft: 15,
    paddingTop: 15,
    fontSize: 14,
    lineHeight: '110%',
    flex: 1
  },
  TitleTextBig: {
    paddingLeft: 15,
    paddingTop: 15,
    fontSize: 16,
    lineHeight: '110%',
    flex: 1
  },
  ModalContainer: {
    position: 'absolute',
    bottom: 0,
    width: '100%'
  },
  SendButton: {
    alignSelf: 'center',
    marginLeft: 16,
    flex: 1,
    minWidth: 150
  },
  Loader: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 10,
    justifyContent: 'center',
    alignItems: 'center'
  },
  HeaderContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center'
  },
  DateContainer: {
    borderWidth: 4,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 8,
    marginHorizontal: 16
  },
  Date: {
    fontWeight: 'bold',
    fontSize: 20
  },
  Price: {
    fontSize: 20,
    fontWeight: 'bold'
  },
  PriceTitle: {
    fontSize: 20
  },
  PriceContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 16,
    paddingVertical: 8
  },
  UpdatesReminder: {
    padding: 20,
    borderWidth: 1,
    borderColor: '#ccc'
  },
  UpdatesReminderText: {
    fontSize: 16
  },
  UpdatesReminderTextBold: {
    fontWeight: 'bold'
  }
})
