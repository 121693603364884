import { selectDeviceDetails, selectUID } from '@redux/selectors'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useFirebaseConnect } from 'react-redux-firebase'
import useDeviceToken from './useDeviceToken'

export default function useDeviceData() {
  const [path, setPath] = useState('devices/')
  const deviceToken = useDeviceToken()
  const uid = useSelector(selectUID)
  const deviceData = useSelector(selectDeviceDetails(deviceToken))
  useFirebaseConnect([path])
  useEffect(() => {
    setPath(`devices/${uid}/${deviceToken}`)
  }, [uid, deviceToken])
  return [deviceData, path]
}
