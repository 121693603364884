import React from 'react'
import { StyleSheet, View } from 'react-native'
import { useTheme } from 'react-native-paper'
import {
  Placeholder,
  PlaceholderLine,
  PlaceholderMedia,
  Shine
} from 'rn-placeholder'
import { Scale } from '../helpers/helpers'

const Styles = StyleSheet.create({
  Container: {
    ...StyleSheet.absoluteFillObject,
    zIndex: 20
  },
  ListItemContainer: {
    paddingHorizontal: 16,
    paddingVertical: 10,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 15
  },
  ListItemSubtitle: {
    marginBottom: 10
  },
  AvatarPlaceholder: {
    width: Scale(64),
    height: Scale(64),
    borderRadius: 15,
    marginRight: 10
  },
  CategoryLine: {
    marginHorizontal: 16,
    marginTop: 75
  }
})

const OrderProductListItemPlaceholder = ({
  width = 50
}: {
  width?: number
}) => (
  <Placeholder
    style={Styles.ListItemContainer}
    Animation={Shine}
    Left={() => <PlaceholderMedia style={Styles.AvatarPlaceholder} />}>
    <PlaceholderLine width={width} height={15} />
    <PlaceholderLine width={30} height={12} style={Styles.ListItemSubtitle} />
    <PlaceholderLine width={20} height={10} noMargin />
  </Placeholder>
)

const OrderCreatePlaceholder = () => {
  const theme = useTheme()

  return (
    <View
      style={[Styles.Container, { backgroundColor: theme.colors.background }]}>
      <Placeholder Animation={Shine}>
        <PlaceholderLine width={40} height={15} style={Styles.CategoryLine} />
      </Placeholder>
      <OrderProductListItemPlaceholder />
      <OrderProductListItemPlaceholder width={60} />
      <OrderProductListItemPlaceholder width={40} />
      <OrderProductListItemPlaceholder />
      <OrderProductListItemPlaceholder width={60} />
      <OrderProductListItemPlaceholder width={40} />
    </View>
  )
}

export default OrderCreatePlaceholder
export { OrderProductListItemPlaceholder }
