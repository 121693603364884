/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react'
import {
  Text,
  FlatList,
  TouchableOpacity,
  StyleSheet,
  View
} from 'react-native'
import { Icon, Divider } from '@components'
import { useTheme } from 'react-native-paper'
import { CustomTheme } from '@themes/variables'

const ItemSeparatorComponent = () => <Divider inset={40} />
export interface ListItemInterface<T> {
  item:
    | T
    | {
        title: string
        [key: string]: any
      }
  disabled?: boolean
  disabledStyling?: boolean
  active?: boolean
  activeSubtitle?: string
  bold?: boolean
  icon?: string
  afterTitleElement?: JSX.Element
  renderFunction?: (item: T) => React.ReactNode
}

interface ListInterface<T> {
  items: ListItemInterface<T>[]
  onPress: (item: any) => void
  iconName?: string
  iconSet?: string
  fontSize?: number
}
const ListWithIcon = <T,>({
  items,
  onPress,
  iconName = 'checkmark',
  iconSet,
  fontSize = 17
}: ListInterface<T>) => {
  const theme: CustomTheme = useTheme()

  const Styles = StyleSheet.create({
    IconContainer: {
      width: 40,
      alignItems: 'center',
      justifyContent: 'center'
    },
    ListItem: {
      fontSize,
      color: theme.colors.text
    },
    DisabledListItem: {
      color: theme.colors.textTertiary
    },
    ListItemContainer: {},
    ListTitleContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      height: 45
    },

    ListItemActive: { fontWeight: 'bold' },
    SubtitleStyle: {
      fontSize: fontSize - 1,
      marginLeft: 40,
      marginBottom: 5,
      marginTop: -5,
      color: theme.colors.textSecondary
    }
  })

  const renderItem = useCallback(
    ({
      item: {
        active,
        item,
        bold,
        disabled,
        disabledStyling,
        afterTitleElement,
        activeSubtitle,
        renderFunction
      }
    }) => (
      <TouchableOpacity
        activeOpacity={disabled ? 1 : undefined}
        onPress={() => !disabled && onPress(item)}
        style={Styles.ListItemContainer}>
        {renderFunction ? (
          renderFunction(item)
        ) : (
          <>
            <View style={Styles.ListTitleContainer}>
              <View style={Styles.IconContainer}>
                {active && (
                  <Icon
                    name={iconName}
                    size={25}
                    iconSet={iconSet}
                    color={theme.colors.text}
                  />
                )}
              </View>
              <Text
                testID={item.title}
                style={[
                  Styles.ListItem,
                  (active || bold) && Styles.ListItemActive,
                  (disabled || disabledStyling) && Styles.DisabledListItem
                ]}>
                {item.title}
              </Text>
              {afterTitleElement && afterTitleElement}
            </View>
            {active && activeSubtitle && (
              <Text style={Styles.SubtitleStyle}>{activeSubtitle}</Text>
            )}
          </>
        )}
      </TouchableOpacity>
    ),
    [onPress, theme, Styles]
  )

  return (
    <FlatList
      data={items}
      bounces={false}
      keyExtractor={(_item, index) => `${index}`}
      ItemSeparatorComponent={ItemSeparatorComponent}
      renderItem={renderItem}
    />
  )
}
export { ListWithIcon }
