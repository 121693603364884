import React, { useState } from 'react'
import { View, StyleSheet } from 'react-native'

import { Moment } from 'moment'
import { MerchantRelationShipForSupplier } from '@hierfoods/interfaces/merchant'

import { DateButton, DateModal } from '@components'

const Styles = StyleSheet.create({
  ModalContainer: {
    position: 'absolute',
    bottom: 0,
    width: '100%'
  }
})

interface OrderDeliverySelectorProps {
  deliveryDate: Moment
  selectDeliveryDate: () => void
  supplierName: string
  merchantSupplier: MerchantRelationShipForSupplier
}

const OrderDeliverySelector = ({
  deliveryDate,
  selectDeliveryDate,
  supplierName, // TODO: being used for error message, we could make it generic
  merchantSupplier // TODO: what we need is only deliveryDays here
}: OrderDeliverySelectorProps) => {
  const [isVisible, setIsVisible] = useState(false)
  return (
    <>
      <DateButton
        preferredDeliveryDate={deliveryDate}
        onPress={() => setIsVisible(true)}
        size="L"
      />
      {isVisible && (
        <View style={Styles.ModalContainer}>
          <DateModal
            supplierName={supplierName}
            visible={isVisible}
            value={deliveryDate}
            merchantSupplier={merchantSupplier}
            onDismiss={() => setIsVisible(false)}
            onDateChange={date => selectDeliveryDate(date)}
          />
        </View>
      )}
    </>
  )
}
export { OrderDeliverySelector }
