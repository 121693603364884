/* eslint-disable no-irregular-whitespace */
/* eslint-disable react-hooks/exhaustive-deps */
import { Bold, Icon, Image, OrderProductAmount } from '@components'
import {
  getBulkUnit,
  getPriceForOrderProduct,
  getSingleUnit,
  getVAT,
  Scale
} from '@helpers/helpers'
import { OrderProductDetails } from '@hierfoods/interfaces'
import { toggleFavorites } from '@redux/reducer/favorites'
import {
  selectAccepting,
  selectBulkUnits,
  selectForceShowProductID,
  selectIsFavoriteProduct,
  selectOrderProductById,
  selectSingleUnits,
  selectSupplierID,
  selectVATs,
  selectUID
} from '@redux/selectors'
import logService, { useFunnel } from '@services/log-service'
import { useScopedDispatch, useScopedSelector } from '@store'
import { CustomTheme } from '@themes/variables'
import React, { memo, useCallback, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Animated, Pressable, StyleSheet, View } from 'react-native'
import { RectButton } from 'react-native-gesture-handler'
import Swipeable from 'react-native-gesture-handler/Swipeable'
import { useTheme, Text } from 'react-native-paper'
import { useSelector } from 'react-redux'
import moment from 'moment'
import * as Localization from 'expo-localization'

import { OrderProductPrice } from './OrderProductPrice'
import { OrderProductListItemPlaceholder } from './OrderCreatePlaceholder'
import { ProductHistoryCalendar } from './ProductHistoryCalendar'

function areEqual(prevProps, nextProps) {
  return (
    prevProps?.productDetails?.bulk_price ===
      nextProps?.productDetails?.bulk_price &&
    prevProps?.productDetails?.id === nextProps?.productDetails?.id &&
    prevProps?.locked === nextProps?.locked &&
    prevProps?.isFavorite === nextProps?.isFavorite &&
    prevProps?.history === nextProps?.history
  )
}
interface OrderProductProps {
  productDetails: OrderProductDetails
  // blockIncrement?: boolean
  locked?: boolean
  isFavorite?: boolean
  // need this prop to since isFavorite can be false depending on hideFavorite
  isFavoriteProduct?: boolean
  autoFocus?: boolean
  disableZoom?: boolean
  showEan?: boolean
  history?: Record<string, number>
  screenName: string
}

const OrderProduct = memo(
  ({
    disableZoom,
    productDetails,
    // blockIncrement = false,
    locked = false,
    isFavorite,
    isFavoriteProduct,
    autoFocus,
    showEan = false,
    history,
    screenName
  }: OrderProductProps) => {
    const isEnglish = Localization.locale.startsWith('en')
    const theme: CustomTheme = useTheme()
    const { t } = useTranslation()
    // #region Styles
    const Styles = StyleSheet.create({
      OuterContainer: {
        alignItems: 'center',
        backgroundColor: theme.colors.background
      },
      Container: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'flex-start',
        padding: Scale(16),
        backgroundColor: theme.colors.background
      },
      Image: {
        width: Scale(64),
        height: Scale(64),
        flexGrow: 0,
        marginRight: Scale(8)
      },
      Body: {
        flex: 1,
        justifyContent: 'space-between',
        flexDirection: 'row'
      },
      Title: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'baseline',
        paddingBottom: Scale(4),
        fontSize: 16,
        color: theme.colors.text
      },
      Favorite: {
        color: theme.colors.yellow
      },
      ProductType: {
        fontSize: 16,
        color: theme.colors.text,
        fontWeight: '700',
        paddingRight: Scale(8)
      },

      Properties: {
        flex: 1,
        justifyContent: 'center',
        paddingRight: Scale(10)
      },
      Property: {
        color: theme.colors.textTertiary,
        fontSize: 12
      },
      PropertyOld: {
        color: theme.colors.textTertiary,
        fontSize: 12,
        textDecorationLine: 'line-through'
      },
      Price: {
        fontWeight: 'bold'
      },
      AmountContainer: {
        alignSelf: 'flex-end',
        width: Scale(80),
        marginBottom: 16,
        flexGrow: 0
      },
      NewAndBackAgainBadge: {
        backgroundColor: theme.colors.green,
        width: '100%',
        marginTop: 10,
        paddingVertical: 5,
        color: 'white',
        borderRadius: theme.borderRadius,
        overflow: 'hidden',
        textAlign: 'center',
        fontSize: 12,
        fontWeight: '700'
      },
      FlexRow: {
        flexDirection: 'row',
        alignItems: 'center'
      },
      BarcodeIcon: {
        fontSize: 15,
        color: theme.colors.textTertiary
      },
      LastOrderDate: {
        flexDirection: 'row',
        alignItems: 'flex-end'
      },
      LastOrderDateText: {
        color: theme.colors.textTertiary,
        marginRight: 4
      },
      LastOrderAmount: {
        backgroundColor: theme.colors.primary,
        borderRadius: 4,
        marginHorizontal: 3
      },
      LastOrderAmountText: {
        color: theme.colors.background
      },
      ExpandedProductDetails: {
        width: '60%',
        flexDirection: 'row',
        justifyContent: 'space-around',
        fontSize: 13,
        marginBottom: 16
      },
      ExpandedProductDetailsText: {
        color: theme.colors.text
      }
    })
    // #endregion
    // #region Hooks
    const acceptingMode = useScopedSelector(selectAccepting)
    const product = useScopedSelector(
      selectOrderProductById(productDetails?.id)
    )
    const bulkUnits = useSelector(selectBulkUnits)
    const vats = useSelector(selectVATs)
    const forceShowProductID = useSelector(selectForceShowProductID)
    const singleUnits = useSelector(selectSingleUnits)
    const singleUnit = useMemo(
      () =>
        product?.originalData?.singleUnit ||
        getSingleUnit(productDetails, singleUnits),
      [productDetails, singleUnits, product]
    )
    const bulkUnit = useMemo(
      () =>
        product?.originalData?.bulkUnit ||
        getBulkUnit(productDetails, bulkUnits),
      [bulkUnits, productDetails, product]
    )
    const vat = useMemo(
      () => product?.originalData?.vat || getVAT(productDetails, vats),
      [vats, productDetails, product]
    )
    const title = useMemo(
      () => product?.originalData?.name || productDetails?.title,
      [product, productDetails]
    )
    const productType = useMemo(
      () => product?.originalData?.productType || productDetails?.product_type,
      [product, productDetails]
    )

    /* The Margin the merchant has for this Product */
    const margin = useMemo(() => {
      if (!productDetails?.selling_price || !vat) return undefined
      const getBulkModifier = () => {
        if (productDetails.bulk_amount) return productDetails.bulk_amount
        if (singleUnit) return 1
        if (productDetails.weight) return productDetails.weight / 1000
        if (productDetails.volume) return productDetails.volume / 1000
        return 1
      }
      const bulkModifier = getBulkModifier()
      const buyingPrice =
        getPriceForOrderProduct({ product, productDetails }) / bulkModifier
      return Math.round(
        100 - (buyingPrice / (productDetails.selling_price / (vat + 1))) * 100
      )
    }, [vat, productDetails, product, singleUnit])

    const [isExpanded, setIsExpanded] = useState(false)

    const lastDate = history
      ? (() => {
          const dates = Object.keys(history)
            .map(ts => parseInt(ts, 10))
            .sort((a, b) => b - a)
            .map(ts => ({
              day: moment(ts)
                .locale(isEnglish ? 'en' : 'de')
                .format('dd DD.MM'),
              date: ts,
              volume: history[ts]
            }))

          const [last, ...otherDates] = dates

          // don’t show history if older than 4 weeks
          if (Date.now() - last.date >= 1000 * 60 * 60 * 24 * 28) {
            return false
          }

          let { volume } = last

          otherDates.forEach(historyItem => {
            if (historyItem.day === last.day) {
              volume += historyItem.volume
            }
          })

          return {
            date: last.date,
            day: last.day,
            volume
          }
        })()
      : undefined

    // #endregion
    // #region Methods
    /*  const updateAmount = useCallback(
      async (amount, accepting) => {
        const opData = {
          ...orderProduct,
          product: {
            ...product,
            amount: Math.max(0, amount),
            originalData: {
              bulkPrice: getPriceForOrderProduct(orderProduct),
              singleUnit,
              bulkUnit,
              productType,
              name: title || '',
              weight: productDetails?.weight || null,
              volume: productDetails?.volume || null
            }
          },
          accepted: accepting,
          initialAmount
        }
        if (locked && !accepting) return
        if ((blockIncrement || accepting) && amount > initialAmount) return
        scopedDispatch(updateOrderProduct(opData))
      },
      [
        locked,
        blockIncrement,
        initialAmount,
        scopedDispatch,
        orderProduct,
        orderProduct?.product??.id,
        singleUnit,
        bulkUnit,
        title,
        productType
      ]
    ) */
    /* Accepted is maybe a weird name, but basically accepted is something like "userTouchedIt"
     * - When he presses the Icon, accepted is set to true
     * - When he changes the Amount, accepted is also true and the Amount differs, so we know that something changed and show the Exclamation-Mark
     * --> When Everything is set to "accepted", the Done Button will be enabled
     */

    const logProduct = () => {
      logService('merchant_zoom', undefined, {
        productID: product.product_id,
        categoryID: productDetails.category_id,
        productType:
          productDetails.product_type_id || productDetails.product_type,
        productPrice:
          getPriceForOrderProduct({ product, productDetails }) /
          productDetails.bulk_amount,
        productBulkUnit:
          productDetails.bulk_unit_id || productDetails.bulk_unit,
        productBulkAmount: productDetails.bulk_amount
      })
    }
    // #endregion
    // #region String-Getters

    const bulkAmountString = useMemo(() => {
      const { bulk_amount: bulkAmount } = productDetails

      if (bulkAmount) return `${bulkAmount} `
      if (!bulkAmount && singleUnit && !bulkUnit) return '1 '
      return ''
    }, [productDetails])

    const singleUnitString = useMemo(() => {
      if (singleUnit) return `${singleUnit} `
      return ''
    }, [productDetails])
    const bulkUnitString = useMemo(() => {
      if (bulkUnit) return `/ ${bulkUnit} `
      return ''
    }, [productDetails])
    const weightOrVolumeString = useMemo(() => {
      const { weight, volume } = productDetails
      const getValue = () => {
        if (weight) {
          if (weight >= 1000) return `${weight / 1000} kg`
          return `${weight} g`
        }
        if (volume) {
          if (volume >= 1000) return `${volume / 1000} l`
          return `${volume} ml`
        }
        return null
      }
      if (getValue()) {
        if (bulkAmountString.length > 0) return `× ${getValue()} `
        return `${getValue()} `
      }
      return ' '
    }, [productDetails])

    const deltaUp = useMemo(
      () =>
        getPriceForOrderProduct({ product, productDetails }) >
        productDetails?.delta,
      [productDetails?.delta]
    )
    const deltaDown = useMemo(
      () =>
        getPriceForOrderProduct({ product, productDetails }) <
        productDetails?.delta,
      [productDetails?.delta]
    )

    // #endregion
    return (
      <View
        testID={`order-product-${
          productDetails?.ean || productDetails?.supplier_product_id
        }`}
        style={Styles.OuterContainer}
        removeClippedSubviews>
        {/* Accepting Checkmark */}
        {/* <OrderProductAccepting productId={product.product_id} /> */}
        <View style={[Styles.Container]}>
          {/* Image */}
          <Image
            disableZoom={disableZoom}
            style={Styles.Image}
            path={productDetails?.image_path_full}
            fallback={productDetails?.image_path}
            onOpen={logProduct}
          />
          {/* Body-Content */}
          <View style={Styles.Body}>
            {/* Text Properties */}
            <View style={Styles.Properties}>
              {/* Title */}
              <Text style={Styles.Title} selectable>
                <Text style={Styles.ProductType} selectable>
                  {productType}
                </Text>
                {` `}
                {title}
                {isFavorite && <Text style={Styles.Favorite}> ★</Text>}
              </Text>
              {/* Subtitle */}
              <Text
                style={Styles.Property}
                numberOfLines={1}
                ellipsizeMode="tail">
                <Bold>{`${bulkAmountString}${weightOrVolumeString}${singleUnitString}${bulkUnitString}`}</Bold>
              </Text>
              {typeof productDetails?.delta === 'number' &&
              productDetails.delta !==
                getPriceForOrderProduct({ product, productDetails }) ? (
                // We have a Delta
                <>
                  {/* EK-New */}
                  <View style={Styles.FlexRow}>
                    <OrderProductPrice
                      price={getPriceForOrderProduct({
                        product,
                        productDetails
                      })}
                      up={deltaUp}
                      down={deltaDown}
                    />
                    {!!productDetails?.bulk_amount && (
                      <OrderProductPrice
                        price={getPriceForOrderProduct({
                          product,
                          productDetails
                        })}
                        bulkAmount={productDetails?.bulk_amount}
                        up={deltaUp}
                        down={deltaDown}
                        prefix=" ("
                        suffix=")"
                        noIcon
                      />
                    )}
                    {/* SuperEdgeCase: IF !bulk_amount && !single_unit && (weight || volume) => Convert Price for 1kg / 1Liter */}
                    {!productDetails?.bulk_amount &&
                      !singleUnit &&
                      !!(productDetails?.weight || productDetails?.volume) && (
                        <OrderProductPrice
                          price={getPriceForOrderProduct({
                            product,
                            productDetails
                          })}
                          bulkAmount={
                            (productDetails?.weight || productDetails.volume) /
                            1000
                          }
                          up={deltaUp}
                          down={deltaDown}
                          prefix=" ("
                          suffix={`/ ${productDetails.weight ? 'kg' : 'l'})`}
                          noIcon
                        />
                      )}
                  </View>
                  {/* EK - Old */}
                  <View style={Styles.FlexRow}>
                    <OrderProductPrice price={productDetails.delta} old />

                    {!!singleUnit && (
                      <OrderProductPrice
                        price={productDetails.delta}
                        bulkAmount={productDetails?.bulk_amount}
                        old
                        prefix=" ("
                        suffix={` / ${singleUnit})`}
                        noIcon
                      />
                    )}
                    {/* SuperEdgeCase: IF !bulk_amount && !single_unit && (weight || volume) => Convert Price for 1kg / 1Liter */}
                    {!productDetails?.bulk_amount &&
                      !singleUnit &&
                      !!(productDetails?.weight || productDetails?.volume) && (
                        <OrderProductPrice
                          price={productDetails.delta}
                          bulkAmount={
                            (productDetails?.weight || productDetails.volume) /
                            1000
                          }
                          old
                          prefix=" ("
                          suffix={`/ ${productDetails.weight ? 'kg' : 'l'})`}
                          noIcon
                        />
                      )}
                  </View>
                </>
              ) : (
                /* EK: We dont have delta, show normal price */
                <View style={Styles.FlexRow}>
                  <OrderProductPrice
                    price={getPriceForOrderProduct({ product, productDetails })}
                  />

                  {!!singleUnit && !!productDetails.bulk_amount && (
                    <OrderProductPrice
                      price={getPriceForOrderProduct({
                        product,
                        productDetails
                      })}
                      bulkAmount={productDetails?.bulk_amount}
                      prefix=" ("
                      suffix={` / ${singleUnit})`}
                    />
                  )}
                  {/* SuperEdgeCase: IF !bulk_amount && !single_unit && (weight || volume) => Convert Price for 1kg / 1Liter */}
                  {!productDetails?.bulk_amount &&
                    !singleUnit &&
                    (productDetails?.weight || productDetails?.volume) && (
                      <OrderProductPrice
                        price={getPriceForOrderProduct({
                          product,
                          productDetails
                        })}
                        bulkAmount={
                          (productDetails?.weight || productDetails.volume) /
                          1000
                        }
                        prefix=" ("
                        suffix={`/ ${productDetails.weight ? 'kg' : 'l'})`}
                        noIcon
                      />
                    )}
                </View>
              )}

              {/* VK */}
              {productDetails?.selling_price && (
                <View style={Styles.FlexRow}>
                  <OrderProductPrice
                    price={productDetails.selling_price}
                    prefix="VK "
                  />
                  {vat && (
                    <Text style={Styles.Property}>{` (${margin}%)`}</Text>
                  )}
                </View>
              )}
              {/* Internal Supplier-Product-ID / EAN */}
              {(showEan || forceShowProductID) &&
                (productDetails?.supplier_product_id ||
                  productDetails?.ean) && (
                  <View style={Styles.FlexRow}>
                    {productDetails?.supplier_product_id && (
                      <Text
                        numberOfLines={1}
                        style={Styles.Property}
                        ellipsizeMode="tail">
                        {`#${productDetails?.supplier_product_id} `}
                      </Text>
                    )}
                    {productDetails?.ean && (
                      <>
                        <Icon
                          name="barcode"
                          iconSet="MaterialCommunityIcons"
                          style={Styles.BarcodeIcon}
                        />
                        <Text
                          numberOfLines={1}
                          style={Styles.Property}
                          ellipsizeMode="tail">
                          {`${productDetails?.ean}`}
                        </Text>
                      </>
                    )}
                  </View>
                )}
            </View>
            {/* Amount Container */}
            <View style={Styles.ActionContainer}>
              <View style={Styles.AmountContainer}>
                <OrderProductAmount
                  supplierProductId={productDetails?.supplier_product_id}
                  ean={productDetails?.ean}
                  productId={productDetails?.id}
                  editable={!(locked && !acceptingMode)}
                  autoFocus={autoFocus}
                  isFavorite={isFavoriteProduct}
                  screenName={screenName}
                />
                {['new', 'back'].includes(productDetails?.delta as string) && (
                  <Text style={Styles.NewAndBackAgainBadge}>
                    {t(`badges.${productDetails.delta}`)}
                  </Text>
                )}
              </View>
              {lastDate && (
                <View style={Styles.FlexRow}>
                  <Pressable
                    onPress={() => {
                      setIsExpanded(!isExpanded)
                    }}
                    hitSlop={{ top: 10, bottom: 30, left: 10, right: 10 }}>
                    <View style={Styles.LastOrderDate}>
                      {isExpanded ? (
                        <Icon
                          name="chevron-up"
                          iconSet="MaterialCommunityIcons"
                          style={Styles.LastOrderDateText}
                          size={16}
                        />
                      ) : (
                        <Icon
                          name="chevron-down"
                          iconSet="MaterialCommunityIcons"
                          style={Styles.LastOrderDateText}
                          size={16}
                        />
                      )}
                      <Icon
                        name="calendar"
                        iconSet="MaterialCommunityIcons"
                        style={Styles.LastOrderDateText}
                        size={16}
                      />
                      <Text style={Styles.LastOrderDateText}>
                        {lastDate.day}
                      </Text>
                      <View style={Styles.LastOrderAmount}>
                        <Text
                          style={
                            Styles.LastOrderAmountText
                          }>{` ${lastDate.volume}x `}</Text>
                      </View>
                    </View>
                  </Pressable>
                </View>
              )}
            </View>
          </View>
        </View>
        {isExpanded && (
          <>
            <ProductHistoryCalendar history={history} />
            <View style={Styles.ExpandedProductDetails}>
              {productDetails?.supplier_product_id && (
                <View>
                  <Text
                    numberOfLines={1}
                    ellipsizeMode="tail"
                    style={Styles.ExpandedProductDetailsText}>
                    #{productDetails.supplier_product_id}
                  </Text>
                </View>
              )}
              {productDetails?.ean && (
                <View style={Styles.FlexRow}>
                  <Icon
                    name="barcode"
                    iconSet="MaterialCommunityIcons"
                    style={Styles.BarcodeIcon}
                  />
                  <Text
                    numberOfLines={1}
                    ellipsizeMode="tail"
                    style={Styles.ExpandedProductDetailsText}>
                    {`${productDetails?.ean}`}
                  </Text>
                </View>
              )}
            </View>
          </>
        )}
      </View>
    )
  },
  areEqual
)

interface OrderProductOuterProps {
  productDetails: OrderProductDetails
  blockIncrement?: boolean
  locked?: boolean
  swipeable?: boolean
  hideFavorite?: boolean
  autoFocus?: boolean
  disableZoom?: boolean
  showEan?: boolean
  history?: Record<string, number>
  screenName: string
}
const OrderProductOuter = ({
  productDetails,
  blockIncrement = false,
  locked = false,
  swipeable,
  hideFavorite,
  autoFocus,
  disableZoom,
  showEan,
  history,
  screenName
}: OrderProductOuterProps) => {
  const swipeableRef = useRef()
  const theme: CustomTheme = useTheme()
  const scopedDispatch = useScopedDispatch()
  const supplierId = useScopedSelector(selectSupplierID)
  const funnel = useFunnel()
  const uid = useSelector(selectUID)
  const isFavorite = useSelector(
    selectIsFavoriteProduct(supplierId, productDetails?.id)
  )
  const Styles = StyleSheet.create({
    FavoriteIcon: {
      fontSize: 50,
      marginLeft: 20,
      transform: [{ rotateX: '180deg' }]
    },
    FavoriteIconContainer: {
      height: '100%',
      width: '100%',
      justifyContent: 'center'
    }
  })

  const renderFavoriteIcon = useCallback(
    (progress, dragX) => {
      const trans = dragX.interpolate({
        inputRange: [-131, -130, 0],
        outputRange: [-1, 0, 0]
      })
      const scale = dragX.interpolate({
        inputRange: [30, 150, 250],
        outputRange: [0.2, 1, 1.2]
      })

      return (
        <RectButton style={[Styles.FavoriteIconContainer]}>
          <Animated.View
            style={{
              transform: [{ translateX: trans }, { scale }]
            }}>
            <Icon
              name={isFavorite ? 'star' : 'star-outline'}
              iconSet="MaterialCommunityIcons"
              color={theme.colors.background}
              style={Styles.FavoriteIcon}
            />
          </Animated.View>
        </RectButton>
      )
    },
    [isFavorite]
  )

  const rowOpened = useCallback(async () => {
    // @ts-ignore
    if (swipeableRef?.current) await swipeableRef.current.close()
    setTimeout(() => {
      scopedDispatch(toggleFavorites(productDetails?.id))
      if (isFavorite) {
        funnel.log({
          componentName: 'OrderProduct',
          entity: 'FavoriteProduct',
          action: 'Delete',
          attributes: {
            supplier_id: supplierId,
            merchant_id: uid
          }
        })
      }
    }, 1)
  }, [productDetails?.id])

  if (!productDetails) return <OrderProductListItemPlaceholder />

  if (!swipeable)
    return (
      <OrderProduct
        productDetails={productDetails}
        isFavorite={isFavorite && !hideFavorite}
        blockIncrement={blockIncrement}
        locked={locked}
        disableZoom={disableZoom}
        autoFocus={autoFocus}
        showEan={showEan}
        history={history}
        screenName={screenName}
      />
    )

  return (
    <Swipeable
      renderRightActions={renderFavoriteIcon}
      onSwipeableRightOpen={rowOpened}
      ref={swipeableRef}
      containerStyle={{ backgroundColor: theme.colors.accent }}>
      <OrderProduct
        productDetails={productDetails}
        isFavoriteProduct={isFavorite}
        isFavorite={isFavorite && !hideFavorite}
        blockIncrement={blockIncrement}
        locked={locked}
        disableZoom={disableZoom}
        autoFocus={autoFocus}
        showEan={showEan}
        history={history}
        screenName={screenName}
      />
    </Swipeable>
  )
}

export { OrderProductOuter as OrderProduct }
