import Constants from 'expo-constants'

const config = {
  dsn: ''
}

if (Constants.manifest.extra?.staging) {
  config.dsn = 'https://05b9187a2d044d07887158270da1ea92@sentry.io/5181784'
}

if (Constants.manifest.extra?.release) {
  config.dsn = 'https://033d3e13f1984e57bd4eeb4ba4f46557@sentry.io/5177884'
}

export default config
