import { useNetInfo, fetch } from '@react-native-community/netinfo'
import React, { useEffect, useState, useRef } from 'react'
import { Banner } from 'react-native-paper'
import { useTranslation } from 'react-i18next'
import Sentry from '@errorhandler'

const NetworkIndicator = () => {
  const timeout = useRef<NodeJS.Timeout>()

  const netInfo = useNetInfo()
  const { t } = useTranslation()
  const [showIndicator, setShowIndicator] = useState(false)

  useEffect(() => {
    Sentry.Native.setContext('network', {
      ...netInfo,
      connectionType: netInfo.type
    })
    if (!netInfo.isInternetReachable) {
      timeout.current = setTimeout(() => {
        setShowIndicator(true)
      }, 4000)
    } else {
      setShowIndicator(false)
      if (timeout.current) {
        clearTimeout(timeout.current)
        timeout.current = null
      }
    }
    return () => {
      clearTimeout(timeout.current)
      timeout.current = null
    }
  }, [netInfo])

  return (
    <Banner
      visible={showIndicator}
      actions={[
        {
          label: t('retry'),
          onPress: () => fetch()
        }
      ]}
      icon="cloud-off-outline">
      {t('networkIndicator')}
    </Banner>
  )
}
export { NetworkIndicator }
