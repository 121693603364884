import axios from 'axios'
import { retryAdapterEnhancer } from 'axios-extensions'
import { updateOrderProduct } from '@redux/reducer/orderProducts'
import { selectSupplierID } from '@redux/selectors'
import { useScopedDispatch, useScopedSelector } from '@store'
import { CreateCustomProduct } from '@hierfoods/interfaces'
import Sentry from '@errorhandler'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFirebase } from 'react-redux-firebase'
import { InputModal } from './InputModal'
import { getCloudFunctionsBaseURL } from '../helpers/helpers'
import { showError } from '../redux/reducer/feedback'

const axiosRetry = axios.create({
  baseURL: getCloudFunctionsBaseURL(),
  headers: { 'Cache-Control': 'no-cache' },
  adapter: retryAdapterEnhancer(axios.defaults.adapter)
})
const AddProduct = ({
  open,
  hide,
  onAdded
}: {
  open?: boolean
  hide?: () => void
  onAdded?: (pid: string) => void
}) => {
  const scopedDispatch = useScopedDispatch()
  const firebase = useFirebase()
  const supplierID = useScopedSelector(selectSupplierID)
  const { t } = useTranslation()
  const [confirmLoading, setConfirmLoading] = useState(false)
  const closePopup = useCallback(() => {
    hide()
  }, [hide])

  const addProduct = useCallback(
    async ({ amount, description }) => {
      try {
        setConfirmLoading(true)
        const newProduct: CreateCustomProduct = {
          supplierID,
          amount,
          description
        }
        const authorization = await firebase.auth().currentUser.getIdToken()
        const { data: newProductID }: { data: string } = await axiosRetry.post(
          'defaults-createCustomProduct',
          newProduct,
          {
            retryTimes: 3,
            headers: {
              authorization,
              Accept: 'application/json',
              'Content-Type': 'application/json'
            }
          }
        )
        if (!newProductID)
          throw new Error('ProductID was not returned by cloudfunction')
        closePopup()
        onAdded(newProductID)
        setTimeout(() => {
          // Timeout so keyboard is closed when animation is shown
          setConfirmLoading(false)
          scopedDispatch(
            updateOrderProduct({
              amount: Math.max(parseInt(amount, 10), 1),
              id: newProductID
            })
          )
        }, 400)
      } catch (e) {
        Sentry.Native.captureException(e)
        setConfirmLoading(false)

        scopedDispatch(showError({ message: 'errors.products.add' }))
      }
    },
    [firebase, supplierID, closePopup, onAdded, scopedDispatch]
  )

  return (
    <InputModal
      visible={open}
      title={t('addProduct.title')}
      description={t('addProduct.description')}
      confirmLoading={confirmLoading}
      onConfirm={addProduct}
      onCancel={closePopup}
      inputs={[
        {
          key: 'description',
          placeholder: t('addProduct.descriptionPlaceholder'),
          validator: (i: string) => i?.length > 0,
          validatorErrorMessage: t('addProduct.missingDescription')
        },
        {
          key: 'amount',
          placeholder: t('addProduct.amountPlaceholder'),
          keyboardType: 'numeric',
          validator: (i: string) => {
            const NumberRegex = /^-?\d*,?\d*$/
            const parsed = parseInt(i, 10)
            return (
              Number.isInteger(parsed) && parsed !== 0 && NumberRegex.test(i)
            )
          },
          validatorErrorMessage: t('addProduct.amountInvalid')
        }
      ]}
    />
  )
}

export { AddProduct }
