/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Price } from '@components'
import useOrderUsers from '@helpers/useOrderUsers'
import { StackActions, useNavigation } from '@react-navigation/native'
import { setAccepting } from '@redux/reducer/accepting'
import {
  deleteOrder,
  resetOrder,
  sendOrder,
  setCartStep
} from '@redux/reducer/order'
import {
  selectAccepting,
  selectAllProductsForCurrentSupplierObject,
  selectCartStep,
  selectCurrentSupplier,
  selectCurrentSupplierLocked,
  selectHideOrderUser,
  selectIsOrderSending,
  selectIsSupplierUsingCrawler,
  selectLocalProductsLength,
  selectOrder,
  selectOrderArchived,
  selectOrderEdited,
  selectPreferredDeliveryDate,
  selectSum,
  selectSupplierID,
  selectUID
} from '@redux/selectors'
import logService, { useFunnel } from '@services/log-service'
import { useScopedDispatch, useScopedSelector } from '@store'
import { CustomTheme } from '@themes/variables'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'
import { useColorScheme } from 'react-native-appearance'
import Modal from 'react-native-modal'
import { ActivityIndicator, Dialog, Text, useTheme } from 'react-native-paper'
import { useSelector } from 'react-redux'
import Styles from '../screens/order-create/styles'
// Save the Timeout of making sendModal visible
let sendModalTimeout = null
const BottomButtons = () => {
  const scopedDispatch = useScopedDispatch()
  const navigation = useNavigation()
  const theme: CustomTheme = useTheme()
  const colorScheme = useColorScheme()
  const { t } = useTranslation()
  const funnel = useFunnel()

  const [showCancelDialog, setShowCancelDialog] = useState(false)
  const [sendModalVisible, setSendModalVisible] = useState(false)
  const [orderUser] = useOrderUsers()

  /* Scoped Selectors */
  const isSendingOrder = useScopedSelector(selectIsOrderSending)
  const order = useScopedSelector(selectOrder)
  const accepting = useScopedSelector(selectAccepting)
  const orderEdited = useScopedSelector(selectOrderEdited)
  const productsLength = useScopedSelector(selectLocalProductsLength)
  const supplierId = useScopedSelector(selectSupplierID)
  const allProducts = useSelector(
    selectAllProductsForCurrentSupplierObject(supplierId)
  )
  const sum = useScopedSelector(selectSum(allProducts))
  const uid = useSelector(selectUID)
  const preferredDeliveryDate = useScopedSelector(selectPreferredDeliveryDate)
  const orderArchived = useScopedSelector(selectOrderArchived)
  const cartStep = useScopedSelector(selectCartStep)

  /* Global Selectors */
  const supplier = useSelector(selectCurrentSupplier(supplierId))
  const isSupplierUsingCrawler = useSelector(
    selectIsSupplierUsingCrawler(supplierId)
  )
  const isSupplierLocked = useSelector(selectCurrentSupplierLocked(supplierId))
  const hideOrderUser = useSelector(selectHideOrderUser)

  const currentStatus = order.status
  const cancelAllowed =
    !orderArchived &&
    currentStatus !== 'rejected' &&
    currentStatus !== 'delivered' &&
    !(isSupplierUsingCrawler && currentStatus !== 'draft')

  const sendAllowed =
    !orderArchived &&
    orderEdited &&
    ['sent', 'confirmed'].includes(currentStatus)
  /* 
  const acceptAllowed =
    !orderArchived &&
    !orderEdited &&
    [OrderStatus.Sent, OrderStatus.Confirmed].includes(currentStatus)
 */
  const dateAllowed = currentStatus !== 'delivered'

  /* Open the "Sending" Modal but with 2 Seconds delay, this is only for slow internetz */
  useEffect(() => {
    if (isSendingOrder) {
      sendModalTimeout = setTimeout(() => {
        setSendModalVisible(true)
      }, 2000)
    } else {
      if (sendModalTimeout) clearTimeout(sendModalTimeout)
      setSendModalVisible(false)
    }
  }, [isSendingOrder])

  const goBackToOverview = async () => {
    navigation.dispatch(StackActions.popToTop())
    setTimeout(() => {
      navigation.navigate('OrderOverviewStack', { screen: 'OrderOverview' })
    }, 1)
  }

  const cancelOrder = async () => {
    logService('merchant_cancel_order', [
      'supplierID',
      'language',
      'app_version',
      'cart_total',
      'count_products_in_cart',
      'minimum_value_reached',
      'preferred_delivery_date',
      'orderID'
    ])
    scopedDispatch(deleteOrder())
    setShowCancelDialog(false)

    goBackToOverview()

    funnel.log({
      componentName: 'OrderNS',
      entity: 'CancelOrder',
      action: 'Tap',
      attributes: {
        supplier_id: supplierId,
        merchant_id: uid
      }
    })
  }
  /*  const acceptPressed = () => {
    scopedDispatch(acceptOrder())
  } */

  const cancelDialogShow = () => {
    setShowCancelDialog(true)
  }
  const cancelDialogHide = () => {
    setShowCancelDialog(false)
  }
  const cancelAccept = () => {
    scopedDispatch(setAccepting(false))
    scopedDispatch(resetOrder())
  }
  /*   const startAccepting = () => {
    scopedDispatch(startAcceptingOrder())
  } */

  const sendPressed = useCallback(() => {
    scopedDispatch(sendOrder('sent'))
    const amount = Number.isFinite(sum) ? sum / 100 : undefined
    funnel.log({
      componentName: 'OrderNS',
      entity: 'SubmitOrder',
      action: 'Tap',
      attributes: {
        supplier_id: supplierId,
        merchant_id: uid,
        revenue: amount,
        currency: 'EUR'
      }
    })
    funnel.log({
      componentName: 'Order',
      entity: 'SendOrder',
      action: 'Success',
      attributes: {
        supplier_id: supplierId,
        merchant_id: uid,
        revenue: amount,
        currency: 'EUR'
      }
    })
  }, [order?.id, order?.order_products, orderUser])

  return (
    <>
      <View style={Styles.OrderPanel}>
        {dateAllowed && (
          <View style={Styles.OrderInfo}>
            {orderUser?.title && currentStatus !== 'draft' && (
              <Text style={Styles.OrderUser}>
                {`${t('orderUser.orderUser')}: ${orderUser.title}`}
              </Text>
            )}
            {!(orderEdited && sum === 0) && (
              <Price amount={sum} style={Styles.Sum} />
            )}
          </View>
        )}

        {currentStatus && currentStatus !== 'delivered' && (
          <View style={Styles.ButtonGroup}>
            {/* Left Button */}
            {cancelAllowed && !accepting && (
              <Button
                onPress={cancelDialogShow}
                mode="contained"
                type="secondary">
                {t('button.cancelOrder')}
              </Button>
            )}
            {/* Cancel Accept Mode -> Reset Order */}
            {accepting && (
              <Button onPress={cancelAccept} mode="contained" type="secondary">
                {t('button.cancelAccepting')}
              </Button>
            )}

            {/* Right Button */}
            {sum < supplier.min_order_value ? (
              <Button disabled style={[Styles.Button]}>
                {`${t('orderCreate.min')} `}
                <Price amount={supplier.min_order_value} />
              </Button>
            ) : (
              <>
                {currentStatus === 'draft' && (
                  <>
                    {/* Go to next Screen */}
                    {cartStep === 1 && (
                      <Button
                        style={Styles.Button}
                        disabled={productsLength === 0}
                        onPress={() => {
                          scopedDispatch(setCartStep(2))
                          funnel.log({
                            componentName: 'OrderNS',
                            entity: 'OpenCartCheckout',
                            action: 'Tap',
                            attributes: {
                              supplier_id: supplierId,
                              merchant_id: uid
                            }
                          })
                        }}
                        testID="accept_cart_step1"
                        mode="contained">
                        {t('button.continue')}
                      </Button>
                    )}
                    {/* Draft -> Can be sent */}
                    {cartStep === 2 && (
                      <Button
                        testID="send-order-button"
                        style={Styles.Button}
                        onPress={sendPressed}
                        mode="contained"
                        loading={isSendingOrder}
                        disabled={
                          (isSendingOrder ||
                            isSupplierLocked ||
                            !orderUser?.id ||
                            !preferredDeliveryDate) &&
                          !hideOrderUser
                        }>
                        {t('button.send')}
                      </Button>
                    )}
                  </>
                )}
                {/* Change Order */}
                {sendAllowed && !accepting && (
                  <Button
                    onPress={sendPressed}
                    mode="contained"
                    disabled={isSupplierLocked}>
                    {t('button.change')}
                  </Button>
                )}
              </>
            )}
            {/*   
         Accept mode is disabled for now
         {/* Accept Order * /}
            {acceptAllowed && !accepting && (
              <Button onPress={startAccepting} mode="contained">
                {t('button.acceptDelivery')}
              </Button>
            )}
            {/* Done accepting * /}
            {acceptAllowed && accepting && (
              <Button
                onPress={acceptPressed}
                mode="contained"
                disabled={!orderProductsArray.every(op => op.accepted)}>
                {t('button.done')}
              </Button>
            )} */}
          </View>
        )}
      </View>

      <Dialog
        theme={{
          ...theme,
          colors: {
            ...theme.colors,
            backdrop: colorScheme === 'dark' ? '#000000bb' : '#00000066'
          }
        }}
        visible={showCancelDialog}
        dismissable={false}
        style={Styles.Dialog}>
        <Dialog.Title style={Styles.DialogTitle}>
          {t('dialog.cancelOrder.title')}
        </Dialog.Title>
        <Dialog.Content>
          <Text style={Styles.DialogContent}>
            {t('dialog.cancelOrder.msg')}
          </Text>
        </Dialog.Content>
        <Dialog.Actions
          style={[
            Styles.BackDialogContainer,
            { borderColor: theme.colors.placeholder }
          ]}>
          <Button
            mode="text"
            type="secondary"
            onPress={cancelDialogHide}
            style={[Styles.BackDialogButtons]}>
            {t('button.cancel')}
          </Button>
          <Button
            mode="text"
            onPress={cancelOrder}
            style={[
              Styles.BackDialogButtons,
              Styles.DialogButtonRight,
              { borderColor: theme.colors.placeholder }
            ]}>
            {t('button.cancelOrder')}
          </Button>
        </Dialog.Actions>
      </Dialog>

      <Modal
        isVisible={sendModalVisible}
        useNativeDriver
        style={[
          Styles.OrderSendingModal,
          { backgroundColor: theme.colors.overlay }
        ]}>
        <Text style={Styles.OrderSendingModalTitle}>
          {t('orderCreate.sendingWaitModal')}
        </Text>
        <ActivityIndicator size="large" />
      </Modal>
    </>
  )
}
export { BottomButtons }
