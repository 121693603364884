import logService from '@services/log-service'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Linking } from 'react-native'
import IconComponent from './IconComponent'

function areEqual(prevProps, nextProps) {
  return prevProps?.phoneNumber === nextProps?.phoneNumber
}
const CallHeaderButton = React.memo(
  ({ phoneNumber, style }: { phoneNumber?: string; style?: any }) => {
    const { t } = useTranslation()

    const onPress = () => {
      Linking.openURL(`tel://${phoneNumber}`)
      logService('merchant_call', [
        'screen',
        'supplierID',
        'language',
        'app_version'
      ])
    }
    if (!phoneNumber) return null
    return (
      <IconComponent
        name="phone"
        text={t('header.call')}
        onPress={onPress}
        style={style}
      />
    )
  },
  areEqual
)
export { CallHeaderButton }
