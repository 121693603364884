/* eslint-disable react-hooks/exhaustive-deps */
// import './wdyr' // For Debugging Rerenders 😠

/* Local Redux */
import { globalStore, persistor } from '@store'
/* App Stuff */
import useForeground from '@helpers/useForeground'
import Navigation from '@navigation'
import { Dark, Light } from '@themes/variables'
import axios from 'axios'
import { cacheAdapterEnhancer } from 'axios-extensions'
import * as ErrorRecovery from 'expo-error-recovery'
import * as Updates from 'expo-updates'
import Constants from 'expo-constants'

/* Firebase */
import registerFirebase from '@services/register-firebase'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/functions'
import 'firebase/storage'
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import * as Analytics from 'expo-firebase-analytics'

/* react / react-native */
import React, { useEffect } from 'react'
import { I18nextProvider, useTranslation } from 'react-i18next'
import { Alert, Platform } from 'react-native'
import { AppearanceProvider, useColorScheme } from 'react-native-appearance'
import FlashMessage from 'react-native-flash-message'
import { Provider as PaperProvider } from 'react-native-paper'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import { enableScreens } from 'react-native-screens'
import { GestureHandlerRootView } from 'react-native-gesture-handler'

/* Redux but not our redux */
import { Provider } from 'react-redux'

/* Other setup stuff */
import i18n from './src/translation/i18n'
import useMaintenanceMode from './src/helpers/useMaintenanceMode'
import Maintenance from './src/screens/maintenance'
import PersistWrapper from './src/components/PersistWrapper'
import './src/services/logbox'

if (process.env.NODE_ENV !== 'production' && Platform.OS !== 'web') {
  try {
    Analytics.setDebugModeEnabled(true)
  } catch (e) {
    console.error(e)
  }
}

const rrfConfig = {
  userProfile: 'merchants'
}

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: globalStore.dispatch
}
global.cachedAxios = axios.create({
  baseURL: '/',
  headers: { 'Cache-Control': 'no-cache' },
  adapter: cacheAdapterEnhancer(axios.defaults.adapter)
})
const App = props => {
  const { t } = useTranslation()
  const isInForeground = useForeground()
  const colorScheme = useColorScheme()
  const theme = colorScheme === 'dark' ? Dark : Light

  const checkForUpdates = async () => {
    if (__DEV__) return
    const { isAvailable } = await Updates.checkForUpdateAsync()
    if (!isAvailable) return
    const { isNew } = await Updates.fetchUpdateAsync()
    if (!isNew) return

    Alert.alert(
      t('updateAlert.title'),
      t('updateAlert.description'),
      [
        {
          text: t('updateAlert.later'),
          style: 'cancel'
        },
        {
          text: t('updateAlert.installNow'),
          onPress: () => Updates.reloadAsync()
        }
      ],
      { cancelable: false }
    )
  }
  if (Platform.OS === 'ios' || Platform.OS === 'android') enableScreens()
  if (firebase.apps?.length < 1) {
    registerFirebase(firebase)

    if (Constants.manifest.extra?.local) {
      firebase.auth().useEmulator('http://localhost:9099')
      firebase.database().useEmulator('localhost', 9000)
      firebase.functions().useEmulator('localhost', 5001)
      console.log('Firebase is pointing at local emulators')
    }
  }
  ErrorRecovery.setRecoveryProps({ purge: true })

  useEffect(() => {
    if (props?.exp?.errorRecovery?.purge && Platform.OS !== 'web') {
      persistor.purge()
    }
  }, [props])
  useEffect(() => {
    if (__DEV__) return
    if (
      isInForeground &&
      Platform.OS !== 'web' &&
      !Constants.manifest.extra?.ci
    )
      checkForUpdates()
  }, [isInForeground])

  const [shouldShowMaintenanceMode, maintenanceConfig] = useMaintenanceMode()
  if (shouldShowMaintenanceMode)
    return <Maintenance config={maintenanceConfig} />
  return (
    <SafeAreaProvider>
      <Provider store={globalStore}>
        <PersistWrapper persistor={persistor}>
          <ReactReduxFirebaseProvider {...rrfProps}>
            <I18nextProvider i18n={i18n}>
              <PaperProvider theme={theme}>
                <GestureHandlerRootView style={{ flex: 1 }}>
                  <Navigation theme={theme} />
                  <FlashMessage position="top" />
                </GestureHandlerRootView>
              </PaperProvider>
            </I18nextProvider>
          </ReactReduxFirebaseProvider>
        </PersistWrapper>
      </Provider>
    </SafeAreaProvider>
  )
}
const AppearanceWrapper = props => (
  <AppearanceProvider>
    <App {...props} />
  </AppearanceProvider>
)
export { persistor }
export default AppearanceWrapper
