/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  DateButton,
  DateModal,
  Divider,
  Icon,
  OrderSectionHeader,
  Price,
  SupplierOrderProduct,
  TextInput
} from '@components'
import { StackActions, useNavigation, useRoute } from '@react-navigation/native'
import { OrderProducts, OrderStatus } from '@hierfoods/interfaces'
import logService, { FunnelProvider } from '@services/log-service'
import { CustomTheme } from '@themes/variables'
import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { SectionList, StyleSheet, View } from 'react-native'
import { showMessage } from 'react-native-flash-message'

import {
  ActivityIndicator,
  Subheading,
  Text,
  useTheme
} from 'react-native-paper'
import { useFirebase } from 'react-redux-firebase'
import { findIndex, set } from 'lodash'
import { useFirebaseData } from '@helpers/useFirebaseData'

import Styles from './styles'
import {
  momentToUnixInt,
  makeTimestampToMoment,
  getFirebaseData,
  getPriceForOrderProduct,
  orderByWithUmlaut,
  mapToArrayWithID
} from '../../helpers/helpers'

const calendarOptions = {
  lastDay: `[GESTERN], DD.MM`,
  sameDay: `[HEUTE], DD.MM`,
  nextDay: `[MORGEN], DD.MM`,
  nextWeek: `dddd, DD.MM`,
  sameElse: 'L'
}

const linkedProperties = [
  {
    valueKey: 'bulkUnit',
    idKey: 'bulk_unit_id',
    dbKey: 'bulk_units'
  },
  {
    valueKey: 'singleUnit',
    idKey: 'single_unit_id',
    dbKey: 'single_units'
  },
  {
    valueKey: 'productType',
    idKey: 'product_type_id',
    dbKey: 'product_types'
  }
]

const findByProductId = (list: OrderProducts[], id) =>
  findIndex(list, item => item.product.id === id)

const OrderDetails = () => {
  const navigation = useNavigation()
  const route = useRoute()

  const orderShortCode = route.params?.code

  const { t } = useTranslation()
  const theme: CustomTheme = useTheme()

  const firebase = useFirebase()

  const goBackToEnterScreen = () => {
    navigation.dispatch(StackActions.replace('enterOrderCode'))
  }
  /* No Shortcode - go back */
  if (!orderShortCode) goBackToEnterScreen()

  const [orderStatus, setOrderStatus] = useState<OrderStatus>()
  const [message, setMessage] = useState('')
  const [dateSelectorVisible, setDateSelectorVisible] = useState(false)
  // "complete" means that we merged the complete product data from
  // order_products which contains a snapshot of some data at the moment of
  // ordering
  // /products/:supplierId which contains current updated product data
  // and from linked properties like product_types, bulk_units etc. that contain
  // meta information stored as lists of titles with ids
  const [completeProducts, setCompleteProducts] = useState([])
  const [preferredDeliveryDate, setPreferredDeliveryDate] = useState(null)
  const [total, setTotal] = useState(0)

  const transformOrder = useCallback(
    orders => mapToArrayWithID(orders)?.[0],
    [orderShortCode]
  )

  const order = useFirebaseData(
    'orders',
    {
      orderByChild: 'short_code',
      equalTo: orderShortCode,
      waitFor: orderShortCode
    },
    transformOrder
  )

  if (order === undefined) goBackToEnterScreen()

  const products = useFirebaseData('order_products', {
    orderByChild: 'order_id',
    equalTo: order?.id,
    waitFor: order
  })

  const supplierProducts = useFirebaseData(`products/${order?.supplier_id}`, {
    waitFor: order
  })

  const productsWithDetails = useMemo(
    () =>
      products && supplierProducts
        ? Object.keys(products).map(key => {
            const product = products[key]
            const productId = product.product_id

            return {
              product: { ...product, id: key },
              productDetails: supplierProducts[productId]
            }
          })
        : [],
    [products, supplierProducts]
  )

  useEffect(() => {
    async function getLinkedProperties() {
      // copy list, modify it and use it to set new state
      const updatedProducts = productsWithDetails.slice()
      for await (const { productDetails, product } of updatedProducts) {
        for await (const { valueKey, idKey, dbKey } of linkedProperties) {
          if (!productDetails?.[valueKey] && product?.[idKey]) {
            let value = productDetails?.[valueKey]
            if (!value) {
              value = await getFirebaseData(
                firebase,
                `/${dbKey}/${productDetails?.[idKey]}/title`
              )
            }
            if (!product.originalData) {
              product.originalData = {}
            }
            if (value) {
              product.originalData[valueKey] = value
            }
          }
        }
      }
      setCompleteProducts(
        orderByWithUmlaut(
          updatedProducts,
          ['product.originalData.productType', 'productDetails.title'],
          ['asc', 'asc']
        )
      )
    }
    getLinkedProperties()
  }, [linkedProperties, productsWithDetails])

  const merchant = useFirebaseData(`merchants/${order?.merchant_id}`, {
    waitFor: order
  })

  const merchantSupplier = useFirebaseData(
    `merchant_suppliers/${order?.merchant_id}/${order?.supplier_id}`,
    { waitFor: order }
  )

  const supplierName = useFirebaseData(`suppliers/${order?.supplier_id}/name`, {
    waitFor: order
  })

  const orderUser = useFirebaseData(
    `order_users/${order?.merchant_id}/${order?.order_user}/title`,
    { waitFor: order && order.order_user }
  )

  useEffect(() => {
    if (order?.preferred_delivery_date) {
      setPreferredDeliveryDate(
        makeTimestampToMoment(order?.preferred_delivery_date)
      )
    }
  }, [order?.preferred_delivery_date])

  useEffect(() => {
    if (order?.status) {
      setOrderStatus(order.status === 'sent' ? 'new' : order.status)
    }
  }, [order?.status])

  const getProductsTotal = products =>
    products.reduce((sum, product) => {
      const productPrice = getPriceForOrderProduct(product)
      return sum + productPrice * product.product.amount
    }, 0)

  useMemo(() => {
    if (completeProducts) {
      const total = getProductsTotal(completeProducts)
      setTotal(total)
    }
  }, [completeProducts])

  /* Set Title when Data is loaded */
  useEffect(() => {
    let title = 'Lädt...'
    if (order && merchant) {
      title = `#${
        order?.order_number || moment(order?.updated_at).format('DD/MM/YY')
      } - ${merchant?.name}`
    }
    if (merchantSupplier?.customerID) {
      title += ` (${merchantSupplier?.customerID})`
    }
    if (orderUser) {
      title += ` (${orderUser})`
    }
    navigation.setOptions({
      title,
      headerStyle: {
        borderBottomWidth: 0
      }
    })
  }, [order, merchant, orderUser, merchantSupplier])

  const sections = order
    ? [
        {
          title: t(`orderStatus.${orderStatus}`),
          type: orderStatus,
          data: completeProducts ?? []
        }
      ]
    : []

  /* Confirm your ORder, send all Data to Firebase */
  const confirmOrder = async () => {
    try {
      const database = firebase.database()
      const updates = {}
      /* Update Order-Status */
      const orderRef = database.ref(`/orders/${order.id}`)
      await orderRef.update({
        updated_at: Date.now(),
        preferred_delivery_date: momentToUnixInt(preferredDeliveryDate),
        status: 'confirmed'
      }) /*  Update Order Products amount and status */
      ;(productsWithDetails as OrderProducts[]).forEach(op => {
        updates[`/order_products/${op.product.id}`] = {
          ...op.product,
          supplier_id: order.supplier_id,
          status: 'confirmed'
        }
      })

      /* Send Message */
      if (message) {
        const messageKey = database
          .ref()
          .child(`messages/${order.merchant_id}/${order.supplier_id}`)
          .push().key

        updates[
          `messages/${order.merchant_id}/${order.supplier_id}/${messageKey}`
        ] = {
          created_at: Date.now(),
          message,
          from: 'supplier'
        }
      }
      await database.ref().update(updates)
      setOrderStatus('confirmed')
      logService('supplier_order_confirm')
      showMessage({
        message: 'Erfolgreich bestätigt',
        type: 'success',
        icon: 'auto'
      })
      setMessage('')
      return true
    } catch (e) {
      console.error(e)
      showMessage({
        message: e?.message,
        type: 'danger',
        icon: 'auto'
      })
      return false
    }
  }

  const sendMessage = async () => {
    const database = firebase.database()
    const updates = {}
    if (message) {
      const messageKey = database
        .ref()
        .child(`messages/${order.merchant_id}/${order.supplier_id}`)
        .push().key

      updates[
        `messages/${order.merchant_id}/${order.supplier_id}/${messageKey}`
      ] = {
        created_at: Date.now(),
        message,
        from: 'supplier'
      }
    }
    await database.ref().update(updates)
    logService('supplier_send_message')
    showMessage({
      message: 'Nachricht gesendet',
      type: 'success',
      icon: 'auto'
    })
    setMessage('')
    return true
  }

  const ThemeStyles = StyleSheet.create({
    MessageInputContainer: {
      backgroundColor: theme.colors.surface,
      borderColor: theme.colors.placeholder
    }
  })

  const updateProductAmount = (id, amount) => {
    const updatedProducts = productsWithDetails.slice()
    const indexToUpdate = findByProductId(updatedProducts, id)
    set(updatedProducts[indexToUpdate], 'product.amount', amount)
    setCompleteProducts(updatedProducts)
  }

  const updateProductPrice = (id, price) => {
    const updatedProducts = productsWithDetails.slice()
    const indexToUpdate = findByProductId(updatedProducts, id)
    set(updatedProducts[indexToUpdate], 'product.originalData.bulkPrice', price)
    setCompleteProducts(updatedProducts)
  }

  let orderHeaderAdditionalText = ''
  if (merchant?.name) {
    orderHeaderAdditionalText = merchant?.name
  }
  if (merchantSupplier?.customerID) {
    orderHeaderAdditionalText += ` (${merchantSupplier?.customerID})`
  }

  return (
    <FunnelProvider
      sessionAttributes={{
        flow_name: 'SupplierOrderDetails'
      }}>
      <View style={Styles.Container}>
        {(!order || !productsWithDetails) && (
          <View style={Styles.Loader}>
            <ActivityIndicator size="large" />
          </View>
        )}
        <View style={Styles.HeaderContainer}>
          <Subheading
            style={merchant?.address ? Styles.TitleTextBig : Styles.TitleText}
            selectable>
            {merchant?.address || t('orderDetailsForSupplier.title')}
          </Subheading>
          {preferredDeliveryDate && (
            <View
              style={[
                Styles.DateContainer,
                { borderColor: theme.colors.text }
              ]}>
              <Text style={Styles.Date}>
                {preferredDeliveryDate.calendar(null, calendarOptions)}
              </Text>
            </View>
          )}
        </View>
        <SectionList
          sections={sections}
          ItemSeparatorComponent={() => <Divider inset />}
          SectionSeparatorComponent={() => <Divider />}
          keyExtractor={(item, index) => item?.product?.product_id || index}
          renderItem={({ item }) => (
            <SupplierOrderProduct
              orderProduct={item as OrderProducts}
              locked={orderStatus === 'confirmed'}
              onProductAmountChange={updateProductAmount}
              onPriceChange={updateProductPrice}
            />
          )}
          renderSectionHeader={({ section: { title, type } }) => (
            <OrderSectionHeader
              title={title}
              type={type}
              additionalInfo={orderHeaderAdditionalText}
            />
          )}
          stickySectionHeadersEnabled={false}
        />
        {Boolean(total) && (
          <>
            <Divider inset={0} platform="both" />
            <View style={Styles.PriceContainer}>
              <Text style={Styles.PriceTitle}>
                {t('orderDetails.priceTitle')}
              </Text>
              <Price
                amount={total}
                style={[Styles.Price, { color: theme.colors.primary }]}
              />
            </View>
          </>
        )}
        <Divider inset={0} platform="both" />
        <View style={Styles.MessageBox}>
          <View
            style={[
              Styles.MessageInputContainer,
              ThemeStyles.MessageInputContainer
            ]}>
            <Icon
              name="chat"
              iconSet="MaterialCommunityIcons"
              style={[Styles.MessageIcon, { color: theme.colors.placeholder }]}
            />

            <TextInput
              onChangeText={m => setMessage(m)}
              value={message}
              style={Styles.MessageInput}
              multiline
              onBlur={() => logService('supplier_add_message')}
              label={t('orderDetailsForSupplier.chatLabel')}
            />
          </View>
          {orderStatus === 'confirmed' && (
            <Button
              style={Styles.SendButton}
              onPress={sendMessage}
              labelStyle={{ color: theme.colors.background }}
              size="L">
              {t('orderDetailsForSupplier.sendButton')}
            </Button>
          )}
        </View>
        <Divider inset={0} platform="both" />

        {orderStatus === 'new' && preferredDeliveryDate && (
          <View style={Styles.ButtonGroup}>
            <DateButton
              preferredDeliveryDate={preferredDeliveryDate}
              onPress={() => setDateSelectorVisible(true)}
              size="L"
            />
            <Button
              style={Styles.ConfirmButton}
              onPress={confirmOrder}
              size="L">
              {t('orderDetailsForSupplier.confirmButton')}
            </Button>
          </View>
        )}
        <View style={Styles.UpdatesReminder}>
          <Text style={Styles.UpdatesReminderText}>
            Neue Produkte oder Preise? Schreiben Sie eine kurze E-Mail an{' '}
            <Text style={Styles.UpdatesReminderTextBold}>
              updates@hierapp.de
            </Text>
            , rufen Sie uns unter{' '}
            <Text style={Styles.UpdatesReminderTextBold}>030 83797304</Text> an
            oder schreiben Sie eine WhatsApp-Nachricht an{' '}
            <Text style={Styles.UpdatesReminderTextBold}>0171 2013257</Text>.
            Falls Sie einen Verteiler haben, nehmen Sie uns bitte mit auf, so
            sind Ihre Produkte immer auf dem neuesten Stand.
          </Text>
        </View>

        {dateSelectorVisible && preferredDeliveryDate && (
          <View style={Styles.ModalContainer}>
            <DateModal
              supplierName={supplierName}
              visible={dateSelectorVisible}
              value={preferredDeliveryDate}
              merchantSupplier={merchantSupplier}
              onDismiss={() => setDateSelectorVisible(false)}
              onDateChange={date => setPreferredDeliveryDate(date)}
            />
          </View>
        )}
      </View>
    </FunnelProvider>
  )
}

export default OrderDetails
