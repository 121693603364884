import * as React from 'react'
import { StyleSheet, View, Platform } from 'react-native'
import { Text, useTheme } from 'react-native-paper'
import { Icon } from '@components'
import { CustomTheme } from '@themes/variables'

interface OrderSectionHeaderProps {
  title: string
  type: string
  additionalInfo: string
}

const OrderSectionHeader = (props: OrderSectionHeaderProps) => {
  const { colors }: CustomTheme = useTheme()
  const { title, type, additionalInfo } = props

  const typeColor =
    {
      draft: colors.textTertiary,
      sent: colors.error,
      confirmed: colors.green,
      delivered: colors.textTertiary,
      rejected: colors.error,
      cart: colors.textSecondary,
      new: colors.textSecondary,
      favorite: colors.textSecondary,
      category: colors.textSecondary,
      orderToday: colors.error
    }[type] || colors.textSecondary
  const typeIconName = {
    draft: 'attach',
    sent: 'mail',
    confirmed: 'checkbox',
    delivered: 'archive',
    rejected: 'alert',
    cart: 'cart',
    new: 'cart',
    favorite: 'star',
    category: 'menu',
    newProducts: 'bell-circle'
  }[type]
  const iconSet = {
    draft: 'Ionicons',
    sent: 'Ionicons',
    confirmed: 'Ionicons',
    delivered: 'Ionicons',
    rejected: 'Ionicons',
    cart: 'Ionicons',
    new: 'Ionicons',
    favorite: 'Ionicons',
    category: 'Ionicons',
    newProducts: 'MaterialCommunityIcons'
  }[type]
  const Styles = StyleSheet.create({
    Container: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      height: 32,
      alignItems: 'flex-end',
      backgroundColor:
        Platform.OS === 'web' ? colors.background : colors.surface
    },
    Title: {
      flexDirection: 'row',
      alignItems: 'center',
      paddingLeft: 16,
      paddingBottom: 6
    },
    TitleNoPadding: {
      flexDirection: 'row',
      alignItems: 'center',
      paddingLeft: 6,
      paddingBottom: 4
    },
    Text: {
      textTransform: Platform.select({ ios: 'uppercase', android: 'none' }),
      paddingLeft: 6,
      fontSize: 13,
      fontWeight: Platform.select({ ios: '700', android: 'normal' }),
      color: typeColor
    },
    AdditionalInfo: {
      color: colors.primary,
      fontWeight: '700',
      paddingRight: 16,
      paddingBottom: 8
    }
  })

  return (
    <View style={Styles.Container}>
      <View style={type && typeIconName ? Styles.Title : Styles.TitleNoPadding}>
        {type && typeIconName && (
          <Icon
            name={typeIconName}
            size={20}
            color={typeColor}
            iconSet={iconSet}
          />
        )}
        <Text style={Styles.Text}>{title}</Text>
      </View>
      {/* 
          We need to convert the string to a boolean. Otherwise an empty string 
          will get rendered, and RN doesn’t accept strings that are not wrapped 
          in <Text>.
      */}
      {Boolean(additionalInfo) && (
        <Text style={Styles.AdditionalInfo}>{additionalInfo}</Text>
      )}
    </View>
  )
}
export { OrderSectionHeader }
