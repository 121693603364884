/* eslint-disable @typescript-eslint/no-unused-vars */

const navigationMiddleware = store => next => action => {
  // Do something in here, when each action is dispatched
  next(action) // Continue the Action
  switch (action.type) {
    default:
      break
  }
}

export { navigationMiddleware }
