import { useEffect, useState } from 'react'
import * as Device from 'expo-device'

export default function useIsTablet() {
  const [isTablet, setIsTablet] = useState(false)
  const getDeviceType = async () => {
    const type = await Device.getDeviceTypeAsync()
    setIsTablet(type === Device.DeviceType.TABLET)
  }
  useEffect(() => {
    getDeviceType()
  }, [])

  return isTablet
}
