import * as React from 'react'
import { View, StyleSheet } from 'react-native'
import { ActivityIndicator } from 'react-native-paper'

const Styles = StyleSheet.create({
  Loader: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 10,
    justifyContent: 'center',
    alignItems: 'center'
  }
})

const Loader = () => (
  <View style={Styles.Loader}>
    <ActivityIndicator size="large" />
  </View>
)

export { Loader }
