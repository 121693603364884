import { useEffect, useState } from 'react'
import { useFirebase } from 'react-redux-firebase'
import { Reference, Query } from '@firebase/database-types'

interface QueryOptions {
  waitFor?: any
  defaultValue?: any
  orderByChild?: string
  equalTo?: any
  limitToLast?: number
}

// In some cases we may want the firebase query to execute when a pre-condition
// has been met, and not execute the query otherwise. For instance, we haven’t
// yet loaded an id to use for a query – in this case we can use `waitFor`
// to wait for a condition to be fullfilled
//
// @example
//
// const order = useFirebaseData(
//   'orders',
//   {
//     waitFor: orderShortCode,
//     orderByChild: 'short_code',
//     equalTo: orderShortCode
//   },
//   useCallback(orders => flattenData(orders)?.[0], [])
// )
//
// Important!
//
// If `transform` function is provided, it’s important to wrap it in `useCallback`
// so that we don’t pass new instance every time, since it’s used as a dependency
// for setting the result of the query. You will enter infinite loop of rerenders
// if you pass new instance of the function every time:
// new function => fire the query => setResult and rerender, pass new function
// => fire the query etc. etc.
//
export function useFirebaseData(
  path: string,
  {
    // unless the condition is provided, we’ll fire the query right away
    waitFor = true,
    orderByChild,
    equalTo,
    limitToLast,
    defaultValue = null
  }: QueryOptions,
  transformFn?: (any) => any
) {
  const firebase = useFirebase()
  const [result, setResult] = useState(defaultValue)

  useEffect(() => {
    let ref: Reference | Query = firebase.database().ref(path)

    if (equalTo) {
      ref = ref.equalTo(equalTo)
    }
    if (limitToLast) {
      ref = ref.limitToLast(limitToLast)
    }
    if (orderByChild) {
      ref = ref.orderByChild(orderByChild)
    }
    if (waitFor) {
      ref.on('value', snapshot => {
        let value = snapshot.val()
        if (transformFn) {
          value = transformFn(value)
        }
        setResult(value)
      })
    }
    return () => ref.off('value')
  }, [firebase, waitFor, path, orderByChild, equalTo, transformFn, limitToLast])

  return result
}
