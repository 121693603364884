import React from 'react'
import { Platform } from 'react-native'
import { Ionicons, MaterialCommunityIcons } from '@expo/vector-icons'

interface IconProps {
  name: string
  color?: string
  size?: number
  style?: {}
  theme?: {}
  onPress?: () => void
  iconSet?: string
  forcePlatform?: boolean
}

const Icon = ({
  name = 'help-circle',
  onPress,
  color,
  theme,
  iconSet = 'Ionicons',
  forcePlatform = false,
  ...otherProps
}: IconProps) => {
  const iconColor = color || theme?.colors?.text
  const IconSets = {
    Ionicons,
    MaterialCommunityIcons
  }
  const Component = IconSets[iconSet]
  const iconName =
    iconSet === 'Ionicons' && !forcePlatform
      ? Platform.select({ ios: 'ios-', android: 'md-', web: 'ios-' }) + name
      : name
  return (
    <Component
      name={iconName}
      onPress={onPress ? () => onPress : null}
      color={iconColor}
      {...otherProps}
    />
  )
}
export { Icon }
