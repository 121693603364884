import React from 'react'
import Constants from 'expo-constants'
import * as Analytics from 'expo-firebase-analytics'
import * as Localization from 'expo-localization'
import * as Updates from 'expo-updates'
import { Platform } from 'react-native'
import 'react-native-get-random-values' // https://forums.expo.io/t/how-do-i-import-uuid/49279/5
import { v4 } from 'uuid'
import axios from 'axios'
import { getCloudFunctionsBaseURL } from '@helpers/helpers'
import * as Device from 'expo-device'

const merchantEvents = [
  'merchant_add_to_cart',
  'merchant_zoom',
  'merchant_change_amount',
  'merchant_remove_from_cart',
  'merchant_change_preferred_delivery_date',
  'merchant_save_draft',
  'merchant_dismiss_changed_order',
  'merchant_new_order',
  'merchant_change_order',
  'merchant_cancel_order',
  'merchant_start_order_accept',
  'merchant_order_accept_toggle_checkmark',
  'merchant_order_accept_change_amount',
  'merchant_order_accepted',
  'merchant_message_started',
  'merchant_message_sent',
  'merchant_login',
  'merchant_logout',
  'merchant_call',
  'merchant_start_order',
  'merchant_ota_update',
  'merchant_scan_start',
  'merchant_scan_cancel',
  'merchant_scan_success',
  'merchant_search_start',
  'merchant_order_duplicate'
] as const
type MerchantEvents = typeof merchantEvents[number]
const supplierEvents = [
  'supplier_change_amount',
  'supplier_remove_from_cart',
  'supplier_change_price',
  'supplier_change_delivery_date',
  'supplier_add_message',
  'supplier_order_confirm',
  'supplier_send_message'
] as const
type SupplierEvents = typeof supplierEvents[number]
const adminEvents = [
  'merchant_notification',
  'supplier_notification',
  'admin_product_changed'
] as const
type AdminEvents = typeof adminEvents[number]

export type AvailableProperties =
  | 'supplierID'
  | 'merchantID'
  | 'language'
  | 'app_version'
  | 'action'
  | 'view'
  | 'count_products_in_cart'
  | 'minimum_value_reached'
  | 'cart_total'
  | 'preferred_delivery_date'
  | 'orderID'
  | 'screen'

const logService = async (
  event: SupplierEvents | AdminEvents | MerchantEvents,
  _properties?: AvailableProperties[],
  customProps?: { [key: string]: any }
) => {
  // next-tick it
  // Getting the whole state is just not efficient, there needs to be a better way
  setTimeout(async () => {
    try {
      // All REDUX-STORE-Properties are disabled for now
      /*    const globalState = {} // globalStore.getState()
      const scopedState = {} // scopedStore?.getState() || {}
      const state = { ...globalState, ...scopedState }
      const getPriceForProductDetails = (orderProduct: any) => {
        return (
          getPriceForOrderProduct(orderProduct) * orderProduct.product.amount
        )
      }
      const cartTotal =
        state?.order?.order_products?.reduce(
          (previous, orderProduct) =>
            previous + getPriceForProductDetails(orderProduct),
          0
        ) || 0
      const availableProperties = {
        screen: state?.navigation,
        supplierID: state?.supplier?.supplier || state?.order?.supplier_id,
        merchantID: state?.firebase?.auth?.uid || state?.order?.merchant_id,
        orderID: state?.order?.id,
        language: Localization?.locale || 'undefined',
        app_version: `v${Constants?.nativeAppVersion} (${Constants?.nativeBuildVersion})`,
        action: state?.order?.status ? 'order_edit' : 'order_new',
        view: state?.order?.category_filtered ? 'category' : 'overview',
        count_products_in_cart: state?.order?.order_products?.length,
        cart_total: cartTotal,
        minimum_value_reached:
          cartTotal >
            state?.firebase?.data?.suppliers?.[state?.supplier?.supplier]
              ?.min_order_value || false,
        preferred_delivery_date: state?.order?.preferred_delivery_date
          ? momentToUnixInt(moment(state?.order?.preferred_delivery_date))
          : '',
        is_test_user:
          state?.firebase?.data?.suppliers?.[state?.supplier?.supplier]
            ?.is_test_user || false
      }
       */
      const propsToUse = {
        native_app_version: Constants.nativeAppVersion,
        native_build_version: Constants.nativeBuildVersion,
        revision_id: (Updates?.manifest as any).revisionId || null,
        language: Localization?.locale || 'undefined',
        app_version: `v${Constants?.nativeAppVersion} (${Constants?.nativeBuildVersion})`
      }
      /* properties?.forEach(prop => {
        propsToUse[prop] = availableProperties[prop]
      }) */

      const finalProps = { ...propsToUse, ...customProps }

      /* Only push MerchantEvents on Mobile Devices */
      if (
        typeof event === 'string' &&
        ((merchantEvents.includes(event as MerchantEvents) &&
          ['android', 'ios'].includes(Platform.OS)) ||
          (supplierEvents.includes(event as SupplierEvents) &&
            Platform.OS === 'web'))
      ) {
        await Analytics.logEvent(event, finalProps)
      }
      // console.log('finalProps', finalProps)
      return
    } catch (e) {
      console.log(e)
    }
  }, 1)
}

export default logService

export interface FunnelSession {
  session_id: string
  session_attributes: Record<string, any>
}
export interface FunnelAnalytic {
  context: string
  component: string
  entity: string
  action: string
  attributes: Record<string, string>
  session: FunnelSession
}

export interface SegmentEvent {
  name: string
  properties: Record<string, string>
  timestamp: Date
  // https://segment.com/docs/connections/spec/common/#context
  context: {
    app: {
      name: string
      version: string
      build: string
    }
    os: {
      name: string
      version: string
    }
    device: {
      manufacturer: string
      model: string
      name?: string
      type?: string
      version?: string
      id?: string
    }
  }
}

let eventsQueue: SegmentEvent[] = []
let eventsQueueTimeout: NodeJS.Timeout | undefined
export async function sendEventsToSegment() {
  const url = `${getCloudFunctionsBaseURL()}/analytics-dispatchAnalyticsMerchantEventToSegment`
  const events = [...eventsQueue]
  eventsQueue = []
  const resp = await axios.post(url, {
    events
  })
  return resp
}

export async function logEventsOnSegment(
  eventName: string,
  properties: Record<string, string>,
  timestamp: Date
) {
  const defaultProps = {
    expo_revision_id: Updates?.manifest?.revisionId || null
  }
  const event: SegmentEvent = {
    name: eventName,
    context: {
      device: {
        manufacturer: Device.manufacturer,
        model: Device.modelId,
        name: Device.modelName,
        id: Constants.deviceId
      },
      os: {
        name: Device.osName,
        version: Device.osVersion
      },
      app: {
        name: 'Hier Merchant',
        version: Constants.nativeAppVersion,
        build: Constants.nativeBuildVersion
      }
    },
    properties: {
      ...defaultProps,
      ...properties
    },
    timestamp
  }
  eventsQueue.push(event)
  if (eventsQueueTimeout) clearTimeout(eventsQueueTimeout)
  eventsQueueTimeout = setTimeout(sendEventsToSegment, 1000 * 10)
}

export function logFunnelAnalytic<
  T extends Record<any, any> = Record<any, any>
>(
  session: FunnelSession,
  componentName: string,
  entity: string,
  action: string,
  attributes: T
) {
  const analytic: FunnelAnalytic = {
    context: 'm',
    component: componentName,
    entity,
    action,
    attributes,
    session
  }
  const eventName = `MA+${analytic.component}+${analytic.entity}+${analytic.action}`
  const props = {
    ...attributes,
    ...session,
    timestamp: Date.now()
  }
  /* if (__DEV__) {
    console.log(eventName as any, [], props)
  } */
  return Promise.all([
    logService(eventName as any, [], {
      ...attributes,
      ...session,
      timestamp: Date.now()
    }),
    logEventsOnSegment(eventName, props, new Date(props.timestamp))
  ])
}

const FunnelContext = React.createContext<FunnelSession>(null)

export function FunnelProvider({
  children,
  sessionAttributes,
  sessionId
}: {
  sessionId?: string
  children: React.ReactNode
  sessionAttributes: Record<string, any>
}) {
  const [funnelSession] = React.useState(() => ({
    session_id: sessionId || v4(),
    session_attributes: sessionAttributes
  }))
  return (
    <FunnelContext.Provider value={funnelSession}>
      {children}
    </FunnelContext.Provider>
  )
}

export function useFunnel() {
  const funnelSession = React.useContext(FunnelContext)

  if (!funnelSession && __DEV__) {
    throw new Error('FunnelProvider not present')
  }
  return {
    log(logPayload: FunnelLog) {
      const { componentName, entity, action, attributes } = logPayload
      return logFunnelAnalytic(
        funnelSession,
        componentName,
        entity,
        action,
        attributes
      )
    }
  }
}

export type FunnelLogBase<ComponentName, Entity, Action, T> = {
  componentName: ComponentName
  entity: Entity
  action: Action
  attributes: T
}

export type SupplierLandingLog =
  | FunnelLogBase<
      'SupplierLanding',
      'NewProductAlertButton',
      'Tap',
      { merchant_id: string; supplier_id: string }
    >
  | FunnelLogBase<
      'SupplierLanding',
      'CreateCustomProductButton',
      'Tap',
      { merchant_id: string; supplier_id: string }
    >
  | FunnelLogBase<
      'SupplierLanding',
      'CreateCustomProduct',
      'Success',
      { merchant_id: string; supplier_id: string }
    >
  | FunnelLogBase<
      'SupplierLanding',
      'ProductCategoryListItemButton',
      'Tap',
      { merchant_id: string; supplier_id: string; category_id: string }
    >
  | FunnelLogBase<
      'SupplierLanding',
      'FavoriteButton',
      'Tap',
      { merchant_id: string; supplier_id: string; category_id: string }
    >
  | FunnelLogBase<
      'SupplierLanding',
      'GoToCartButton',
      'Tap',
      { merchant_id: string; supplier_id: string }
    >
  | FunnelLogBase<
      'SupplierLanding',
      'SearchBarInput',
      'Submit',
      { merchant_id: string; supplier_id: string }
    >
  | FunnelLogBase<
      'SupplierLanding',
      'SearchBarResultSet',
      'Create',
      { merchant_id: string; supplier_id: string }
    >

export type OrderProductAmount = FunnelLogBase<
  'OrderProductAmount',
  'ChangeQtyButton',
  'Tap',
  {
    merchant_id: string
    supplier_id: string
    product_id: string
    is_favorite?: boolean
    placed_screen_name: string
  }
>
export type FavouriteLog =
  | FunnelLogBase<
      'OrderProduct',
      'FavoriteProduct',
      'Delete',
      { merchant_id: string; supplier_id: string }
    >
  | FunnelLogBase<
      'OrderProduct',
      'FavoriteIncQtyButton',
      'Tap',
      { merchant_id: string; supplier_id: string; product_id: string }
    >
  | FunnelLogBase<
      'SupplierLanding',
      'FavoriteDecQtyButton',
      'Tap',
      { merchant_id: string; supplier_id: string; product_id: string }
    >
export type ProductsListLog =
  | FunnelLogBase<
      'ProductsList',
      'FavoriteIncQtyButton',
      'Tap',
      { merchant_id: string; supplier_id: string; product_id: string }
    >
  | FunnelLogBase<
      'ProductsList',
      'FavoriteDecQtyButton',
      'Tap',
      { merchant_id: string; supplier_id: string; product_id: string }
    >
  | FunnelLogBase<
      'ProductsList',
      'GoToCartButton',
      'Tap',
      { merchant_id: string; supplier_id: string }
    >
  | FunnelLogBase<
      'ProductsList',
      'AddFavoriteButton',
      'Tap',
      { merchant_id: string; supplier_id: string }
    >

export type CartOverviewLog = FunnelLogBase<
  'CartOverview',
  'AddProductButton',
  'Tap',
  { merchant_id: string; supplier_id: string }
>

export type CartCheckoutLog =
  | FunnelLogBase<
      'CartCheckout',
      'ShowAllOrderersButton',
      'Tap',
      { merchant_id: string; supplier_id: string }
    >
  | FunnelLogBase<
      'CartCheckout',
      'PickOrdererButton',
      'Tap',
      { merchant_id: string; supplier_id: string }
    >

export type DatePickerLog = FunnelLogBase<
  'DatePickerLog',
  'PickDateButton',
  'Tap',
  { merchant_id: string; supplier_id: string }
>

export type CartActionsLog =
  | FunnelLogBase<
      'CartActions',
      'CancelOrderButton',
      'Tap',
      { merchant_id: string; supplier_id: string }
    >
  | FunnelLogBase<
      'CartActions',
      'SubmitOrderButton',
      'Tap',
      { merchant_id: string; supplier_id: string }
    >

export type OrderLog =
  | FunnelLogBase<
      'Order',
      'StartOrder',
      'Success',
      { merchant_id: string; supplier_id: string }
    >
  | FunnelLogBase<
      'Order',
      'CreateDraft',
      'Success',
      { merchant_id: string; supplier_id: string }
    >
  | FunnelLogBase<
      'Order',
      'DiscardDraft',
      'Success',
      { merchant_id: string; supplier_id: string }
    >
  | FunnelLogBase<
      'Order',
      'SendOrder',
      'Success',
      {
        merchant_id: string
        supplier_id: string
        revenue: number
        currency: string
      }
    >

export type OrderCreateNSLog =
  | FunnelLogBase<
      'OrderNS',
      'OpenOrderCreate',
      'Tap',
      {
        merchant_id: string
        supplier_id: string
      }
    >
  | FunnelLogBase<
      'OrderNS',
      'SubmitOrder',
      'Tap',
      {
        merchant_id: string
        supplier_id: string
        revenue: number
        currency: string
      }
    >
export type OrderNSLog =
  | FunnelLogBase<
      'OrderNS',
      'OpenCartOverview',
      'Tap',
      {
        merchant_id: string
        supplier_id: string
      }
    >
  | FunnelLogBase<
      'OrderNS',
      'OpenCartCheckout',
      'Tap',
      {
        merchant_id: string
        supplier_id: string
      }
    >
  | FunnelLogBase<
      'OrderNS',
      'OpenOrdersOverviewPostSending',
      'Tap',
      {
        merchant_id: string
        supplier_id: string
      }
    >
  | FunnelLogBase<
      'OrderNS',
      'BackToCartOverview',
      'Tap',
      {
        merchant_id: string
        supplier_id: string
      }
    >
  | FunnelLogBase<
      'OrderNS',
      'CloseCart',
      'Tap',
      {
        merchant_id: string
        supplier_id: string
      }
    >
  | FunnelLogBase<
      'OrderNS',
      'CancelOrder',
      'Tap',
      {
        merchant_id: string
        supplier_id: string
      }
    >
export type FunnelLog =
  | OrderNSLog
  | OrderCreateNSLog
  | SupplierLandingLog
  | OrderProductAmount
  | ProductsListLog
  | CartOverviewLog
  | CartActionsLog
  | DatePickerLog
  | CartCheckoutLog
  | OrderLog
  | FavouriteLog
