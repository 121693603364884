/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Animated, Image } from 'react-native'
import { Text } from 'react-native-paper'
import { SafeAreaView } from 'react-native-safe-area-context'
import { Maintenance } from '@hierfoods/interfaces'
import Styles from './styles'

const AnimationDuration = 1500
const AnimationOffset = 0.1

const stagingIcon = require('../../../assets/staging-icon.png')

const MaintenanceScreen = ({ config }: { config: Maintenance | undefined }) => {
  const { t } = useTranslation()
  const Scale = new Animated.Value(1)

  useEffect(() => {
    Animated.loop(
      Animated.sequence([
        Animated.timing(Scale, {
          toValue: 1 - AnimationOffset,
          duration: AnimationDuration,
          useNativeDriver: true
        }),
        Animated.timing(Scale, {
          toValue: 1 + AnimationOffset,
          duration: AnimationDuration,
          useNativeDriver: true
        })
      ])
    ).start()
  }, [])

  return (
    <SafeAreaView style={Styles.Container}>
      <Text style={Styles.Title}>
        {config?.customTitle || `🚧 ${t('maintenance.title')} 🚧`}
      </Text>
      <Animated.View style={{ transform: [{ scale: Scale }] }}>
        <Image source={stagingIcon} style={Styles.Logo} />
      </Animated.View>
      <Text style={Styles.Description}>
        {config?.customDescription || t('maintenance.description')}
      </Text>
    </SafeAreaView>
  )
}

export default MaintenanceScreen
