/* eslint-disable guard-for-in */
/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from 'axios'
import { retryAdapterEnhancer } from 'axios-extensions'
import Sentry from '@errorhandler'
import { momentToUnixInt, getCloudFunctionsBaseURL } from '@helpers/helpers'
import { navigationRef } from '@navigation'
import { StackActions } from '@react-navigation/native'
import logService from '@services/log-service'
import { globalStore } from '@store'
import firebase from 'firebase'
import throttle from 'lodash/throttle'
import moment from 'moment'
import {
  AutoDraftFields,
  GlobalReduxStore,
  ScopedReduxStore
} from '@hierfoods/interfaces'
import { setAccepting } from '../reducer/accepting'
import { showError } from '../reducer/feedback'
import {
  sendOrder,
  setCartOpen,
  setCartStep,
  setOrderEdited,
  setOrderID,
  setOrderSending,
  setTestOrder
} from '../reducer/order'
import {
  setOrderProductRemoteID,
  updateOrderProduct
} from '../reducer/orderProducts'

// Cancel-able autodraft request
const { CancelToken } = axios
let cancelableAutoDraft
const axiosCancel = axios.create({
  baseURL: getCloudFunctionsBaseURL(),
  headers: { 'Cache-Control': 'no-cache' }
})

// SendOrder Request with auto-retry before erroring out
let orderSending = false
const axiosRetry = axios.create({
  baseURL: getCloudFunctionsBaseURL(),
  headers: { 'Cache-Control': 'no-cache' },
  adapter: retryAdapterEnhancer(axios.defaults.adapter)
})

// #region AutoDraft
/**
 * Automatically creates a Draft for each Order that is started
 * This is throttled to 5 Seconds so we dont make too many requests
 * This Function is invoked every time a OrderProduct is added, changed or removed
 */
export const autoDraftOrder = async (state, dispatch, _action) => {
  const { order: o, orderProducts }: ScopedReduxStore = state
  // Return if we are currently Sending the Order to Server
  if (o.sending) return
  /**
   * Return if we dont have any OrderProducts or the Order is no Draft
   * because we dont want to auto-update a non - draft
   */
  if (o.status !== 'draft') return
  // Object for our batch-updates
  const draftFields: AutoDraftFields = {
    type: 'draft',
    orderID: '',
    order: {},
    orderProducts: {}
  }
  let orderID = o.id
  // No OrderID -> Make one
  if (!orderID) {
    orderID = firebase.database().ref().child('orders').push().key
    // Save it into the Order
    dispatch(setOrderID(orderID))
    draftFields.order.status = o.status
  }
  draftFields.orderID = orderID
  // Autodraft should never be more or same time with Sent
  draftFields.order.updated_at = momentToUnixInt(
    moment().subtract('10 seconds')
  )
  draftFields.order.status = o.status
  draftFields.order.preferred_delivery_date = momentToUnixInt(
    moment(o.preferred_delivery_date)
  )
  draftFields.order.supplier_id = o.supplier_id
  draftFields.order.merchant_id = o.merchant_id
  draftFields.order.is_test_order = o.is_test_order || null

  /**
   * For every orderProduct, calculate its data and add it to our update-batch
   */
  for (const productID in orderProducts) {
    const onlineData = {
      ...orderProducts[productID],
      order_id: orderID,
      status: 'draft'
    }
    /* OrderProduct-ID */
    let opID = onlineData.id

    if (!opID) {
      /* if we dont have an ID for this OP, create one */
      opID = firebase.database().ref().child('order_products').push().key

      /* Fix the ID's of the Product. This will not loop because of the throttle */
      dispatch(
        setOrderProductRemoteID({
          id: productID,
          remoteID: opID
        })
      )
    }

    // Update the OrderProduct
    draftFields.orderProducts[opID] = onlineData
  }

  // Write all the Updates to the DB
  // If autodraft is running, cancel it
  if (cancelableAutoDraft) {
    await cancelableAutoDraft()
    cancelableAutoDraft = null
  }
  const authorization = await firebase.auth().currentUser.getIdToken()
  // Prevent some race conditions that could happen if the autodraft and the ordersending get called nearly at the same time
  if (orderSending) return
  await axiosCancel
    .post('defaults-autoDraftOrder', draftFields, {
      headers: {
        authorization,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      cancelToken: new CancelToken(c => {
        cancelableAutoDraft = c
      })
    })
    .catch(err => {
      console.log(err)
      // we cancel requests to send just the last one, no need to report
      if (axios.isCancel(err)) {
        return
      }
      if (Sentry?.Native) {
        Sentry.Native.captureException(
          err instanceof Error ? err : new Error(err),
          { extra: { state } }
        )
      }
    })
}
/**
 * Throttle the Function so we dont write too often :)
 */
export const debouncedAutoDraftOrder = throttle(autoDraftOrder, 1000, {
  leading: true
})
// #endregion

// #region SendOrder
/**
 * Send out the Order
 * This is triggered by the SendOrder-Action, which optionally takes a @see OrderStatus as param
 */
export const sendOutOrder = async (store, action) => {
  try {
    orderSending = true
    // Loader automatically gets enabled by reducer
    const {
      order,
      orderProducts,
      supplier: { supplier: supplierID }
    }: ScopedReduxStore = store.getState()

    const { firebase: firebaseData } =
      globalStore.getState() as GlobalReduxStore
    const orderStatus = action.payload || order.status
    const allProductsForSupplier = firebaseData?.data?.products?.[supplierID]

    // #region ErrorHandling
    // Check if we have products with a negative amount, remove them from store, also throw an error
    const brokenProducts = Object.entries(orderProducts).filter(
      ([, op]) => op.amount < 1
    )
    if (brokenProducts?.length > 0) {
      // Remove the negative amount product from the cart, create a sentry error and send out the order again
      for (const brokenProduct of brokenProducts) {
        store.dispatch(
          updateOrderProduct({
            id: brokenProduct[0],
            amount: 0
          })
        )
      }
      store.dispatch(sendOrder(orderStatus))
      Sentry.Native.captureException(new Error('Had negative ProductAmount'))
      return
    }
    const unavailableProducts = Object.entries(orderProducts).filter(
      ([id]) => allProductsForSupplier?.[id]?.soft_deleted // Was soft_deleted while in cart
    )
    if (unavailableProducts?.length > 0) {
      // They added Products to the cart that shouldnt be here
      // Remove the disabled Products, and notify the User about this
      for (const unavailableProduct of unavailableProducts) {
        store.dispatch(
          updateOrderProduct({
            id: unavailableProduct[0],
            amount: 0
          })
        )
      }
      // Close sending message
      store.dispatch(setOrderSending(false))
      // Close Cart
      store.dispatch(setCartOpen(false))
      // Reset Cart to Page 1
      store.dispatch(setCartStep(1))
      // Show Error message
      store.dispatch(
        showError({
          message: 'errors.order.disabledProducts',
          params: {
            amount: unavailableProducts.length
          }
        })
      )
      // Capture the Error
      Sentry.Native.captureException(
        new Error('Had disabled Product in Cart'),
        { extra: { state: store.getState() } }
      )
      return
    }
    // #endregion

    const finalData: AutoDraftFields = {
      type: 'sent',
      orderID: order.id,
      orderProducts: {},
      order: {}
    }
    // If we have no orderID, make one
    //
    // If the autoDraft hasn’t completed by the time
    // the user is actually sending the request, it’s possible
    // to not have acquired real order ID – very unlikely
    if (!finalData.orderID) {
      finalData.orderID = firebase.database().ref().child('orders').push().key
    }

    /**
     * For every orderProduct, calculate its data and add it to our update-batch
     */
    for (const productID in orderProducts) {
      const onlineData = {
        ...orderProducts[productID],
        order_id: finalData.orderID,
        status: orderStatus
      }
      /* OrderProduct-ID */
      let opID = onlineData.id

      if (!opID) {
        /* if we dont have an ID for this OP, create one */
        opID = firebase.database().ref().child('order_products').push().key
      }

      // Update the OrderProduct
      finalData.orderProducts[opID] = onlineData
    }

    // Make Analytics-log :)
    logService('merchant_change_order')
    /**
     * Write all of the Updates to the Order
     * Notice that we write each property as a seperate update?
     * That because firebase handles bulk updates differently, it doesn't merge them
     * So if we would update the Order, it would remove some of the already calculated properties
     * which we dont want :)
     * So instead we write each Property that needs updating seperately
     */
    finalData.order.status = orderStatus
    finalData.order.updated_at = Date.now()

    if (!order?.created_at) finalData.order.created_at = Date.now()
    finalData.order.preferred_delivery_date = momentToUnixInt(
      moment(order.preferred_delivery_date)
    )
    finalData.order.supplier_id = order.supplier_id
    finalData.order.merchant_id = order.merchant_id
    finalData.order.order_user = order.order_user || null
    finalData.order.is_test_order = order.is_test_order || null

    // If autodraft is running, cancel it
    if (cancelableAutoDraft) {
      await cancelableAutoDraft()
      cancelableAutoDraft = null
    }

    const authorization = await firebase.auth().currentUser.getIdToken()
    await axiosRetry.post('defaults-sendOrder', finalData, {
      retryTimes: 3,
      headers: {
        authorization,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })

    // Disable Loader
    store.dispatch(setOrderSending(false))

    // Navigate Back
    if (orderStatus === 'draft')
      navigationRef.current?.navigate('OrderCreateStack', {
        screen: 'NewOrderChooseSupplier'
      })
    else {
      navigationRef.current?.dispatch(StackActions.popToTop())
      navigationRef.current?.navigate('OrderOverviewStack', {
        screen: 'OrderOverview'
      })
    }
    orderSending = false
  } catch (err) {
    orderSending = false
    store.dispatch(setOrderSending(false))
    Sentry.Native.captureException(err, { extra: { state: store.getState() } })
    store.dispatch(showError({ message: 'errors.order.send' }))
  }
}
// #endregion

// #region AcceptOrder
/**
 * Accept the Current Order
 */
export const acceptCurrentOrder = async (store, _action) => {
  try {
    // ### TODO FIX ACCEPT MODE
    /*  const { order }: ScopedReduxStore = store.getState()
    // Reset the edited-state
    store.dispatch(setOrderEdited(null))
    const updates = {}
    // We are accepting the order, so it was delivered now :)
    updates[`/orders/${order.id}/status`] = 'delivered'
    updates[`/orders/${order.id}/updated_at`] = Date.now()
    updates[`/orders/${order.id}/delivered_at`] = Date.now()
    updates[`/orders/${order.id}/preferred_delivery_date`] = momentToUnixInt(
      moment()
    )

    order.order_products.forEach(op => {
      updates[`/order_products/${op.product.id}`] = {
        ...op.product,
        status:
          op.initialAmount === op.product.amount
            ? 'delivered' // ### TODO does rejected make sense? is it rejected if the amount has changed?
            : 'rejected'
      }
    })

    // Update the DB
    await firebase.database().ref().update(updates)
    // log it
    logService('merchant_order_accepted', undefined, {
      count_products_delivered: order.order_products.filter(
        op => op.product.amount !== 0
      ).length
    })
    store.dispatch(setAccepting(false))
    navigationRef.current?.navigate('OrderOverview') */
  } catch (err) {
    Sentry.Native.captureException(err, { extra: { state: store.getState() } })
    store.dispatch(showError({ message: 'errors.order.acceptOrder' }))
  }
}
// #endregion
// #region Make Order Edited
export const makeOrderEdited = (state, dispatch) => {
  const { order, accepting }: ScopedReduxStore = state

  if (!accepting && !order.order_edited) dispatch(setOrderEdited(true))
}
// #endregion
// #region Delete Order
export const deleteCurrentOrder = async (store, action) => {
  try {
    const { order }: ScopedReduxStore = store.getState()
    await firebase.database().ref(`orders/${order.id}`).remove()
  } catch (err) {
    Sentry.Native.captureException(err, { extra: { state: store.getState() } })
    store.dispatch(showError({ message: 'errors.order.delete' }))
  }
}
// #endregion
// #region Start Accepting an Order
export const startAccepting = async (store, action) => {
  try {
    const { order }: ScopedReduxStore = store.getState()

    store.dispatch(setAccepting(true))
    logService('merchant_start_order_accept')
    // ### TODO accepting
    /* for (const orderProduct of orderProducts) {
      store.dispatch(updateOrderProduct({ ...orderProduct, accepted: true }))
    } */
  } catch (err) {
    Sentry.Native.captureException(err, { extra: { state: store.getState() } })
    store.dispatch(showError({ message: 'errors.order.acceptOrder' }))
  }
}
// #endregion
// #regionMark TestOrder
export const markTestOrders = async store => {
  const {
    supplier: { supplier: supplierID }
  }: ScopedReduxStore = store.getState()

  // Prevent the weird undefined supplier we sometimes have?
  if (supplierID === undefined || supplierID === 'undefined') return

  const { firebase: firebaseData } = globalStore.getState() as GlobalReduxStore

  const supplierData = firebaseData?.data?.suppliers?.[supplierID]
  if (supplierData?.is_test_user) store.dispatch(setTestOrder(true))
}

// #endregion
