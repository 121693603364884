export const CALENDAR_OPTIONS = {
  lastDay: `[GESTERN], DD.MM`,
  sameDay: `[HEUTE], DD.MM`,
  nextDay: `[MORGEN], DD.MM`,
  nextWeek: `dddd, DD.MM`,
  sameElse: 'L'
}

export const LINKED_PROPERTIES = [
  {
    valueKey: 'bulkUnit',
    idKey: 'bulk_unit_id',
    dbKey: 'bulk_units'
  },
  {
    valueKey: 'singleUnit',
    idKey: 'single_unit_id',
    dbKey: 'single_units'
  },
  {
    valueKey: 'productType',
    idKey: 'product_type_id',
    dbKey: 'product_types'
  }
]
