import * as React from 'react'
import { Platform } from 'react-native'
import { PersistGate } from 'redux-persist/integration/react'

interface PersistWrapperProps {
  children: React.ReactChildren
  persistor: any // TODO: investigate a better solution
}

const PersistWrapper = ({ children, persistor }: PersistWrapperProps) => {
  if (Platform.OS === 'web') {
    return children
  }

  return (
    <PersistGate persistor={persistor} loading={null}>
      {children}
    </PersistGate>
  )
}

export default PersistWrapper
